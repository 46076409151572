import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Grid,
  IconButton,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  TextField,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useTranslation } from "react-i18next";
import DataGridContractsUnderManagement from "../../../components/datagrids/DataGridContractsUnderManagement";
import InitiateDocumentsDialog from "../../../components/dialogs/InitiateDocumentsDialog";
import { DialogMode } from "../../../types/dialogmode";
import AskMeAnything from "../../components/AskMeAnything";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

function ContractsUnderManagementList() {
  const { t } = useTranslation();
  const location = useLocation();

  const { clientId } = useParams(); // Extracts the :clientId parameter from the URL

  return (
    <React.Fragment>
      <Helmet title={`${t("Managed Contracts")}`} />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link
              component={NavLink}
              to={`/contracts-under-management/${clientId}/contracts`}
              color="inherit"
            >
              <Typography variant="h3" gutterBottom display="inline">
                {t("Managed Contracts")}
              </Typography>
            </Link>

            {/* {status && (
              <Typography variant="h3" gutterBottom display="inline">
                {t(status)}
              </Typography>
            )} */}
          </Breadcrumbs>
        </Grid>

        <Grid item xs={12} md={4}>
          <AskMeAnything placeholder={"Ask a question about your contracts"} />
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container direction="column" gap={6}>
        {/* <Grid item>
          <Grid container gap={3}>
            <Grid item>
              <InitiateDocumentsDialog
                mode={DialogMode.Add}
                refetch={() => setRefetch(true)}
              />
            </Grid>
          </Grid>
        </Grid> */}

        <Grid item>
          {clientId && <DataGridContractsUnderManagement clientId={clientId} />}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default ContractsUnderManagementList;
