// https://redux-toolkit.js.org/rtk-query/usage/examples

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import config from "../../config";
import { IAIResponse } from "../../types/airesponse";
import mockAIResponse from "./aiSlice-response";
import { IDocument } from "../../types/document";
import { IQuestion } from "../../types/question";
import { isValidDate } from "../../utils/string";
import { IContract } from "../../types/contract";
import i18next from "i18next";

export const AIApi: any = createApi({
  reducerPath: "AIApi",

  baseQuery: fetchBaseQuery({
    baseUrl: config.API_URL,
    prepareHeaders: (headers: any, { getState }: any) => {
      const accessToken = getState().auth.accessToken; // Retrieve the access token

      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      } else {
        throw new Error("No access token available");
      }

      return headers;
    },
  }),

  endpoints: (builder) => ({
    // processPDF: builder.mutation<IAIResponse, { document: IDocument; base64string: string }>({
    //   query: ({ document, base64string }) => ({
    //     url: `/contracts/${document.contractId}/documents/${document.id}/analyze`,
    //     method: "POST",
    //     body: { pdf: base64string },
    //     onUploadProgress: (progressEvent: any) => {
    //       // Calculate the progress and handle each chunk here
    //       const chunkSize = 8192; // You can adjust the chunk size as needed
    //       const totalChunks = Math.ceil(base64string.length / chunkSize);
    //       const currentChunk = Math.ceil((progressEvent.loaded / base64string.length) * totalChunks);

    //       // Extract the current chunk of base64 string
    //       const start = (currentChunk - 1) * chunkSize;
    //       const end = Math.min(currentChunk * chunkSize, base64string.length);
    //       const chunk = base64string.substring(start, end);

    //       // Send the chunk to the server or handle it as needed
    //       sendChunkToServer(chunk, currentChunk, totalChunks);
    //     },
    //   }),
    //   transformResponse: (response: any) => {
    //     // remove all non numberic characters
    //     const totalValue = response?.data?.financials?.totalValue?.replace(/[^0-9]/g, "");

    //     const totalValueNumber = parseInt(totalValue, 10);

    //     const aiResponse: IAIResponse = {
    //       ...response?.data,
    //       financials: {
    //         ...response?.data.financials,
    //         totalValue: totalValueNumber,
    //       },
    //       dates: {
    //         ...response?.data.dates,
    //         startDate: response?.data?.dates?.startDate ? new Date(response.data.dates.startDate) : null,
    //         endDate: response?.data?.dates?.endDate ? new Date(response.data.dates.endDate) : null,
    //         signedDate: response?.data?.dates?.signedDate ? new Date(response.data.dates.signedDate) : null,
    //       },
    //     };

    //     return aiResponse;
    //   },
    // }),
    // Analyses a file in the Blob storage
    // analyzeFile: builder.mutation<IAIResponse, IDocument>({
    //   query: (document: IDocument) => ({
    //     url: `/contracts/${document.contractId}/documents/${document.id}/analyze`,
    //     method: "POST",
    //     body: { document },
    //   }),
    //   transformResponse: (response: any) => {
    //     // remove all non numberic characters
    //     // const totalValue = response?.data?.financials?.totalValue?.replace(
    //     //   /[^0-9]/g,
    //     //   ""
    //     // );

    //     const totalValue = response?.data?.financials?.totalValue; //"$25,000";
    //     const valueAsNumber = totalValue
    //       ? Number(totalValue.replace(/[^0-9.-]+/g, ""))
    //       : null;

    //     const totalValueNumber = parseInt(totalValue, 10);

    //     const aiResponse: IAIResponse = {
    //       ...response?.data,
    //       financials: {
    //         ...response?.data.financials,
    //         totalValue: valueAsNumber,
    //       },
    //       dates: {
    //         ...response?.data.dates,
    //         startDate: response?.data?.dates?.startDate
    //           ? new Date(response.data.dates.startDate)
    //           : null,
    //         endDate: response?.data?.dates?.endDate
    //           ? new Date(response.data.dates.endDate)
    //           : null,
    //         signedDate: response?.data?.dates?.signedDate
    //           ? new Date(response.data.dates.signedDate)
    //           : null,
    //       },
    //     };

    //     return aiResponse;
    //   },
    // }),
    requestDocumentAnalysis: builder.mutation<string, IDocument>({
      query: (document: IDocument) => ({
        url: `/contracts/${document.contractId}/documents/${document.id}/analyze`,
        method: "POST",
        body: { document },
      }),
      transformResponse: (response: any) => {
        console.log(response);

        return response?.data;
      },
    }),
    getContractAnalysisStatus: builder.query<string, IContract>({
      query: ({ id }) => ({
        url: `/contracts/${id}/analyze`,
        method: "GET",
      }),
      transformResponse: (response: any) => {
        console.log(response);

        return response?.data?.Status || "none";
      },
    }),
    analyzeContractFile: builder.mutation<any, any>({
      query: ({ file }) => ({
        url: `/contract/analyze${
          file.location === "sharepoint" ? "SharePointFile" : ""
        }`,
        method: "POST",
        body: file,
      }),
      transformResponse: (response: any) => {
        const data = response.data;

        // get the total value
        // remove all non numberic characters, except the decimal point
        const totalValue = data?.financials?.totalValue?.replace(
          /[^0-9.]/g,
          ""
        );

        //   const totalValue = data?.financials?.totalValue?.replace(/[^0-9]/g, "");

        const startDate = data?.dates?.startDate
          ? new Date(data?.dates?.startDate.split("-").reverse().join("-"))
          : null;

        const endDate = data?.dates?.endDate
          ? new Date(data?.dates?.endDate.split("-").reverse().join("-"))
          : null;

        const signedDate = data?.dates?.signedDate
          ? new Date(data?.dates?.signedDate.split("-").reverse().join("-"))
          : null;

        const totalValueNumber = parseInt(totalValue, 10);
        const aiResponse: IAIResponse = {
          ...response?.data,
          financials: {
            ...data?.financials,
            totalValue: totalValueNumber,
          },
          dates: {
            ...data?.dates,
            startDate: startDate && isValidDate(startDate) ? startDate : null,
            endDate: endDate && isValidDate(endDate) ? endDate : null,
            signedDate:
              signedDate && isValidDate(signedDate) ? signedDate : null,
            noticePeriod: {
              ...data?.dates?.noticePeriod,
              value: Number(data?.dates?.noticePeriod?.value),
            },
          },
        };

        console.log("aiResponse redux", aiResponse);

        return aiResponse;
      },
    }),
    addAnalysisTask: builder.mutation<IContract, IDocument>({
      query: (document) => {
        return {
          url: `/contracts/${document?.contractId}/documents/${document?.id}/analysisTask`,
          method: "POST",
          body: {
            locale: i18next.language,
            level: localStorage.getItem("level") || 1,
            document: {
              location: document.location,
              downloadUrl: document.downloadUrl,
            },
          },
        };
      },
    }),
    stopAnalysisTask: builder.mutation<any, IDocument>({
      query: (document) => ({
        url: `/contracts/${document?.contractId}/documents/${document?.id}/analysisTask`,
        method: "DELETE",
      }),
    }),
    askMeAnything: builder.mutation<any, IQuestion>({
      query: ({ question }: IQuestion) => ({
        url: `/ask-me-anything`,
        method: "POST",
        body: { Q: question },
      }),
      transformResponse: (response: any) => {
        return {
          id: "123",
          question: "Welke leverancier levert lampen in regio Helmond?", // response.question,
          answer: "deze", // response.answer,
        };
      },
    }),

    estimateCosts: builder.mutation<string, IDocument>({
      query: (document: IDocument) => ({
        url: `/contracts/${document.contractId}/documents/${document.id}/estimateCosts`,
        method: "POST",
        body: { document },
      }),
      transformResponse: (response: any) => {
        // {
        //    AnalyzeCostString: '$0.59',
        //    AnalyzeCostFloat: '0.59',
        //    AnalyzeCostWithMarginString: '$0.71',
        //    AnalyzeCostWithMarginFloat: '0.71'
        // }

        return response?.data; //?.AnalyzeCostWithMarginString;
      },
    }),
  }),
});

export const {
  useAnalyzeFileMutation,
  useAskMeAnythingMutation,
  useAnalyzeContractFileMutation,
  useEstimateCostsMutation,
  useGetContractAnalysisStatusQuery,
  useLazyGetContractAnalysisStatusQuery,
  useAddAnalysisTaskMutation,
  useStopAnalysisTaskMutation,
} = AIApi;
