import styled from "@emotion/styled";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import {
  Box,
  CircularProgress,
  Button as MuiButton,
  Grid,
  Typography,
  Card as MuiCard,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  FormLabel,
  FormGroup,
  Checkbox,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import {
  useGetTrustedPartnerTrustsQuery,
  useUpdateContractMutation,
} from "../../../../redux/slices/indexApiSlice";
import { Euro, Edit as EditIcon, QuestionMark } from "@mui/icons-material";
import CustomNoRowsOverlay from "../../../../components/datagrids/CustomNoRowsOverlay";
import { IContract } from "../../../../types/contract";
import React, { useEffect, useState } from "react";
import { fontWeight, spacing, SpacingProps } from "@mui/system";
import { ITrustedPartnerTrust } from "../../../../types/trustedpartner";
import DataGridUsers from "../../../../components/datagrids/DataGridUsers";
import AzureADAvatar from "../../../../components/avatars/AzureADAvatar";
import useAuth from "../../../../hooks/useAuth";
import { IVendor } from "../../../../types/vendor";
import { skipToken } from "@reduxjs/toolkit/dist/query";

const TypographyLight = styled(Typography)`
  color: #646464;
`;

const Centered = styled.div`
  text-align: center;
`;

const Button = styled(MuiButton)(spacing);

const Card = styled(MuiCard)(spacing);
const Spacer = styled.div(spacing);

function SelectUsersDialog({
  ...props
}: {
  updateContract: (userIds: string[]) => Promise<void>;
  defaultSelectedUsers: string[];
}) {
  const { defaultSelectedUsers, updateContract } = props;
  const { t } = useTranslation();

  const { user } = useAuth();
  const [open, setOpen] = useState(false);

  const [selectedUsers, setSelectedUsers] = useState(defaultSelectedUsers);

  // check if there are trusted partner trusts
  const { data: trusts = [], isSuccess } = useGetTrustedPartnerTrustsQuery(
    user?.id ?? skipToken
  );

  const resetAndClose = () => {
    // set back to original
    setSelectedUsers(defaultSelectedUsers);

    setOpen(false);
  };

  const handleSaveClick = async () => {
    console.log("save selected users", selectedUsers);

    await updateContract(selectedUsers);

    // close the dialog
    setOpen(false);
  };

  const handlePartnerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setState({
    //   ...state,
    //   [event.target.name]: event.target.checked,
    // });
  };

  return (
    <>
      <Button onClick={() => setOpen(true)}>
        <EditIcon />
      </Button>
      <Dialog
        open={open}
        maxWidth={"lg"}
        fullWidth
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {t("Edit Assignee(s)")}
        </DialogTitle>
        <DialogContent>
          {trusts.length > 0 && (
            <DialogContentText>
              <FormControl
                sx={{ m: 3 }}
                component="fieldset"
                variant="standard"
              >
                <FormLabel component="legend">Assign partner</FormLabel>
                <FormGroup>
                  {trusts.map((trust: ITrustedPartnerTrust) => {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={false}
                            onChange={handlePartnerChange}
                            name={trust.partnerName}
                          />
                        }
                        label={trust.partnerName}
                      />
                    );
                  })}
                </FormGroup>
                <FormHelperText>
                  Assign the contract to a partner organisation
                </FormHelperText>
              </FormControl>
            </DialogContentText>
          )}

          <DialogContentText>
            {t(
              "Select the assignee(s) of the contract. Assignees will receive an email notification."
            )}
          </DialogContentText>

          <DataGridUsers selected={selectedUsers} onSelect={setSelectedUsers} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => resetAndClose()} color="primary">
            {t("Cancel")}
          </Button>
          <Button onClick={() => handleSaveClick()} color="primary">
            {t("Save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

interface IUserCardProps {
  contract: IContract;
  updateContract: (userIds: string[]) => Promise<void>;
  refetchMessages?: () => void;
  title: string;
  defaultSelectedUsers: string[];
}
function UserCard({ ...props }: IUserCardProps) {
  const { title, defaultSelectedUsers, updateContract } = props;

  const { t } = useTranslation();

  return (
    <Box>
      {/* <Grid container justifyContent="space-between">
        <Grid item>
          <TypographyLight variant="caption">{title}</TypographyLight>
        </Grid>

        <Grid item>
          <SelectUsersDialog
            defaultSelectedUsers={defaultSelectedUsers}
            updateContract={updateContract}
          />
        </Grid>
      </Grid> */}

      <TypographyLight variant="caption">{title}</TypographyLight>
      <Typography variant="body1">
        {defaultSelectedUsers?.length > 0
          ? defaultSelectedUsers.map((userId: string) => {
              return (
                <AzureADAvatar
                  key={`azure-ad-avatar-${userId}`}
                  userId={userId}
                  nameOnly
                />
              );
            })
          : t("Contract has not been assigned")}
      </Typography>

      {/* {defaultSelectedUsers?.length > 0 ? (
        <Centered>
          <Box p={2} display="flex" justifyContent="start">
            <AvatarGroup total={defaultSelectedUsers.length}>
              {defaultSelectedUsers.map((assigneeId: string) => {
                return (
                  <AzureADAvatar
                    key={`azure-ad-avatar-${assigneeId}`}
                    userId={assigneeId}
                    small
                  />
                );
              })}
            </AvatarGroup>

            <MessageDialog
              mode={DialogMode.Add}
              contract={props.contract}
              refetch={props.refetchMessages}
              selectedUserIds={defaultSelectedUsers}
              compact
            />
          </Box>
        </Centered>
      ) : (
        <CustomNoRowsOverlay label={t("Contract has not been assigned")} />
      )} */}
    </Box>
  );
}

type IVendorMetadataProps = {
  vendor: IVendor;
};

const VendorMetadata: React.FC<IVendorMetadataProps> = ({ vendor }) => {
  const { t } = useTranslation();

  const [updateContract, { isSuccess: isUpdated, reset: resetUpdate }] =
    useUpdateContractMutation();

  useEffect(() => {
    if (isUpdated) {
      resetUpdate();

      // refetch();
    }
  }, [isUpdated]);

  // const updateOwnerIds = async (ownerIds: string[]) => {
  //   await updateContract({
  //     ...contract,
  //     ownerIds,
  //   });
  // };

  // const updateManagerIds = async (managerIds: string[]) => {
  //   await updateContract({
  //     ...contract,
  //     managerIds,
  //   });
  // };

  const fullAddress = `${vendor.street} ${vendor.houseNumber} ${vendor.postalCode} ${vendor.city} ${vendor.country}`;

  return vendor ? (
    <Box mb={6}>
      <Typography variant="h6" mb={2}>
        {t("Vendor Information")}
      </Typography>

      {/* <Box mb={3}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <UserCard
              title={t("Account Manager")}
              contract={null}
              defaultSelectedUsers={vendor?.accountManagerIds}
              updateContract={updateAccountManagerIds}
            />
          </Grid>      
        </Grid>
      </Box> */}

      {/* <VendorAvatar vendorId={vendor?.id} big /> */}

      {vendor.name && (
        <Box mb={3}>
          <TypographyLight variant="caption">{t("Name")}</TypographyLight>
          <Typography variant="body1">{vendor.name}</Typography>
        </Box>
      )}
      {vendor.kvkNumber && (
        <Box mb={3}>
          <TypographyLight variant="caption">
            {t("Registration number")}
          </TypographyLight>
          <Typography variant="body1">{vendor.kvkNumber}</Typography>
        </Box>
      )}
      {vendor.description && (
        <Box mb={3}>
          <TypographyLight variant="caption">
            {t("Description")}
          </TypographyLight>
          <Typography variant="body1">{vendor.description}</Typography>
        </Box>
      )}
      {fullAddress && (
        <Box mb={3}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <TypographyLight variant="caption">
                {t("Address")}
              </TypographyLight>
              <Typography variant="body1">{fullAddress}</Typography>
            </Grid>
          </Grid>
        </Box>
      )}
      {vendor.phone && (
        <Box mb={3}>
          <TypographyLight variant="caption">
            {t("Phone number")}
          </TypographyLight>
          <Typography variant="body1">{vendor.phone}</Typography>
        </Box>
      )}

      {/* <Box mb={3}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <TypographyLight variant="caption">
              {t("Contact persons")}
            </TypographyLight>
            {vendor.created && (
              <Typography variant="body1">
                {format(new Date(vendor.created), "P")}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Box> */}

      {/* <Box mb={3}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <TypographyLight variant="caption">
              {t("Notice period")}
            </TypographyLight>
            {contract.noticePeriod && (
              <Typography variant="body1">
                {contract.noticePeriod?.value} {t(contract.noticePeriod?.unit)}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Box> */}

      {/* <Box mb={3}>
        <TypographyLight variant="caption">{t("Budget")}</TypographyLight>
        {contract.budget?.amount && (
          <Grid container alignContent="center">
            {contract.budget?.currency ? (
              new Intl.NumberFormat(navigator.language, {
                style: "currency",
                currency: contract.budget?.currency,
                compactDisplay: "long",
                maximumFractionDigits: 0,
              }).format(contract.budget?.amount)
            ) : (
              <NumericFormat
                value={contract.budget?.amount}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"? "}
              />
            )}
          </Grid>
        )}
      </Box> */}

      {/* {contract.risks?.map((risk: { risk: string; recommendation: string }) => { */}
      {/* {contract.risks?.map((risk) => {
        return (
          <Box mb={3}>
            <Box mb={1}>
              <TypographyLight variant="caption">{t("Risk")}</TypographyLight>
              <Typography variant="body1">{risk.risk}</Typography>
            </Box>
            <Box>
              <TypographyLight variant="caption">
                {t("Recommendation")}
              </TypographyLight>
              <Typography variant="body1">{risk.recommendation}</Typography>
            </Box>
          </Box>
        );
      })} */}
    </Box>
  ) : (
    <></>
  );
};

export default VendorMetadata;
