import React from "react";
import styled from "@emotion/styled";
import { Box, CircularProgress, Grid } from "@mui/material";
import GoBeyondLogo from "../icons/GoBeyondLogo";
import GlobalStyle from "./GlobalStyle";
import useTheme from "../hooks/useTheme";
import { THEMES } from "../constants";

const Root = styled(Box)<{ isdarkmode: boolean }>`
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  flex-direction: column;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  background-color: ${(props) => props.theme.palette.background.default};
  background-image: ${(props) =>
    props.isdarkmode
      ? `url("/static/img/backgrounds/dark-raster.png")`
      : `inherit`};
  //background-image: url("/static/img/backgrounds/2.jpg");
  background-position: 100 50;
  background-size: 85%;
`;

function FullScreenLoader() {
  const { theme } = useTheme();

  return (
    <Root isdarkmode={theme === THEMES.DARK}>
      <GlobalStyle />
      <Grid
        container
        display="flex"
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        <Grid item>
          <GoBeyondLogo isDrawerOpen={true} />
        </Grid>
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    </Root>
  );
}

export default FullScreenLoader;
