import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { blue, grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import {
  Box,
  Chip as MuiChip,
  Grid,
  Icon,
  LinearProgress,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  StepButton,
  Typography,
  useMediaQuery,
  useTheme,
  Menu,
  ListItemSecondaryAction,
  ListItem,
  ListItemText,
  List,
  ListItemButton,
  Fade,
  Alert,
  AlertTitle,
  Card,
  CardContent,
  Stack,
  CardActions,
  Button,
} from "@mui/material";
import {
  AutoAwesome,
  CheckCircleOutlined,
  FileDownloadOutlined,
  FileUploadOutlined,
  InsertDriveFileOutlined,
  MoreHoriz as MoreHorizIcon,
  Store,
} from "@mui/icons-material";
import { FileUploader } from "react-drag-drop-files";
import { DialogMode } from "../../../../types/dialogmode";
import { IContract } from "../../../../types/contract";
import { IDocument } from "../../../../types/document";
import CustomNoRowsOverlay from "../../../../components/datagrids/CustomNoRowsOverlay";
import { spacing } from "@mui/system";
import FileAsListItem from "../../../../components/lists/listitems/FileAsListItem";
import {
  useAddDocumentContentMutation,
  useAddDocumentMutation,
  useDeleteDocumentMutation,
  useGetAttachmentsQuery,
  useGetDocumentsQuery,
} from "../../../../redux/slices/indexApiSlice";
import { useNavigate, useParams } from "react-router-dom";
import FilesToUploadList from "../../../../components/lists/FilesToUploadList";
import { IVendor } from "../../../../types/vendor";
import { set } from "date-fns";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import VendorAsListItem from "../../../../components/lists/listitems/VendorAsListItem";
import VendorAvatar from "../../../../components/avatars/VendorAvatar";
import OwlAvatarIcon from "../../../../icons/OwlAvatarIcon";
import { C } from "@fullcalendar/core/internal-common";

const UploadButton = styled(MuiPaper)`
  height: 144px;
  border: 1px dashed ${(props) => props.theme.palette.divider};
  padding: 24px;
  cursor: pointer;
  &:hover {
    border: 1px dashed ${(props) => props.theme.palette.primary.main};
  }
`;

interface IContractPartiesProps {
  contract?: IContract;
  hideTitle?: boolean;
  setBadgeCount?: (count: number) => void;
}
function ContractParties({ ...props }: IContractPartiesProps) {
  const { contract } = props;

  const { t } = useTranslation();
  const theme = useTheme();
  const { id } = useParams();
  const navigate = useNavigate();

  const isDownLg = useMediaQuery(theme.breakpoints.down("lg"));

  // the filesToUpload holds all files that are uploading, regardsless of contractId
  // this ensures that the user can browse and upload files for multiple contracts at the same time
  const [filesToUpload, setFilesToUpload] = useState<
    {
      contractId: string;
      files: {
        file: File;
        progress: number;
        document?: IDocument; // when the progress is 100, the document is set
      }[];
    }[]
  >([]);

  // an array that keeps the uploading files for the current contract page
  const [contractUploadingFiles, setContractUploadingFiles] = useState<
    { file: File; progress: number }[]
  >([]);

  useEffect(() => {
    const contractFilesToUpload = filesToUpload?.find(
      (f) => f.contractId === id
    );

    setContractUploadingFiles(contractFilesToUpload?.files || []);
  }, [id, filesToUpload]);

  const [errorType, setErrorType] = useState<any>(null);

  const {
    data: attachments = [],
    isLoading: attachmentsLoading,
    isSuccess: attachmentsLoaded,
    isUninitialized: attachmentsUnitialized,
    refetch,
  } = useGetAttachmentsQuery(contract?.id ?? skipToken);

  useEffect(() => {
    props.setBadgeCount && props.setBadgeCount(attachments.length);
  }, [attachments]);

  return (
    <Box pb={2}>
      {!props.hideTitle && (
        <Typography variant="h6" mb={2}>
          {t("Parties")}
        </Typography>
      )}

      <Stack gap={2}>
        <Card variant="outlined">
          <CardContent>
            <Typography variant="body2" mb={2}>
              {t("Vendor")}
            </Typography>

            <Typography variant="body2">
              <Box>
                <Box>{contract?.seller?.name}</Box>
                <Box>{contract?.seller?.address}</Box>
              </Box>
            </Typography>

            {contract?.explanations?.seller && (
              <Alert
                sx={{ mt: 2 }}
                severity="info"
                variant="outlined"
                icon={<OwlAvatarIcon />}
              >
                <Typography variant="body1">
                  {contract?.explanations.seller}
                </Typography>
              </Alert>
            )}
          </CardContent>
          {contract?.vendorId && (
            <CardActions>
              <Button
                size="small"
                color="primary"
                variant="outlined"
                startIcon={<Store />}
                onClick={() => navigate(`/vendor/${contract.vendorId}`)}
              >
                {t("Vendor page")}
              </Button>
            </CardActions>
          )}
        </Card>

        <Card variant="outlined">
          <CardContent>
            <Typography variant="body2" mb={2}>
              {t("Customer")}
            </Typography>

            {contract?.buyer && (
              <Typography variant="body2">
                <Box>
                  <Box>{contract?.buyer?.name}</Box>
                  <Box>{contract?.buyer?.address}</Box>
                </Box>
              </Typography>
            )}

            {contract?.explanations?.buyer && (
              <Alert
                sx={{ mt: 2 }}
                severity="info"
                variant="outlined"
                icon={<OwlAvatarIcon />}
              >
                <Typography variant="body1">
                  {contract?.explanations.buyer}
                </Typography>
              </Alert>
            )}
          </CardContent>
        </Card>
      </Stack>
    </Box>
  );
}

export default ContractParties;
