import styled from "@emotion/styled";
import { format } from "date-fns";
import { PDFDocument } from "pdf-lib";
import {
  AppBar,
  Box,
  Card,
  Chip as MuiChip,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Toolbar,
  Typography,
  CardContent,
} from "@mui/material";
import { useEffect, useState } from "react";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import useAuth from "../../../../hooks/useAuth";
import DocumentViewer from "../../../../components/viewers/DocumentViewer";
import AIContractInformation from "../components/ContractMetadata";
import AIContractSigneeManagement from "../components/AIContractSigneeManagement";
import { ReactComponent as Logo } from "../../../../vendor/logo.svg";
import { ISignature, ISignee } from "../../../../types/signature";
import { useGetDocumentContentQuery } from "../../../../redux/slices/indexApiSlice";
import { createFileFromUrl } from "../../../../utils/file";
import {
  useAddContractAISignatureMutation,
  useAddSignatureDocumentContentMutation,
  useGetContractAISignatureQuery,
} from "../../../../redux/slices/digitalSigningSlice";
import { DialogMode } from "../../../../types/dialogmode";
import ContractHeader from "../components/ContractHeader";

import RejectDialog from "./RejectDialog";
import { darken } from "polished";
import SigningReceipt from "./SigningReceipt";
import SigningAppBar from "./SigningAppBar";
import useTheme from "../../../../hooks/useTheme";
import { THEMES } from "../../../../constants";

import CancelIcon from "@mui/icons-material/Cancel";

// function getFileTypeName(mimeType: string) {
//   const mimeEntry = mimeDb[mimeType];
//   if (mimeEntry && mimeEntry.extensions) {
//     const fileExtension = mimeEntry.extensions[0];
//     return fileExtension.toUpperCase();
//   }
//   return "Unknown";
// }

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 128px;
  height: auto;
  margin-bottom: 24px;
  cursor: pointer;
`;

const Chip = styled(MuiChip)<{ color?: string }>`
  // font-size: 85%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"]}; // .light
  // color: ${(props) => props.theme.palette.common.white};
`;

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const AIContractSign = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [file, setFile] = useState<any>(null);
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [signer, setSigner] = useState<ISignee>();
  const [signees, setSignees] = useState<Array<ISignee>>([]);
  const [signable, setSignable] = useState(
    !!(searchParams.get("code") && searchParams.get("hex"))
  );
  const [signatures, setSignatures] = useState<Array<ISignature>>([]);
  const [toggleSignaturePad, setToggleSignaturePad] = useState<
    boolean | undefined
  >();
  const [signatureSizePercentValue, setSignatureSizePercentValue] = useState({
    width: 0,
    height: 0,
  });
  const [showRejectDialog, setShowRejectDialog] = useState(false);

  const { setTheme, theme } = useTheme();

  // Since this is an external signing page, we force the light theme
  useEffect(() => {
    if (theme === THEMES.DARK) {
      setTheme(THEMES.LIGHT);
    }
  }, [theme]);

  const {
    data: contractDetail,
    isLoading: contractDetailLoading,
    isSuccess: contractDetailLoaded,
    isFetching: contractDetailFetching,
    refetch: contractDetailRefetch,
    error,
  } = useGetContractAISignatureQuery({
    code: searchParams.get("code"),
    hex: searchParams.get("hex"),
  });

  const {
    data: arrayBuffer,
    isSuccess: fileLoaded,
    isLoading: gettingDocumentContent,
  } = useGetDocumentContentQuery(contractDetail?.document, {
    skip: !contractDetail?.document,
  });

  const [
    addContractAISignature,
    { isLoading: isAdding, isSuccess: addSignSuccess, data: addedSignature },
  ] = useAddContractAISignatureMutation();

  const [
    addDocument,
    {
      isLoading: isUploading,
      isSuccess: documentUploaded,
      reset: resetContent,
    },
  ] = useAddSignatureDocumentContentMutation();

  useEffect(() => {
    if (error && error.status === 410) {
      navigate(
        `/410?page=signature&code=${searchParams.get(
          "code"
        )}&hex=${searchParams.get("hex")}`
      );
    } else if (error) {
      navigate(`/${error.status}`);
    }
  }, [error]);

  useEffect(() => {
    if (arrayBuffer) {
      const blob = new Blob([arrayBuffer as ArrayBuffer], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      console.log(url);

      setFileUrl(url);
    } else {
      setFileUrl(null);
    }
  }, [arrayBuffer]);

  useEffect(() => {
    if (addSignSuccess && addedSignature) {
      console.log("addedSignature: ", addedSignature);

      const formData = new FormData();
      // Append the File object to the FormData object
      formData.append("file", file);
      formData.append("email", addedSignature.email);
      formData.append("displayName", addedSignature.displayName);
      formData.append("contractName", contractDetail.name);
      addDocument({
        contractId: addedSignature?.contractId,
        documentId: addedSignature?.documentId,
        file: formData,
        type: "signed",
      });

      // update the signer's hasSigned status
      handleSaveSignature();
    }
  }, [addSignSuccess, addedSignature]);

  useEffect(() => {
    if (contractDetailLoaded && contractDetail) {
      const { dataDecrypted, signatures: _signatures } = contractDetail;

      const signatureId = _signatures.find(
        (sig: ISignature) => sig.email === dataDecrypted.email
      )?.id;

      setSigner({
        displayName: dataDecrypted.displayName,
        email: dataDecrypted.email,
        hasSigned: false,
        signatureId,
      });

      setSignees(
        _signatures.map((sig: ISignature) => {
          const signee: ISignee = {
            displayName: sig.displayName,
            email: sig.email,
            hasSigned: sig.isSigned,
          };

          return signee;
        })
      );

      setSignatures(_signatures);
      // if (contractDetail?.blob) {
      //   const blob = new Blob([contractDetail?.blob.data], { type: "application/pdf" });
      //   const url = URL.createObjectURL(blob);
      //   setFileUrl(url);
      // } else {
      //   setFileUrl(null);
      // }
    }
  }, [contractDetailLoaded]);

  useEffect(() => {
    setSignable(!!(searchParams.get("code") && searchParams.get("hex")));
  }, [searchParams.get("code"), searchParams.get("hex")]);

  const handleSaveSignature = () => {
    setSignees((prev) =>
      prev.map((signee) => {
        if (
          signee.email === signer?.email &&
          signee.email === addedSignature?.email
        ){
          signee.hasSigned = addedSignature?.isSigned;
          signee.isApproved = addedSignature?.isApproved;
        }

        return signee;
      })
    );

    setSigner((prev) => {
      if (prev) {
        const signee: ISignee = {
          ...prev,
          hasSigned:
            signer?.email === addedSignature?.email && addedSignature?.isSigned,
          isApproved: addedSignature?.isApproved
        };

        return signee;
      }
    });
  };

  useEffect(() => {
    addSignatureToPDF();
  }, [signatureSizePercentValue.width, signatureSizePercentValue.height]);

  const addSignatureToPDF = async () => {
    let userIndex = 0;
    const userSignature = signatures.find((sig, index) => {
      if (sig.email === signer?.email && signer.signatureId === sig.id) {
        userIndex = index;
      }
      return sig.email === signer?.email;
    });
    if (userSignature && userSignature?.signatureUrl) {
      const signatureFile = await createFileFromUrl(
        userSignature?.signatureUrl,
        contractDetail.name,
        "application/pdf"
      );
      if (signatureFile && userSignature.signedDate && arrayBuffer) {
        const pdfBytes = new Uint8Array((arrayBuffer as { data: ArrayBuffer; type: string; })?.data);
        console.log("pdfBytes", pdfBytes);
        const signatureBytes = await signatureFile.arrayBuffer();
        const pdfDoc = await PDFDocument.load(pdfBytes);
        const numPages = await pdfDoc.getPageCount();
        const pageHeightPercent = 1 / numPages;
        const pageIndex =
          userSignature.positionPercentValue.y > pageHeightPercent
            ? Math.floor(
                userSignature.positionPercentValue.y / pageHeightPercent
              )
            : 0;
        const page = await pdfDoc.getPage(pageIndex);
        const signatureImage = await pdfDoc.embedPng(signatureBytes);
        const { width, height } = page.getSize();
        // const signatureWith = 150;
        // const signatureHeight = 50;
        const signatureWith = signatureSizePercentValue.width * width;
        const signatureHeight = signatureSizePercentValue.height * height;

        // calculate coordinate difference:
        // 180: width of signature box
        // 700: width of wrapper (div have position relative) of signature boxs
        const deviation = (180 / 700) * userIndex;

        // console.table({
        //   signatureWith,
        //   width,
        //   userIndex,
        //   deviation,
        //   dif: signatureWith / width,
        // });

        const signatureX =
          width * (userSignature.positionPercentValue.x + deviation);
        const signatureY =
          userSignature.positionPercentValue.y > pageHeightPercent
            ? height -
              height *
                (userSignature.positionPercentValue.y -
                  pageHeightPercent * pageIndex) *
                numPages -
              signatureHeight
            : height -
              height * userSignature.positionPercentValue.y * numPages -
              signatureHeight;
        const name = userSignature.displayName;
        const nameSize = 11;
        const nameX = signatureX;
        const nameY = signatureY - nameSize - 10;
        const date = format(new Date(userSignature.signedDate), "PPPPp zzzz");
        const dateSize = 8;
        const dateX = signatureX;
        const dateY = nameY - dateSize - 4;
        page.drawImage(signatureImage, {
          x: signatureX,
          y: signatureY,
          width: signatureWith,
          height: signatureHeight,
        });

        page.drawText(name, {
          x: nameX,
          y: nameY,
          size: nameSize,
        });

        page.drawText(date, {
          x: dateX,
          y: dateY,
          size: dateSize,
        });

        if (userSignature.reason) {
          const reason = userSignature.reason;
          const reasonSize = 8;
          const reasonX = signatureX;
          const reasonY = dateY - reasonSize - 4;
          page.drawText(reason, {
            x: reasonX,
            y: reasonY,
            size: reasonSize,
          });
        }

        const modifiedPdfBytes = await pdfDoc.save();
        const blob = new Blob([modifiedPdfBytes], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        setFileUrl(url);

        const newFile = await createFileFromUrl(
          url,
          contractDetail.name,
          "application/pdf"
        );
        console.log(URL.createObjectURL(newFile));
        setFile(newFile);

        addContractAISignature({
          contractId: contractDetail.id,
          signatureId: userSignature.id,
          code: searchParams.get("code"),
          hex: searchParams.get("hex"),
          isApproved: userSignature.isApproved,
          reason: userSignature.reason,
        });
      }
    }
  };

  const handleSignButton = () => {
    if (setToggleSignaturePad) {
      setToggleSignaturePad((prev) => !prev);
    }
  };

  const onReject = (reason: string) => {

    console.log("Rejecting: ", reason);

    // create a new canvas element that shows the rejected stamp
    const offscreenCanvas = document.createElement("canvas");
    offscreenCanvas.width = 180;
    offscreenCanvas.height = 75;

    // add the img to the canvas
    const img = new Image();
    img.width = 200;
    img.height = 93;
    img.src = "/static/img/RejectedStamp.png";

    img.onload = () => {
      const ctx = offscreenCanvas.getContext("2d");
      ctx?.drawImage(img, 0, 0, offscreenCanvas.width, offscreenCanvas.height);

      const dataUrl = offscreenCanvas.toDataURL();

      if (setSignatures) {
        setSignatures((prev) => {
          const newSignatures = prev.map((sig) => {
            if (sig.email === signer?.email) {
              return {
                ...sig,
                signatureUrl: dataUrl,
                signedDate: new Date().toISOString(),
                reason: reason,
                isSigned: true,
                isApproved: false,
              };
            } else {
              return { ...sig };
            }
          });

          return newSignatures;
        });
      }

      setShowRejectDialog(false);
    };
  };

  return (
    <>
      <SigningAppBar
        contractDetail={contractDetail}
        handleSignButton={handleSignButton}
        handleRejectButton={() => setShowRejectDialog(true)}
        signable={signable}
        signature={signatures?.find((sig) => sig.email === signer?.email)}
        signer={signer}
        loading={isAdding || isUploading}
      />

      <Container maxWidth="lg">
        {contractDetailLoading || gettingDocumentContent ? (
          <Box textAlign="center">
            <CircularProgress />
          </Box>
        ) : (
          <Paper
            sx={{
              marginTop: 10,
              marginBottom: 60,
              paddingLeft: 4,
              paddingRight: 4,
              paddingTop: 4,
            }}
          >
            {contractDetail && (
              <Box mb={4}>
                <ContractHeader
                  contract={{ ...contractDetail, status: null }}
                />
              </Box>
            )}

            {fileUrl && signer && (
              <Grid
                container
                justifyContent="space-between"
                flexDirection="row"
              >
                <Grid item xs={12} md={8} mb={6}>
                  <Box>
                    <AIContractInformation contract={contractDetail} />
                  </Box>

                  <DocumentViewer
                    file={file}
                    document={contractDetail?.document}
                    // fileUrl={fileUrl}
                    hideFileMenu
                    viewMode="pageOnly"
                    signable={signable}
                    signer={signer}
                    signatures={signatures}
                    setSignatures={setSignatures}
                    onSaveSignature={handleSaveSignature}
                    setSignatureSizePercentValue={setSignatureSizePercentValue}
                    toggleSignaturePad={toggleSignaturePad}
                    disableAppBar
                    disableScroller
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <Box flex={1}>
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        mb: 6,
                        py: 6,
                        px: 3,
                        backgroundColor: "background.default",
                      }}
                    >
                      <AIContractSigneeManagement
                        signer={signer}
                        signable={signable}
                        signees={signees}
                        setSignees={setSignees}
                        setSignatures={setSignatures}
                        signatures={signatures}
                        setToggleSignaturePad={setToggleSignaturePad}
                        contract={contractDetail}
                        document={contractDetail?.document}
                        mode={DialogMode.Edit}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Paper>
        )}
      </Container>

      <RejectDialog
        open={showRejectDialog}
        onClose={() => setShowRejectDialog(false)}
        onReject={onReject}
      />
    </>
  );
};

export default AIContractSign;
