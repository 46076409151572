import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";

import {
  Badge,
  Grid,
  Avatar,
  Typography,
  Button,
  IconButton,
  Icon,
  Fade,
  ListItemAvatar,
  ListItemText,
  ListItem,
} from "@mui/material";

import { Settings as SettingsIcon } from "@mui/icons-material";

import useAuth from "../../hooks/useAuth";
import {
  useGetUserPhotoMutation,
  useGetUserProfileQuery,
} from "../../redux/slices/graphApiSlice";
import { is } from "date-fns/locale";
import AzureADAvatar from "../avatars/AzureADAvatar";
import { useNavigate } from "react-router-dom";
import SidebarNavListItem from "./SidebarNavListItem";

const Footer = styled.div`
  background-color: ${(props) =>
    props.theme.sidebar.footer.background} !important;
  padding: ${(props) => props.theme.spacing(0.75)}
    ${(props) => props.theme.spacing(4)};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const FooterText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
`;

const FooterSubText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
  font-size: 0.7rem;
  display: block;
  padding: 1px;
`;

const FooterIcon = styled(Icon)`
  color: ${(props) => props.theme.sidebar.footer.color};
`;

const FooterBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)};
  span {
    background-color: ${(props) =>
      props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

const SidebarFooter = ({
  isDrawerOpen,
  ...rest
}: {
  isDrawerOpen: boolean;
  [key: string]: any;
}) => {
  const navigate = useNavigate();

  const { user, isAuthenticated, method } = useAuth();
  const { data: userProfile } = useGetUserProfileQuery();
  // const { data: photoData, isLoading, isError } = useGetUserPhotoMutation();
  // const [getUserPhoto] = useGetUserPhotoMutation();

  // const [imageUrl, setImageUrl] = useState<string | null>(null);

  // // console.log("userProfile", userProfile);

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     getUserPhoto()
  //       .unwrap()
  //       .then((url: any) => {
  //         console.log(url);
  //         setImageUrl(url);
  //       });
  //   }
  // }, [isAuthenticated, user]);

  const goToSettings = () => {
    navigate("/settings");
  };

  return (
    <Footer {...rest}>
      <ListItem disablePadding sx={{ py: 3 }}>
        <ListItemAvatar>
          {!!user && method == "Google" ? (
            <Avatar alt={user.displayName} src={user.avatar} />
          ) : (
            <AzureADAvatar userId={user?.id} pictureOnly />
          )}
        </ListItemAvatar>
        <ListItemText>
          <Grid item flexGrow={1} sx={{ opacity: isDrawerOpen ? 1 : 0 }}>
            {user && (
              <FooterText variant="body2">{user?.displayName}</FooterText>
            )}
            <FooterSubText variant="caption">
              {userProfile?.jobTitle}
            </FooterSubText>
          </Grid>
        </ListItemText>
      </ListItem>
    </Footer>
  );
};

export default SidebarFooter;
