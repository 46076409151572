import styled from "@emotion/styled";
import LoadingButton from "@mui/lab/LoadingButton";
import { SendRounded } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Input,
  ListItem,
  ListItemButton,
  TextField,
  Typography,
} from "@mui/material";
import useAuth from "../../hooks/useAuth";
import useBotChat from "../../hooks/useBotChat";
import ChatMessage from "./ChatMessage";
import { convertCreatedDate } from "../../utils/string";

import {
  useAddChatBotMutation,
  useUpdateChatBotMutation,
} from "../../redux/slices/indexApiSlice";
import {
  Author,
  ChatMessageType,
  ChatType,
  IChatBotHistory,
  NewChatBoxMessage,
} from "../../types/chatBot";

import { useParams } from "react-router-dom";
import { ChatInputField } from "./ChatBox";

const ChatInputWidget: React.FC = () => {
  const { user } = useAuth();
  const { t } = useTranslation();

  const { contractId, vendorId } = useParams<string>();

  const {
    botName,
    setOpen: setShowBotChat,
    addedMessage,
    setAddedMessage,
  } = useBotChat();

  const inputRef = useRef(null);

  const [responding, setResponding] = useState(false);
  const [sentMessage, setSentMessage] = useState("");
  const [chatBoxType, setChatBoxType] = useState<ChatType>(ChatType.GENERAL);

  const handleEnterSentMessage = async (event: any) => {
    if (event.key === "Enter" && !!sentMessage && !responding) {
      setAddedMessage(sentMessage);
    }
  };

  const handleSendMessage = async () => {
    if (!!sentMessage && !responding) {
      setAddedMessage(sentMessage);
    }
  };

  return (
    <strong className="no-cursor">
      <Box display="flex" alignItems="center" pb={1}>
        <ListItem
          disablePadding
          secondaryAction={
            <SendRounded
              sx={{ cursor: "pointer" }}
              color="primary"
              onClick={handleSendMessage}
            />
          }
          sx={{ pb: 1 }}
        >
          <ListItemButton
            role={undefined}
            dense
            sx={{
              borderRadius: 2,
            }}
          >
            <TextField
              ref={inputRef}
              type="text"
              value={sentMessage}
              onInput={(e) =>
                setSentMessage((e.target as HTMLInputElement).value)
              }
              onKeyDown={handleEnterSentMessage}
              placeholder={t("Ask your question") + "..."}
              fullWidth
              size="small"
              variant="outlined"
              sx={{
                // no border
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                  "&:hover fieldset": {
                    border: "none",
                  },
                  "&.Mui-focused fieldset": {
                    border: "none",
                  },
                },
              }}
            />
          </ListItemButton>
        </ListItem>
      </Box>
    </strong>
  );
};

export default ChatInputWidget;
