import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";

import MoreVertIcon from "@mui/icons-material/MoreVert";

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid as MuiGrid,
  LinearProgress as MuiLinearProgress,
  Typography as MuiTypography,
  Container,
  Pagination as MuiPagination,
  Paper as MuiPaper,
  CircularProgress as MuiCircularProgress,
  makeStyles,
  Tooltip,
  IconButton,
  Fade,
  CardMedia as MuiCardMedia,
  CardActionArea,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField as MuiTextField,
  Alert as MuiAlert,
  ListItem,
  ListItemIcon,
  Menu,
  MenuItem,
  MenuList,
  Popper,
  ClickAwayListener,
  Grow,
  StepContent,
  useMediaQuery,
  Theme,
  ListItemText,
  ListItemSecondaryAction,
  ListItemButton,
  Alert,
} from "@mui/material";

import { display, fontWeight, spacing, SpacingProps } from "@mui/system";

import {
  useDeleteDocumentMutation,
  useDeleteNoteMutation,
  useUpdateNoteMutation,
} from "../../../redux/slices/indexApiSlice";
import { IDocument } from "../../../types/document";

import { useTranslation } from "react-i18next";

import { INote } from "../../../types/note";
import { IGraphUser } from "../../../types/user";
import AzureADAvatar from "../../avatars/AzureADAvatar";
import DateTimeTranslate from "../../DateTimeTranslate";
import StyledMenu from "../../../theme/StyledMenu";
import NoteDialog from "../../dialogs/NoteDialog";
import { DialogMode } from "../../../types/dialogmode";
import {
  Alarm,
  AlarmOff,
  AlarmOn,
  Note,
  NoteAdd,
  NoteAlt,
} from "@mui/icons-material";
import useAuth from "../../../hooks/useAuth";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

const Grid = styled(MuiGrid)(spacing);

interface TypographyProps extends SpacingProps {
  component?: string;
}
const Typography = styled(MuiTypography)<TypographyProps>(spacing);

const Title = styled(Typography)`
  color: ${(props) => props.theme.palette.text.secondary};
  font-size: ${(props) => props.theme.typography.caption.fontSize};
  padding: ${(props) => props.theme.spacing(4)}
    ${(props) => props.theme.spacing(7)} ${(props) => props.theme.spacing(1)};
  opacity: 0.8;
  text-transform: uppercase;
  display: block;
`;

interface INoteAsListItemProps {
  note: INote;
  showMenuIcon?: boolean;
  onMouseEnter?: () => void;
}

const NoteAsListItem = (props: INoteAsListItemProps) => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const { note } = props;

  const users = useSelector((state: RootState) => state.users.users);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const formattedBody = note?.body
    ?.split("\n")
    .map((line: string, index: number) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));

  // check if taggedusers is an array or string
  // if it's a string, convert it to an array
  const taggedUsers = note.taggedUsers;

  const authorId =
    typeof note?.author === "string" ? note.author : note?.author?.id;

  const displayNames: string[] = [];

  if (taggedUsers.some((userId: string) => userId === user?.id)) {
    displayNames.push(t("You"));
  }

  taggedUsers
    ?.filter((userId: string) => userId !== user?.id)
    .map((userId: string) => {
      const taggedUser = users?.find((user) => user.id === userId);

      const displayName =
        taggedUser?.givenName ||
        taggedUser?.displayName ||
        taggedUser?.mail ||
        "";

      displayNames.push(displayName);
    });

  const label =
    displayNames?.length === 1 && taggedUsers[0] === user?.id
      ? "me"
      : displayNames?.length === 1
      ? "single"
      : "multi";

  const displayNamesString =
    displayNames?.length === 2
      ? displayNames?.join(` ${t("and")} `)
      : displayNames?.join(", ");

  const isPast = note.reminderDate && new Date(note.reminderDate) < new Date();

  return (
    <ListItem
      // component={props.onClick ? ListItemButton : ListItem}
      alignItems="flex-start"
      onMouseEnter={props.onMouseEnter}
    >
      {/* <ListItemIcon>
        <AzureADAvatar userId={authorId} pictureOnly />
        {note.reminderDate ? <Alarm /> : <NoteAlt />}
      </ListItemIcon> */}

      <ListItemText>
        <Grid container flexDirection="column" spacing={1}>
          <Grid item>
            {note.reminderDate && (
              <Typography variant="subtitle2">
                <DateTimeTranslate
                  date={new Date(note.reminderDate)}
                  format="dateOnly"
                ></DateTimeTranslate>
              </Typography>
            )}
            <Typography variant="body1">{formattedBody}</Typography>
          </Grid>

          <Grid item>
            <Box pt={1}>
              {taggedUsers?.map((userId: string) => {
                // return <Chip key={userId} size="small" mr={1} mb={1} label={userId} color="secondary" />;
                return (
                  <AzureADAvatar
                    key={`user-${userId}`}
                    userId={userId}
                    tagged
                  />
                );
                // return <Chip key={userId} size="small" mr={1} mb={1} label={`@${taggedUser?.displayName}`} color="default" />;
              })}
            </Box>
          </Grid>

          {note.reminderDate && displayNamesString && (
            <Grid item>
              <Alert
                severity={isPast ? "warning" : "info"}
                icon={isPast ? <AlarmOff /> : <AlarmOn />}
              >
                {isPast ? (
                  <Typography>
                    {t(`reminder_date_text_past_due_${label}`, {
                      displayNames: displayNamesString?.replace(
                        t("You")!,
                        t("you")
                      ),
                      date: new Date(note.reminderDate),
                      format: "dateOnly",
                    })}
                  </Typography>
                ) : (
                  <Typography>
                    {t(`reminder_date_text_${label}`, {
                      displayNames: displayNamesString,
                      date: new Date(note.reminderDate),
                      format: "dateOnly",
                    })}
                  </Typography>
                )}
              </Alert>
            </Grid>
          )}

          {note.created && (
            <Grid item>
              <Typography
                variant="body2"
                color="text.secondary"
                fontSize="0.7rem"
              >
                {t("Created")}:{" "}
                <DateTimeTranslate
                  date={new Date(note.created)}
                  format="ago"
                ></DateTimeTranslate>{" "}
                {authorId && (
                  <>
                    {t("by")} <AzureADAvatar userId={authorId} nameOnly />
                  </>
                )}
              </Typography>
            </Grid>
          )}
        </Grid>
      </ListItemText>
      <ListItemSecondaryAction sx={{ top: 30 }}>
        <IconButton
          aria-label="more"
          id={`note-menubutton-${note.id}`}
          aria-controls={open ? `note-menu-${note.id}` : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          sx={{ visibility: props.showMenuIcon ? "visible" : "hidden" }}
        >
          <MoreVertIcon />
        </IconButton>
        <StyledMenu
          id={`note-menu-${note.id}`}
          MenuListProps={{
            "aria-labelledby": `note-menubutton-${note.id}`,
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {/* <Divider sx={{ my: 0.5 }} /> */}

          <NoteDialog
            mode={DialogMode.Edit}
            type={note.reminderDate ? "reminder" : "note"}
            note={props.note}
            asMenuItem
          />

          <NoteDialog
            mode={DialogMode.Delete}
            type={note.reminderDate ? "reminder" : "note"}
            note={props.note}
            asMenuItem
          />
        </StyledMenu>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default NoteAsListItem;
