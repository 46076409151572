import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { blue, grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import {
  Box,
  Chip as MuiChip,
  Grid,
  Icon,
  LinearProgress,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  StepButton,
  Typography,
  useMediaQuery,
  useTheme,
  Menu,
  ListItemSecondaryAction,
  ListItem,
  ListItemText,
  List,
  ListItemButton,
  Fade,
  Alert,
  AlertTitle,
} from "@mui/material";
import {
  AutoAwesome,
  CheckCircleOutlined,
  FileDownloadOutlined,
  FileUploadOutlined,
  InsertDriveFileOutlined,
  MoreHoriz as MoreHorizIcon,
} from "@mui/icons-material";
import { FileUploader } from "react-drag-drop-files";
import { DialogMode } from "../../../../types/dialogmode";
import { IContract } from "../../../../types/contract";
import { IDocument } from "../../../../types/document";
import CustomNoRowsOverlay from "../../../../components/datagrids/CustomNoRowsOverlay";
import { spacing } from "@mui/system";
import FileAsListItem from "../../../../components/lists/listitems/FileAsListItem";
import {
  useAddDocumentContentMutation,
  useAddDocumentMutation,
  useDeleteDocumentMutation,
  useGetAttachmentsQuery,
  useGetDocumentsQuery,
} from "../../../../redux/slices/indexApiSlice";
import { useParams } from "react-router-dom";
import FilesToUploadList from "../../../../components/lists/FilesToUploadList";
import { IVendor } from "../../../../types/vendor";
import { set } from "date-fns";
import { skipToken } from "@reduxjs/toolkit/dist/query";

const UploadButton = styled(MuiPaper)`
  height: 144px;
  border: 1px dashed ${(props) => props.theme.palette.divider};
  padding: 24px;
  cursor: pointer;
  &:hover {
    border: 1px dashed ${(props) => props.theme.palette.primary.main};
  }
`;

interface IContractAttachmentsProps {
  mode: DialogMode;
  contract: IContract;
  vendor?: IVendor;
  hideTitle?: boolean;
  setBadgeCount?: (count: number) => void;
}
function ContractAttachments({ ...props }: IContractAttachmentsProps) {
  const { mode, contract } = props;

  const { t } = useTranslation();
  const theme = useTheme();

  const isDownLg = useMediaQuery(theme.breakpoints.down("lg"));

  // the filesToUpload holds all files that are uploading, regardsless of contractId
  // this ensures that the user can browse and upload files for multiple contracts at the same time
  const [filesToUpload, setFilesToUpload] = useState<
    {
      contractId: string;
      files: {
        file: File;
        progress: number;
        document?: IDocument; // when the progress is 100, the document is set
      }[];
    }[]
  >([]);

  // an array that keeps the uploading files for the current contract page
  const [contractUploadingFiles, setContractUploadingFiles] = useState<
    { file: File; progress: number }[]
  >([]);

  useEffect(() => {
    const contractFilesToUpload = filesToUpload?.find(
      (f) => f.contractId === contract.id
    );

    setContractUploadingFiles(contractFilesToUpload?.files || []);
  }, [contract.id, filesToUpload]);

  const [errorType, setErrorType] = useState<any>(null);

  const {
    data: attachments = [],
    isLoading: attachmentsLoading,
    isSuccess: attachmentsLoaded,
    isUninitialized: attachmentsUnitialized,
    refetch,
  } = useGetAttachmentsQuery(contract.id ?? skipToken);

  const [
    addDocument,
    {
      isLoading: addingDocument,
      isSuccess: documentAdded,
      data: addedDocument,
      reset: resetDocumentAdd,
      isError: errorWhileAddingDocument,
      error: addedDocumentErrorObject,
    },
  ] = useAddDocumentMutation();

  const [
    uploadDocumentContent,
    {
      isLoading: isUploading,
      isSuccess: documentContentStored,
      reset: resetContent,
      isError: errorWhileUploading,
      error: uploadErrorObject,
    },
  ] = useAddDocumentContentMutation();

  const [
    deleteDocument,
    { isLoading: deletingDocument, isSuccess: documentDeleted },
  ] = useDeleteDocumentMutation();

  useEffect(() => {
    props.setBadgeCount && props.setBadgeCount(attachments.length);
  }, [attachments]);

  useEffect(() => {
    if (attachmentsLoaded && !attachmentsLoading) {
      removeCompletedUploads();
    }
  }, [
    attachmentsLoaded,
    attachmentsLoading,
    attachments,
    isUploading,
    documentContentStored,
  ]);

  useEffect(() => {
    if (filesToUpload?.length > 0) {
      for (const fileToUpload of filesToUpload) {
        const notStartedFiles = fileToUpload.files.filter(
          (f) => f.progress === 0
        );

        notStartedFiles.forEach((f) => {
          uploadFile(f.file, fileToUpload.contractId);
        });
      }
    }
  }, [filesToUpload]);

  const onTypeError = (error: any) => {
    setErrorType(error);
    console.log(error);
  };
  const fileTypes = ["PDF"];

  const handleProgress = (
    contractId: string,
    filename: string,
    progress: number,
    index: number, // pass the index in case there are documents with the same filename
    createdDocument?: IDocument
  ) => {
    for (const file of filesToUpload) {
      // ensure to keep the indexes the same
      if (file.contractId === contractId) {
        file.files[index].progress = progress;

        if (createdDocument) {
          file.files[index].document = createdDocument;
        }
      }
    }

    setFilesToUpload([...filesToUpload]);
  };

  const uploadFile = (
    fileToUpload: File,
    contractId: string
  ): PromiseLike<boolean> => {
    return new Promise((resolve, reject) => {
      const contractElement = filesToUpload?.find(
        (el) => el.contractId === contractId
      );

      const index = contractElement?.files.findIndex(
        (f) =>
          f.file instanceof File &&
          f.file.name === fileToUpload.name &&
          f.file.size === fileToUpload.size &&
          f.progress === 0
      );

      if (index !== undefined && index > -1) {
        handleProgress(contractId, fileToUpload.name, 10, index);

        // create a document for the file
        const newDocument: IDocument = {
          name: fileToUpload.name,
          type: "attachment",
          origin: "upload",
          location: "blobstorage",
          size: fileToUpload.size,
          contractId: contract.id,
        };

        addDocument(newDocument)
          .unwrap()
          .then((document_payload) => {
            handleProgress(contractId, fileToUpload.name, 50, index);

            // upload the file content
            uploadLocalFileContentToAzureBlobStorage(
              fileToUpload,
              document_payload
            )
              .then((u: any) => {
                handleProgress(
                  contractId,
                  fileToUpload.name,
                  100,
                  index,
                  document_payload
                );

                resolve(true);
              })
              .catch((error: any) => {
                reject(error);
              });
          })
          .catch((error: any) => {
            reject(error);
          });
      }
    });
  };

  const uploadLocalFileContentToAzureBlobStorage = async (
    fileToUpload: File,
    document: IDocument
  ) => {
    if (document?.origin === "upload") {
      const formData = new FormData();
      formData.append("file", fileToUpload);
      await uploadDocumentContent({
        contractId: document.contractId,
        documentId: document.id,
        file: formData,
      });
    }
  };

  const addFiles = (moreFiles: FileList) => {
    const moreFilesArray = Array.from(moreFiles);

    const moreFilesWithProgress = moreFilesArray.map((f) => {
      return { file: f, progress: 0 };
    });

    if (filesToUpload.findIndex((f) => f.contractId === contract.id) === -1) {
      // add the contractId to the filesToUpload array
      const contractElement = {
        contractId: contract.id,
        files: moreFilesWithProgress,
      } as any;

      setFilesToUpload((prev) => [...prev, contractElement]);
    } else {
      // add the files to the filesToUpload array
      const newFilesToUpload = filesToUpload.map((f) => {
        if (f.contractId === contract.id) {
          return {
            ...f,
            files: [...f.files, ...moreFilesWithProgress],
          };
        } else {
          return f;
        }
      });

      setFilesToUpload(newFilesToUpload);
    }

    // moreFilesArray.forEach((f: File) => {
    //   uploadFile(f, id);
    // });
  };

  const handleDeleteFileToUpload = (index: number) => {
    // the index is the index of the file in the filesToUpload
    const newFilesToUpload = filesToUpload.map((f) => {
      if (f.contractId === contract.id) {
        const documentToDelete = f.files[index].document;
        if (documentToDelete) {
          // the file has been uploaded, delete the document
          handleDeleteDocument(documentToDelete);
        }

        const newFiles = f.files.filter((file, i) => i !== index);
        return { ...f, files: newFiles };
      }
      return f;
    });

    setFilesToUpload(newFilesToUpload);
  };

  const removeCompletedUploads = () => {
    // remove the finished uploads
    const newFilesToUpload = filesToUpload.map((f) => {
      if (f.contractId === contract.id) {
        const newFiles = f.files.filter((file) => file.progress !== 100);
        return { ...f, files: newFiles };
      }
      return f;
    });
    setFilesToUpload(newFilesToUpload);

    const newContractUploadingFiles = contractUploadingFiles.filter(
      (f) => f.progress !== 100
    );
    setContractUploadingFiles(newContractUploadingFiles);
  };

  const handleDeleteDocument = async (document: IDocument) => {
    await deleteDocument(document).then((res: any) => {
      if (res?.data) {
        refetch();
      }
    });
  };

  return (
    <Box
      flex="1"
      display="flex"
      flexDirection="column"
      alignItems={isDownLg ? "flex-start" : "flex-end"}
    >
      <Box width="100%">
        {!props.hideTitle && (
          <Typography variant="h6" mb={2}>
            {t("Attachments")}
          </Typography>
        )}

        <Fade in appear>
          <Grid container gap={3}>
            <Grid
              item
              xs={12}
              md={12}
              sx={
                mode === DialogMode.ViewOnly
                  ? {
                      pointerEvents: "none",
                      opacity: 0.5,
                    }
                  : null
              }
            >
              <FileUploader
                multiple={true}
                handleChange={addFiles}
                name="file"
                types={fileTypes}
                onTypeError={onTypeError}
              >
                <UploadButton
                  variant="outlined"
                  onClick={() => console.log("upload button clicked")}
                  sx={{ cursor: "pointer" }}
                >
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box>
                      <FileUploadOutlined
                        fontSize="small"
                        color="secondary"
                      ></FileUploadOutlined>
                    </Box>
                    <Typography mt={2} mb={1} variant="body2">
                      <Link underline="none">{t("Click_to_upload")}</Link>{" "}
                      {t("or_drag_and_drop")}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {t("Maximum_file_size", { size: 30 })}
                    </Typography>
                  </Grid>
                </UploadButton>
              </FileUploader>
            </Grid>

            <Grid item xs={12} md={12}>
              {/* {files?.length === 0 ? (
                // <CustomNoRowsOverlay label={t("No attachments")} />
                <Typography variant="body2" color="textSecondary">
                  {t("No attachments")}
                </Typography>
              ) : ( */}
              {errorWhileAddingDocument && (
                <Alert severity="error">
                  <AlertTitle>{t("An error occurred!")}</AlertTitle>
                  {JSON.stringify(addedDocumentErrorObject)}
                </Alert>
              )}
              {errorWhileUploading && (
                <Alert severity="error">
                  <AlertTitle>{t("An error occurred!")}</AlertTitle>
                  {JSON.stringify(uploadErrorObject)}
                </Alert>
              )}

              <FilesToUploadList
                mode={mode}
                filesWithProgress={contractUploadingFiles}
                documents={attachments}
                handleDeleteFileToUpload={handleDeleteFileToUpload}
                handleDeleteDocument={handleDeleteDocument}
              />

              {/* {allFiles?.length > 0 && (
                <List dense>
                  {allFiles?.map((f: File | IDocument, i: number) => {
                    return (
                      <Grid item>
                        <FileAsListItem
                          onClick={() =>
                            selectedIndex === i // ensures that the user can unselect the document
                              ? setSelectedIndex(-1)
                              : setSelectedIndex(i)
                          }
                          file={f}
                          onDelete={
                            f instanceof File
                              ? () => handleDeleteFileToUpload(i)
                              : () => handleDeleteDocument(f)
                          }
                          uploading={false}
                          uploadPercentProgress={40}
                        />
                      </Grid>
                    );
                  })}
                </List>
              )} */}
            </Grid>
          </Grid>
        </Fade>
      </Box>
    </Box>
  );
}

export default ContractAttachments;
