import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { blue, grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import {
  Box,
  Chip as MuiChip,
  Grid,
  Icon,
  LinearProgress,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  StepButton,
  Typography,
  useMediaQuery,
  useTheme,
  Menu,
  ListItemSecondaryAction,
  ListItem,
  ListItemText,
  List,
  ListItemButton,
  Fade,
  Alert,
  AlertTitle,
} from "@mui/material";
import {
  AutoAwesome,
  CheckCircleOutlined,
  FileDownloadOutlined,
  FileUploadOutlined,
  InsertDriveFileOutlined,
  MoreHoriz as MoreHorizIcon,
} from "@mui/icons-material";
import { FileUploader } from "react-drag-drop-files";
import { DialogMode } from "../../../../types/dialogmode";
import { IContract } from "../../../../types/contract";
import { IDocument } from "../../../../types/document";
import CustomNoRowsOverlay from "../../../../components/datagrids/CustomNoRowsOverlay";
import { spacing } from "@mui/system";
import FileAsListItem from "../../../../components/lists/listitems/FileAsListItem";
import {
  useAddDocumentContentMutation,
  useAddDocumentMutation,
  useDeleteDocumentMutation,
  useGetAttachmentsQuery,
  useGetDocumentsQuery,
} from "../../../../redux/slices/indexApiSlice";
import { useParams } from "react-router-dom";
import FilesToUploadList from "../../../../components/lists/FilesToUploadList";
import { IVendor } from "../../../../types/vendor";
import { format, set } from "date-fns";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import OwlAvatarIcon from "../../../../icons/OwlAvatarIcon";

const UploadButton = styled(MuiPaper)`
  height: 144px;
  border: 1px dashed ${(props) => props.theme.palette.divider};
  padding: 24px;
  cursor: pointer;
  &:hover {
    border: 1px dashed ${(props) => props.theme.palette.primary.main};
  }
`;

interface IContractDatesProps {
  contract?: IContract;
  hideTitle?: boolean;
}
function ContractDates({ ...props }: IContractDatesProps) {
  const { contract } = props;
  const { t } = useTranslation();

  return (
    <Box pb={2}>
      {!props.hideTitle && (
        <Typography variant="h6" mb={2}>
          {t("End date")}
        </Typography>
      )}

      {(contract?.startDate || contract?.endDate) && (
        <Box mb={3}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography variant="caption">{t("Start date")}</Typography>
              {contract.startDate && (
                <Typography variant="body1">
                  {format(new Date(contract.startDate), "P")}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="caption">{t("End date")}</Typography>
              {contract.endDate && (
                <Typography variant="body1">
                  {format(new Date(contract.endDate), "P")}
                </Typography>
              )}
            </Grid>

            {contract.explanations?.dates && (
              <Grid item xs={12} md={12}>
                <Alert
                  sx={{ mt: 2 }}
                  severity="info"
                  variant="outlined"
                  icon={<OwlAvatarIcon />}
                >
                  <Typography variant="body1">
                    {contract.explanations.dates}
                  </Typography>
                </Alert>
              </Grid>
            )}
          </Grid>
        </Box>
      )}
    </Box>
  );
}

export default ContractDates;
