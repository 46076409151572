import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Alert as MuiAlert,
  AvatarGroup,
  Box,
  Button as MuiButton,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  Grid,
  IconButton,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  TextField as MuiTextField,
  Typography,
  ImageList,
  ImageListItem,
  Tab,
  Tabs,
  ListItemButton,
  Tooltip,
} from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { spacing } from "@mui/system";
import {
  useAddTrustedPartnerInviteAcceptedMutation,
  useAddTrustedPartnerInviteMutation,
  useAddTrustedPartnerInviteDeclinedMutation,
  useDeleteTrustedPartnerInviteItemMutation,
} from "../../redux/slices/indexApiSlice";
import { Delete as DeleteIcon, Cancel } from "@mui/icons-material";
import { Add as AddIcon } from "@mui/icons-material";

import { IVendor } from "../../types/vendor";

import { useTranslation } from "react-i18next";
import VendorForm from "../forms/VendorForm";
import { DialogMode } from "../../types/dialogmode";
import { Check } from "react-feather";
import { IActivity } from "../../types/activity";
import useAuth from "../../hooks/useAuth";
import {
  ITrustedPartnerInvite,
  ITrustedPartnerInviteResponse,
} from "../../types/trustedpartner";

import { getTenantId } from "../../redux/slices/authSlice";
import { store } from "../../redux/store";
import TrustedPartnerInviteForm from "../forms/TrustedPartnerInviteForm";

const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);
const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

type ITrustedPartnerDialogProps = {
  mode: DialogMode;
  iconOnly?: boolean;
  inlineConfirmation?: boolean;
  invite?: ITrustedPartnerInvite;
  responses?: ITrustedPartnerInviteResponse[];
  refetch: () => void;
};

function TrustedPartnerInviteDialog(props: ITrustedPartnerDialogProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState(props.mode || DialogMode.Add);
  const [success, setSuccess] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const { invite, responses } = props;

  const [
    addTrustedPartnerInvite,
    {
      isSuccess: isInvited,
      reset: resetInvited,
      isError: inviteError,
      error: inviteErrorObject,
    },
  ] = useAddTrustedPartnerInviteMutation();

  const [
    addTrustedPartnerInviteAccepted,
    {
      isSuccess: isAccepted,
      reset: resetAccepted,
      isError: acceptError,
      error: acceptErrorObject,
    },
  ] = useAddTrustedPartnerInviteAcceptedMutation();

  const [
    addTrustedPartnerInviteRejected,
    {
      isSuccess: isRejected,
      reset: resetRejected,
      isError: rejectError,
      error: rejectErrorObject,
    },
  ] = useAddTrustedPartnerInviteDeclinedMutation();

  const [
    deleteTrustedPartnerInviteItem,
    {
      isSuccess: isDeleted,
      reset: resetDelete,
      isError: deleteError,
      error: deleteErrorObject,
    },
  ] = useDeleteTrustedPartnerInviteItemMutation();

  const { user } = useAuth();

  const openDialog = () => {
    setOpen(true);
  };

  const resetAndClose = () => {
    setOpen(false);

    // wait for the dialog to close
    setTimeout(() => {
      setSuccess(false);
    }, 500);
  };

  useEffect(() => {
    if (isDeleted) {
      resetDelete();
    }
  }, [isDeleted]);

  useEffect(() => {
    if (isInvited || isAccepted || isRejected || isDeleted) {
      setSuccess(true);

      if (props.refetch) {
        // re-get the message items if the add or delete mutation was successful
        props.refetch();
      }

      if (isInvited) {
        resetInvited();
      }

      if (isAccepted) {
        resetAccepted();
      }

      if (isRejected) {
        resetRejected();
      }

      if (isDeleted) {
        resetDelete();
      }
    }
  }, [isInvited, isAccepted, isRejected, isDeleted]);

  const handleAddTrustedPartner = async (t: ITrustedPartnerInvite) => {
    await addTrustedPartnerInvite(t);
  };

  const handleConfirmDelete = async (e: any) => {
    e.stopPropagation();

    const tenantId = store.getState().auth.tenantId; // Retrieve the tenantId

    if (invite?.tenantId === tenantId) {
      // the current user is the client, so delete the invite
      if (invite?.id) {
        await deleteTrustedPartnerInviteItem(invite.id);
      }
    } else if (invite?.partnerId === tenantId) {
      // the current user is the partner, so delete the invite response
      const promises = responses?.map(async (response) => {
        if (response.id) {
          deleteTrustedPartnerInviteItem(response.id);
        }
      });

      if (promises) {
        await Promise.all(promises);
      }
    } else {
      // the current user is neither the client nor the partner, so do nothing
    }

    // Hide the confirmation
    setShowConfirmation(false);
  };

  const handleCancelDelete = (e: any) => {
    e.stopPropagation();

    // Hide the confirmation
    setShowConfirmation(false);
  };

  const handleEditClick = (e: any) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleDeleteClick = (e: any) => {
    e.stopPropagation();

    if (props.inlineConfirmation) {
      setShowConfirmation(true);
    } else {
      setOpen(true);
    }
  };

  const acceptInvite = async () => {
    if (invite) {
      await addTrustedPartnerInviteAccepted(invite);

      setSuccess(true);

      // close the dialog
      resetAndClose();
    }
  };

  const declineInvite = async () => {
    if (invite) {
      await addTrustedPartnerInviteRejected(invite);

      setSuccess(true);

      // close the dialog
      resetAndClose();
    }
  };

  const handleAcceptClick = (e: any) => {
    e.stopPropagation();

    acceptInvite();
  };

  const handleDeclineClick = (e: any) => {
    e.stopPropagation();

    declineInvite();
  };

  return (
    <>
      {mode === DialogMode.Delete && (
        <>
          {props.inlineConfirmation && showConfirmation && (
            <Fade in={showConfirmation}>
              <Box>
                <Typography component="span">Are you sure?</Typography>
                <IconButton
                  aria-label="Cancel"
                  size="small"
                  onClick={handleCancelDelete}
                >
                  <Cancel />
                </IconButton>
                <IconButton
                  aria-label="Confirm"
                  size="small"
                  onClick={handleConfirmDelete}
                >
                  <Check />
                </IconButton>
              </Box>
            </Fade>
          )}

          <Tooltip title="Delete">
            {props.iconOnly ? (
              <IconButton onClick={handleDeleteClick}>
                <DeleteIcon />
              </IconButton>
            ) : (
              <Button
                color="primary"
                variant="contained"
                onClick={() => setOpen(true)}
              >
                {t("Revoke")}
              </Button>
            )}
          </Tooltip>
        </>
      )}
      {mode === DialogMode.Add && (
        <Button variant="contained" color="primary" onClick={openDialog}>
          <AddIcon />
          {t("New trusted partner")}
        </Button>
      )}
      {mode === DialogMode.Edit && (
        <>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAcceptClick}
          >
            {t("Accept")}
          </Button>

          <Button color="primary" onClick={handleDeclineClick}>
            {t("Decline")}
          </Button>
        </>
      )}
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          {mode === DialogMode.Add
            ? t("New trusted partner")
            : mode === DialogMode.Delete
            ? "Are you sure you want to revoke this trust?"
            : ""}
        </DialogTitle>

        <DialogContent>
          {inviteError && (
            <Alert severity="error" my={3}>
              {JSON.stringify(inviteErrorObject)}
            </Alert>
          )}

          {acceptError && (
            <Alert severity="error" my={3}>
              {JSON.stringify(acceptErrorObject)}
            </Alert>
          )}

          {rejectError && (
            <Alert severity="error" my={3}>
              {JSON.stringify(rejectErrorObject)}
            </Alert>
          )}

          {deleteError && (
            <Alert severity="error" my={3}>
              {JSON.stringify(deleteErrorObject)}
            </Alert>
          )}
          <TrustedPartnerInviteForm invite={props.invite} mode={mode} />
        </DialogContent>

        <DialogActions>
          {success ? (
            <Button onClick={() => resetAndClose()} color="primary">
              {t("Close")}
            </Button>
          ) : (
            <>
              {!success && (
                <Button
                  onClick={() => resetAndClose()}
                  color="primary"
                  autoFocus
                >
                  {t("Cancel")}
                </Button>
              )}

              {(mode === DialogMode.Edit || mode === DialogMode.Add) && (
                <Button
                  type="submit"
                  form="trusted-partner-form"
                  color="primary"
                >
                  {t("Save")}
                </Button>
              )}

              {mode === DialogMode.Delete && (
                <Button
                  variant="contained"
                  onClick={handleConfirmDelete}
                  color="primary"
                >
                  {t("Revoke")}
                </Button>
              )}
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

export default TrustedPartnerInviteDialog;
