import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Alert as MuiAlert,
  AvatarGroup,
  Box,
  Button as MuiButton,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  Grid,
  IconButton,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  TextField as MuiTextField,
  Typography,
  ImageList,
  ImageListItem,
  Tab,
  Tabs,
  ListItemButton,
  Tooltip,
} from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { spacing } from "@mui/system";
import {
  useAddVendorMutation,
  useDeleteVendorMutation,
  useUpdateVendorMutation,
} from "../../redux/slices/vendorsApiSlice";
import {
  Delete as DeleteIcon,
  Description,
  LegendToggle,
  Visibility,
  Edit as EditIcon,
  PropaneSharp,
  Cancel,
} from "@mui/icons-material";
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";

import { IVendor } from "../../types/vendor";

import { useTranslation } from "react-i18next";
import VendorForm from "../forms/VendorForm";
import { DialogMode } from "../../types/dialogmode";
import { Check } from "react-feather";
import { IActivity } from "../../types/activity";
import useAuth from "../../hooks/useAuth";
import LoadingButton from "@mui/lab/LoadingButton";

const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);
const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

type IVendorDialogProps = {
  mode: DialogMode;
  iconOnly?: boolean;
  inlineConfirmation?: boolean;
  vendor?: IVendor;
  onCancel?: () => void;
  open?: boolean;
  onSubmit?: (vendor: IVendor) => void;
};

function VendorDialog(props: IVendorDialogProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState(props.mode || DialogMode.Add);
  const [success, setSuccess] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [addVendor, { isSuccess: isAdded, reset: resetAdd }] =
    useAddVendorMutation();
  const [
    updateVendor,
    { isSuccess: isUpdated, reset: resetUpdate, data: updatedVendor },
  ] = useUpdateVendorMutation();
  const [
    deleteVendor,
    { isLoading: isDeleting, isSuccess: isDeleted, reset: resetDelete },
  ] = useDeleteVendorMutation();
  const { user } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (props.open !== undefined) {
      // open/close the dialog if it's controlled from outside
      setOpen(props.open);
    }
  }, [props.open]);

  const resetAndClose = () => {
    setOpen(false);

    if (props.onCancel) {
      props.onCancel();
    }

    // wait for the dialog to close
    setTimeout(() => {
      setSuccess(false);
    }, 500);
  };

  useEffect(() => {
    if (isDeleted) {
      // redirect to the vendors page
      navigate("/vendors", {
        state: { reload: true },
      });

      resetDelete();
    }
  }, [isDeleted]);

  useEffect(() => {
    if (isAdded || isUpdated || isDeleted) {
      setSuccess(true);

      if (isAdded) {
        resetAdd();
      }

      if (isUpdated && updatedVendor) {
        resetUpdate();
      }

      if (isDeleted) {
        resetDelete();
      }
    }
  }, [isAdded, isUpdated, isDeleted, updatedVendor]);

  const handleAddVendor = async (vendor: IVendor) => {
    if (props.onSubmit) {
      // send the data to the parent component
      props.onSubmit(vendor);
    } else {
      await addVendor(vendor);
    }

    setSuccess(true);

    // close the dialog
    resetAndClose();
  };

  const handleUpdateVendor = async (vendor: IVendor) => {
    await updateVendor(vendor);

    setSuccess(true);

    // close the dialog
    resetAndClose();
  };

  const handleConfirmDelete = async (e: any) => {
    e.stopPropagation();

    if (props.vendor) {
      // Call the delete function to delete the item
      await deleteVendor(props.vendor.id);
    }

    // Hide the confirmation
    setShowConfirmation(false);
  };

  const handleCancelDelete = (e: any) => {
    e.stopPropagation();

    // Hide the confirmation
    setShowConfirmation(false);
  };

  const handleEditClick = (e: any) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleDeleteClick = (e: any) => {
    e.stopPropagation();

    if (props.inlineConfirmation) {
      setShowConfirmation(true);
    } else {
      setOpen(true);
    }
  };

  return (
    <>
      {props.open === undefined && (
        <>
          {mode === DialogMode.Delete && (
            <>
              {!showConfirmation && (
                <Tooltip title={t("Delete vendor")}>
                  {props.iconOnly ? (
                    <IconButton size="small" onClick={handleDeleteClick}>
                      <DeleteIcon />
                    </IconButton>
                  ) : (
                    <Button color="primary" onClick={() => setOpen(true)}>
                      {t("Delete vendor")}
                    </Button>
                  )}
                </Tooltip>
              )}

              {props.inlineConfirmation && showConfirmation && (
                <Fade in={showConfirmation}>
                  <Box>
                    <Typography component="span">
                      {t("Are you sure?")}
                    </Typography>
                    <IconButton
                      aria-label="Cancel"
                      size="small"
                      onClick={handleCancelDelete}
                    >
                      <Cancel />
                    </IconButton>
                    <IconButton
                      aria-label="Confirm"
                      size="small"
                      onClick={handleConfirmDelete}
                    >
                      <Check />
                    </IconButton>
                  </Box>
                </Fade>
              )}
            </>
          )}

          {mode === DialogMode.Add && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpen(true)}
            >
              <AddIcon />
              {t("New vendor")}
            </Button>
          )}

          {mode === DialogMode.Edit && (
            <Tooltip title={t("Edit vendor")}>
              {props.iconOnly ? (
                <IconButton size="small" onClick={handleEditClick}>
                  <EditIcon />
                </IconButton>
              ) : (
                <Button color="primary" onClick={handleEditClick}>
                  {t("Quick edit")}
                </Button>
              )}
            </Tooltip>
          )}
        </>
      )}

      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          {mode === DialogMode.Add
            ? t("New vendor")
            : mode === DialogMode.Edit
            ? `${t("Edit")} ${props.vendor?.name}`
            : mode === DialogMode.Delete
            ? t("Are you sure you want to delete this vendor?")
            : ""}
        </DialogTitle>
        {mode !== DialogMode.Delete && (
          <DialogContent>
            {/* <DialogContentText>{mode === DialogMode.Add ? "Add a new vendor." : "Edit the vendor."}</DialogContentText> */}

            <VendorForm
              mode={mode}
              vendor={props.vendor}
              addVendor={handleAddVendor}
              updateVendor={handleUpdateVendor}
            />
          </DialogContent>
        )}
        <DialogActions>
          {success ? (
            <Button
              variant="contained"
              onClick={() => resetAndClose()}
              color="primary"
            >
              {t("Close")}
            </Button>
          ) : (
            <>
              {!success && (
                <Button
                  onClick={() => resetAndClose()}
                  color="primary"
                  autoFocus
                >
                  {t("Cancel")}
                </Button>
              )}

              {(mode === DialogMode.Edit || mode === DialogMode.Add) && (
                <Button
                  variant="contained"
                  type="submit"
                  form="vendor-form"
                  color="primary"
                >
                  {t("Save")}
                </Button>
              )}

              {mode === DialogMode.Delete && (
                <LoadingButton
                  variant="contained"
                  loading={isDeleting}
                  onClick={handleConfirmDelete}
                  color="primary"
                >
                  {t("Delete")}
                </LoadingButton>
              )}
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

export default VendorDialog;
