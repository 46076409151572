import { t } from "i18next";
import { INotification } from "../types/notification";
import { isToday, isTomorrow, subDays, subMonths, subWeeks } from "date-fns";
import { IDatePeriod } from "../types/datePeriod";
import { IContract } from "../types/contract";

const uppercaseFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const getReminderDateForContract = (contract: IContract): Date | null => {
  // get the general notification period for the settings

  if (contract.endDate && contract.noticePeriod) {
    const reminderDate = subPeriodFromDate(
      contract.endDate,
      contract.noticePeriod
    );

    return reminderDate;
  }

  return null;
};

const convertNotificationDate = (notification: INotification) => {
  return `${notification.contractName} ${
    isToday(new Date(notification?.endDate)) ||
    isTomorrow(new Date(notification.endDate))
      ? t("expires")
      : t("expires_on")
  } ${t("date.dateOnly", { date: new Date(notification.endDate) })}. ${
    notification.noticePeriod?.value && notification.noticePeriod?.unit
      ? t("notification_period_date_text", {
          value: notification.noticePeriod?.value ?? 0,
          unit:
            notification.noticePeriod?.value > 1
              ? t(notification.noticePeriod?.unit)
              : t(removeSAtEnd(notification.noticePeriod?.unit)),
          beforeDate: t("date.dateOnly", {
            date: subPeriodFromDate(
              notification.endDate,
              notification.noticePeriod
            ),
          }),
        })
      : t("notification_no_period_date_text", {
          endDate: t("date.dateOnly", {
            date: subPeriodFromDate(
              notification.endDate,
              notification.noticePeriod
            ),
          }),
        })
  }`;
};

const convertCreatedDate = (insertionTime: string | number | Date) => {
  return uppercaseFirstLetter(t("date.ago", { date: new Date(insertionTime) }));
};

const subPeriodFromDate = (
  date: string | number | Date,
  noticePeriod: IDatePeriod
) => {
  if (noticePeriod?.value) {
    switch (noticePeriod?.unit) {
      case "days":
        return subDays(new Date(date), noticePeriod?.value);
      case "weeks":
        return subWeeks(new Date(date), noticePeriod?.value);
      case "months":
        return subMonths(new Date(date), noticePeriod?.value);
      default:
        return new Date(date);
    }
  }
  return new Date(date);
};

const removeSAtEnd = (inputString: string): string => {
  if (inputString.endsWith("s")) {
    return inputString.slice(0, -1);
  }
  return inputString;
};

const formatBytes = (bytes: number, decimals = 2): string => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  // for Bytes and KB, do not show decimals
  if (i < 2) {
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(0))} ${sizes[i]}`;
  }

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

const isValidDate = (s: string | Date) => {
  try {
    if (!s) return false;
    const d = new Date(s);

    return d.toString() !== "Invalid Date";
  } catch (error) {
    return false;
  }
};

export {
  uppercaseFirstLetter,
  convertNotificationDate,
  convertCreatedDate,
  subPeriodFromDate,
  formatBytes,
  isValidDate,
  getReminderDateForContract,
};
