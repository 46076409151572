import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Alert as MuiAlert,
  AvatarGroup,
  Box,
  Button as MuiButton,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  Grid,
  IconButton,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  TextField as MuiTextField,
  Typography,
  Tooltip,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useUpdateContractMutation } from "../../redux/slices/indexApiSlice";
import {
  AutoFixHigh,
  Delete,
  Description,
  LegendToggle,
  Visibility,
  Edit as EditIcon,
  Cancel,
  Search,
  PersonAdd,
} from "@mui/icons-material";

import { IContract } from "../../types/contract";
import { DialogMode } from "../../types/dialogmode";
import DataGridUsers from "../datagrids/DataGridUsers";
import { set } from "date-fns";
import { useTranslation } from "react-i18next";

const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);

type ISearchUserDialogProps = {
  mode: DialogMode;
  iconOnly?: boolean;
  inlineConfirmation?: boolean;
  selectedUserIds: string[];
  setSelectedUserIds: (selected: string[]) => void;
};

function SearchUserDialog({
  selectedUserIds,
  setSelectedUserIds,
}: ISearchUserDialogProps) {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const [selected, setSelected] = useState<string[]>(selectedUserIds);

  useEffect(() => {
    if (selectedUserIds) {
      setSelected(selectedUserIds);
    }
  }, [selectedUserIds]);

  const resetAndClose = () => {
    // set back to original
    // setSelectedUserIds(selectedUserIds);

    setOpen(false);
  };

  const handleSaveClick = async () => {
    console.log("save user id's", selectedUserIds);

    setSelectedUserIds(selected);
    setOpen(false);
    // await updateContract(updatedContract);
  };

  return (
    <>
      {/* <Button variant="text" onClick={() => setOpen(true)}>
        <Search sx={{ mr: 2 }} />
      </Button> */}

      <Button variant={"text"} onClick={() => setOpen(true)}>
        <PersonAdd sx={{ mr: 2 }} /> {t("Add user")}
      </Button>

      <Dialog
        open={open}
        maxWidth={"md"}
        fullWidth
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{t("Users")}</DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>

          <DataGridUsers selected={selected} onSelect={setSelected} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => resetAndClose()} color="primary">
            {t("Cancel")}
          </Button>
          <Button
            variant="contained"
            onClick={() => handleSaveClick()}
            color="primary"
          >
            {t("Save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default SearchUserDialog;
