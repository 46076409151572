import React, { useEffect } from "react";

import { Box, Alert, AlertTitle, LinearProgress } from "@mui/material";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import useContract from "../hooks/useContract";
import { AIReadingStatus } from "../types/contract";

function AnalyzingContractsLoader() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  const [totalProgress, setTotalProgress] = React.useState(0);

  const { analyzedContracts, isAnalyzing, analyzingNumber, analyzingSuccess } =
    useContract();

  useEffect(() => {
    if (
      analyzedContracts?.length > 0 ||
      (analyzingNumber && analyzingNumber > 0)
    ) {
      const analyzingContracts = analyzedContracts.filter(
        (c) => c.analyzeStatus === AIReadingStatus.ANALYZING
      );

      const totalProgress = analyzingContracts.reduce(
        (acc, contract) => acc + contract.progress,
        0
      );

      setTotalProgress(totalProgress / analyzingContracts.length);
    }
  }, [analyzedContracts, isAnalyzing, analyzingNumber, analyzingSuccess]);

  return (
    <>
      {analyzedContracts?.length > 0 && (
        <Box mt={6} mb={4}>
          {isAnalyzing ? (
            <>
              <Alert severity="info">
                <AlertTitle>{t("Reading")}</AlertTitle>
                {t("Reading_contracts", {
                  result: analyzingNumber,
                })}
              </Alert>

              <LinearProgress variant="determinate" value={totalProgress} />
              {/* <CircularProgressWithLabel value={totalProgress} /> */}
            </>
          ) : analyzingSuccess ? (
            <Alert severity="success">
              <AlertTitle>{t("Success")}</AlertTitle>
              {t("Contracts have been read")}
            </Alert>
          ) : (
            <span />
          )}

          {/* {analyzeError && (
          <Alert severity="error">
            <AlertTitle>{t("An error occurred!")}</AlertTitle>
            {analyzeError?.data && JSON.stringify(analyzeError.data)}
            {!analyzeError?.data && JSON.stringify(analyzeError)}
          </Alert>
        )} */}
        </Box>
      )}
    </>
  );
}

export default AnalyzingContractsLoader;
