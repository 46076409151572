import React, { useEffect, useState } from "react";

import { Field, Form, Formik, FormikProps, useFormikContext } from "formik";
import * as Yup from "yup";
import styled from "@emotion/styled";

import {
  Alert as MuiAlert,
  AvatarGroup,
  createFilterOptions,
  Box,
  Button as MuiButton,
  Grid,
  IconButton,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";

import { DialogMode } from "../../types/dialogmode";
import useAuth from "../../hooks/useAuth";
import { IPrompt } from "../../types/prompt";
import { IGraphUser } from "../../types/user";
import { useTranslation } from "react-i18next";

const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);
const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const validationSchemaPrompt = Yup.object().shape({
  body: Yup.string().required("Required"),
});

interface IPromptFormProps {
  mode: DialogMode;
  addPrompt: (Prompt: IPrompt) => Promise<void>;
  updatePrompt: (Prompt: IPrompt) => Promise<void>;
  prompt?: IPrompt;
}

function PromptForm({ ...props }: IPromptFormProps) {
  const { t } = useTranslation();
  // create a ref
  // const formikRef = useRef<FormikProps<any>>(null);

  const { user } = useAuth();

  const handleSubmit = async (
    values: any,
    { resetForm, setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      console.log("submitting form");
      if (props.mode === "add") {
        await props.addPrompt(values);

        //   resetForm();
        setStatus({ sent: true });
        setSubmitting(false);
      } else if (props.mode === "edit") {
        await props.updatePrompt(values);

        // resetForm();
        setStatus({ sent: true });
        setSubmitting(false);
      } else {
        setStatus({ sent: false });
        setErrors({ submit: "Mode undefined" });
        setSubmitting(false);
      }
    } catch (error: any) {
      setStatus({ sent: false });
      setErrors({ submit: error.Prompt });
      setSubmitting(false);
    }
  };

  // useEffect(() => {
  //   if (props.submit) {
  //     // if (formikRef.current) {
  //     //   // submit the form
  //     //   formikRef?.current?.handleSubmit();
  //     // }
  //   }
  // }, [props.submit]);

  // const initialValues = {
  //   name: props.contract?.name,
  //   startDate: props.contract?.startDate,
  //   endDate: props.contract?.endDate,
  //   value: props.contract?.value,
  // };

  const blankPrompt: IPrompt = {
    id: "",
    body: "",
    author: user?.id,
    key: "",
  };

  const initialValues: IPrompt = props.prompt ? props.prompt : blankPrompt;

  const fieldVariant = "standard"; // "outlined";
  const errorFieldVariant = "filled";

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchemaPrompt}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
        isValid,
      }) => (
        <Grid container mb={6}>
          {errors && !isValid && !status?.sent && (
            <Grid item>
              <Alert severity="error" my={3}>
                {t("An error occurred!")}
                {errors && Object.keys(errors).length > 0 && (
                  <pre style={{ whiteSpace: "pre-wrap" }}>
                    {JSON.stringify(errors, null, 2)}
                  </pre>
                )}
              </Alert>
            </Grid>
          )}

          <Grid item width="100%">
            <Form id="prompt-form" onSubmit={handleSubmit}>
              <Grid container gap={3} direction="column">
                <Grid item>
                  <TextField
                    name="key"
                    label={t("Unique key")}
                    value={values.key}
                    error={Boolean(touched.key && errors.key)}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant={
                      touched.key && errors.key
                        ? errorFieldVariant
                        : fieldVariant
                    }
                    my={2}
                    multiline={false}
                    disabled
                  />
                </Grid>

                <Grid item>
                  <TextField
                    name="body"
                    label={t("Prompt")}
                    value={values.body}
                    error={Boolean(touched.body && errors.body)}
                    fullWidth
                    // helperText={touched.name && errors.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onPaste={(e) => {
                      e.preventDefault();
                      let text: string = e.clipboardData.getData("text/plain");

                      // trim start and end
                      text = text.trim();
                      text = text.replace(/^\s+|\s+$/g, "");

                      setFieldValue("body", text);
                    }}
                    variant={
                      touched.body && errors.body
                        ? errorFieldVariant
                        : fieldVariant
                    }
                    my={2}
                    multiline={true}
                    minRows={3}
                    maxRows={5}
                  />
                </Grid>

                <Grid item>
                  <TextField
                    name="category"
                    label={t("Category")}
                    value={values.category}
                    error={Boolean(touched.category && errors.category)}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onPaste={(e) => {
                      e.preventDefault();
                      let text: string = e.clipboardData.getData("text/plain");

                      // trim start and end
                      text = text.trim();
                      text = text.replace(/^\s+|\s+$/g, "");

                      setFieldValue("category", text);
                    }}
                    variant={
                      touched.category && errors.category
                        ? errorFieldVariant
                        : fieldVariant
                    }
                    my={2}
                    multiline={false}
                  />
                </Grid>

                <Grid item>
                  <TextField
                    name="comment"
                    label={t("Comment")}
                    value={values.comment}
                    error={Boolean(touched.comment && errors.comment)}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onPaste={(e) => {
                      e.preventDefault();
                      let text: string = e.clipboardData.getData("text/plain");

                      // trim start and end
                      text = text.trim();
                      text = text.replace(/^\s+|\s+$/g, "");

                      setFieldValue("comment", text);
                    }}
                    variant={
                      touched.comment && errors.comment
                        ? errorFieldVariant
                        : fieldVariant
                    }
                    my={2}
                    multiline={true}
                    minRows={3}
                    maxRows={5}
                  />
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
}

export default PromptForm;
