import React, { useState, useEffect, ReactNode } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Avatar,
  AvatarGroup as MuiAvatarGroup,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography as MuiTypography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  FormControl as MuiFormControl,
  Paper as MuiPaper,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  IconButton,
  CardMedia as MuiCardMedia,
  CardActionArea,
  CardActions,
  Chip as MuiChip,
  Tooltip,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import {
  Send as SendIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { spacing, SpacingProps } from "@mui/system";
import {
  orange,
  green,
  blue,
  pink,
  deepOrange,
  deepPurple,
} from "@mui/material/colors";
import { Add as AddIcon, Delete, Spa } from "@mui/icons-material";
import {
  useGetDriveItemThumbnailsQuery,
  useGetDriveItemsQuery,
  useGetDriveQuery,
  useGetDrivesQuery,
  useGetFollowedSitesQuery,
  useGetSiteQuery,
  useSendMessageToQueueMutation,
} from "../../../redux/slices/graphApiSlice";
import {
  useAddContractMutation,
  useAddDocumentMutation,
  useAddSharePointConnectionMutation,
  useDeleteSharePointConnectionMutation,
  useGetSharePointConnectionsQuery,
} from "../../../redux/slices/indexApiSlice";
import useAuth from "../../../hooks/useAuth";
import { IDocument } from "../../../types/document";
import { useTranslation } from "react-i18next";
import SharePointConnectionDialog from "../../../components/dialogs/SharePointConnectionDialog";
import { DialogMode } from "../../../types/dialogmode";
import { ISharePointConnection } from "../../../types/sharepointconnection";
import StyledMenu from "../../../theme/StyledMenu";
import MenuItemAsLabel from "../../../theme/MenuItemAsLabel";

const Paper = styled(MuiPaper)(spacing);

const FormControlSpacing = styled(MuiFormControl)(spacing);

const FormControl = styled(FormControlSpacing)<{ m?: number }>`
  min-width: 148px;
`;

const PinkAvatar = styled(Avatar)`
  background-color: ${pink[500]};
`;

const GreenAvatar = styled(Avatar)`
  background-color: ${green[500]};
`;

const OrangeAvatar = styled(Avatar)`
  background-color: ${deepOrange[500]};
`;

const PurpleAvatar = styled(Avatar)`
  background-color: ${deepPurple[500]};
`;

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)`
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
`;

const Chip = styled(MuiChip)<{ color?: string }>`
  height: 20px;
  padding: 4px 0;
  font-size: 85%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
  margin-bottom: ${(props) => props.theme.spacing(4)};
`;

const AvatarGroup = styled(MuiAvatarGroup)`
  margin-left: ${(props) => props.theme.spacing(2)};
`;

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

interface TypographyProps extends SpacingProps {
  component?: string;
}
const Typography = styled(MuiTypography)<TypographyProps>(spacing);

const Spacer = styled.div(spacing);

interface SharePointConnectionProps {
  connection: ISharePointConnection;
}

const CardMedia = styled(MuiCardMedia)`
  // height: auto; // 220px
`;

function DriveItemCard({ ...props }) {
  const { t } = useTranslation();
  const driveItem = props.driveItem;

  console.log("driveItem", driveItem);

  const { user } = useAuth();
  const navigate = useNavigate();

  const { data: thumbnails, isLoading: loadingThumbnails } =
    useGetDriveItemThumbnailsQuery({
      driveId: driveItem?.parentReference.driveId,
      driveItemId: driveItem.id,
    });

  const [
    addContract,
    { isLoading: importing, isSuccess: contractCreated, data: contract },
  ] = useAddContractMutation();
  const [addDocument, { isLoading: addingDocument, isSuccess: documentAdded }] =
    useAddDocumentMutation();

  useEffect(() => {
    if (contractCreated && contract) {
      // add a pointer for the document
      // the document itself will remain in SharePoint, but we need to store the metadata in the database
      const document: IDocument = {
        contractId: contract.id,
        name: driveItem.name,
        type: "contract",
        origin: "sharepoint",
        driveId: driveItem.parentReference.driveId,
        driveItemId: driveItem.id,
      };

      addDocument(document);
    }
  }, [contractCreated, contract]);

  useEffect(() => {
    if (contractCreated && documentAdded && contract?.id) {
      navigate(`/contracts/edit/${contract.id}`);
    }
  }, [contractCreated, documentAdded]);

  const handleImportClick = async () => {
    const metadata: any = {
      name: driveItem.name,
      managerIds: [user?.id],
      ownerIds: [user?.id],
    };

    addContract(metadata);
  };

  // const handleImportClick = async () => {
  //   console.log(`adding download url ${driveItem["@microsoft.graph.downloadUrl"]} to the queue`);

  //   const message = {
  //     id: driveItem.id,
  //     name: driveItem.name,
  //     fileType: driveItem.file?.mimeType,
  //     serverRelativeUrl: driveItem.webUrl,
  //     libraryRelativeUrl: "",
  //     downloadUrl: driveItem["@microsoft.graph.downloadUrl"],
  //   };

  //   addMessage(message);
  // };

  return (
    <Card mb={6}>
      <CardActionArea>
        {!loadingThumbnails &&
          thumbnails?.value &&
          thumbnails?.value.length > 0 && (
            <CardMedia
              sx={{
                height: thumbnails.value[0].medium?.height,
                width: thumbnails.value[0].medium?.width,
              }}
              image={thumbnails.value[0].medium?.url}
              title={driveItem.name}
            />
          )}
        <CardContent>
          <Tooltip title={driveItem.name} arrow placement="top">
            <Typography
              variant="subtitle2"
              gutterBottom
              sx={{
                display: "-webkit-box",
                "-webkit-box-orient": "vertical",
                WebkitLineClamp: 2,
                overflow: "hidden",
                lineHeight: "1.2em",
                height: "2.4em",
              }}
            >
              {driveItem.name}
            </Typography>
          </Tooltip>
        </CardContent>
      </CardActionArea>

      <CardActions>
        <Button
          onClick={() => handleImportClick()}
          variant="contained"
          disabled={importing || addingDocument}
        >
          {t("Import")}
        </Button>
      </CardActions>
    </Card>
  );
}

function SharePointFilesDialog({ ...props }) {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid container alignItems={"center"} spacing={2} gap={3} p={3}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => setOpen(true)}
        >
          {t("View files")}
        </Button>
      </Grid>

      <Dialog
        open={open}
        maxWidth="xl"
        fullWidth
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{t("View files")}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent>
          <DialogContentText>Files in this drive</DialogContentText>

          <Grid container spacing={3} direction="row">
            {props.driveItems?.map((item: any) => (
              <Grid item key={`drive-item-${item.id}`} width={250} height={350}>
                <DriveItemCard driveItem={item} />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleSaveClick()} color="primary">
            Save
          </Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
}

const SharePointConnectionCard = ({ ...props }: SharePointConnectionProps) => {
  const { t } = useTranslation();

  const connection = props.connection;

  const [initials, setInitials] = useState("");
  const { data: site } = useGetSiteQuery(connection.siteId);
  const { data: drive } = useGetDriveQuery(connection.driveId);
  const { data: driveItems, isLoading } = useGetDriveItemsQuery(
    connection.driveId ?? {
      skiptoken: !connection.driveId,
    }
  );
  const [
    deleteConnection,
    { isLoading: isDeleting, isSuccess: isDeleted, isError: errorOccurred },
  ] = useDeleteSharePointConnectionMutation();

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleConfirmDelete = async (e: any) => {
    e.stopPropagation();

    // Call the delete function to delete the item
    if (props.connection?.id) {
      deleteConnection(props.connection.id);
    }

    // Hide the confirmation
    setShowConfirmation(false);
  };

  const handleCancelDelete = (e: any) => {
    e.stopPropagation();
    // Hide the confirmation
    setShowConfirmation(false);

    // Close the menu
    handleClose();
  };

  useEffect(() => {
    if (connection.siteId && connection.driveId) {
      console.log("driveItems", driveItems);
    }
  }, [connection.siteId, connection.driveId, driveItems]);

  useEffect(() => {
    if (site?.displayName) {
      // get the first letter of each word in the title
      const initials = site?.displayName
        ?.split(" ")
        .map((word: string) => word.charAt(0))
        .join("");

      // take only the first 2 letters
      const initialsShort = initials?.substring(0, 2);

      setInitials(initialsShort);
    }
  }, [site]);

  const chip = <Chip label="Finished" color="success" />;

  return (
    <Card
      style={{
        filter:
          (isDeleting || isDeleted) && !errorOccurred ? "blur(3px)" : "none",
        opacity: (isDeleting || isDeleted) && !errorOccurred ? "0.5" : "1",
        transition: "filter 0.8s ease-in-out, opacity 0.8s ease-in-out",
      }}
    >
      {/* {image ? <CardMedia image={image} title="Contemplative Reptile" /> : null} */}
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {site?.displayName}
        </Typography>

        <Typography mb={4} color="textSecondary" component="p">
          {drive?.name}
        </Typography>

        <Typography mb={4} color="textSecondary" component="p">
          {site?.webUrl}
        </Typography>
      </CardContent>
      <CardActions>
        <SharePointFilesDialog driveItems={driveItems} />

        <IconButton
          aria-label="more"
          id={`connection-menubutton-${connection?.id}`}
          aria-controls={open ? `connection-menu-${connection?.id}` : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          sx={{ ":hover": { visibility: "visible" } }}
        >
          <MoreVertIcon />
        </IconButton>
        <StyledMenu
          id={`message-menu-${connection?.id}`}
          MenuListProps={{
            "aria-labelledby": `connection-menubutton-${connection?.id}`,
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem disableRipple>
            <EditIcon />
            Edit
          </MenuItem>

          <Divider sx={{ my: 0.5 }} />

          <MenuItem onClick={() => setShowConfirmation(true)} disableRipple>
            <DeleteIcon />
            Delete
          </MenuItem>

          {showConfirmation && (
            <>
              <MenuItemAsLabel sx={{ justifyContent: "center" }}>
                Are you sure?
              </MenuItemAsLabel>

              {/* <Fade in={showConfirmation}> */}
              <MenuItemAsLabel>
                <Button
                  color="primary"
                  size="small"
                  onClick={handleConfirmDelete}
                >
                  Yes, delete!
                </Button>
                <Button
                  color="primary"
                  size="small"
                  onClick={handleCancelDelete}
                >
                  Cancel
                </Button>
              </MenuItemAsLabel>
              {/* </Fade> */}
            </>
          )}
        </StyledMenu>
      </CardActions>
    </Card>
  );
};

function SharePointConnections() {
  const { t } = useTranslation();

  const { data: connections } = useGetSharePointConnectionsQuery();

  return (
    <React.Fragment>
      <Helmet title={t("SharePoint Connections")!} />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {t("SharePoint Connections")}
          </Typography>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container direction="column" gap={6}>
        <Grid item>
          <SharePointConnectionDialog mode={DialogMode.Add} />
        </Grid>

        <Grid item>
          <Grid container spacing={6}>
            {connections?.map(
              (connection: ISharePointConnection, index: number) => (
                <Grid key={`connection-${connection.id}`} item xs={12} md={4}>
                  <SharePointConnectionCard connection={connection} />
                </Grid>
              )
            )}
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default SharePointConnections;
