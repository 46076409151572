import { WebPubSubClient } from "@azure/web-pubsub-client";
import React, { useEffect, useState } from "react";
import useAppSelector from "../hooks/useAppSelector";
import useAuth from "../hooks/useAuth";
import {
  useAddAnalysisTaskMutation,
  useStopAnalysisTaskMutation,
} from "../redux/slices/aiSlice";
import { useLazyGetDriveItemContentAsPDFQuery } from "../redux/slices/graphApiSlice";
import {
  useGetContractsQuery,
  useGetWebPubSubQuery,
} from "../redux/slices/indexApiSlice";
import {
  AIReadingStatus,
  AnalyzedContract,
  ContractStatus,
  IContract,
} from "../types/contract";
import { IDocument } from "../types/document";

const initialState: {
  openedContract: AnalyzedContract | null;
  analyzedContracts: AnalyzedContract[];
  setOpenedContract: (c: AnalyzedContract | null) => any;
  requestDocumentAnalysis: (info: IDocument) => any;
  requestedDocumentAnalysis: IDocument | null;
  stopDocumentAnalysis: (info: IDocument) => any;
  setAnalyzedContracts: React.Dispatch<
    React.SetStateAction<AnalyzedContract[]>
  >;
  analyzedContract: IContract | null;
  analyzingNumber: number | null | undefined;
  isAnalyzing?: boolean;
  analyzingSuccess?: boolean;
} = {
  requestedDocumentAnalysis: null,

  openedContract: null,
  analyzedContracts: [],
  setOpenedContract: (c: AnalyzedContract | null) => {},
  setAnalyzedContracts: () => {},
  requestDocumentAnalysis: (info: IDocument) => {},
  stopDocumentAnalysis: (info: IDocument) => {},
  analyzedContract: null,
  analyzingNumber: 0,
  isAnalyzing: false,
  analyzingSuccess: false,
};
const ContractContext = React.createContext(initialState);

type ContractProvider = {
  children: React.ReactNode;
};

function ContractProvider({ children }: ContractProvider) {
  const { user, isAuthenticated } = useAuth();
  const tenantId = useAppSelector((state) => state.auth.tenantId);

  const [analyzeContract, { data, isSuccess, isLoading, isError, error }] =
    useAddAnalysisTaskMutation();

  const [stopAnalysisTask] = useStopAnalysisTaskMutation();

  const { data: contracts } = useGetContractsQuery(ContractStatus.ALL);

  const [getDriveItemContentAsPDF, { data: driveItemContentAsPDF }] =
    useLazyGetDriveItemContentAsPDFQuery();

  const {
    data: dataPubSub,
    refetch: refetchPubSub,
    isSuccess: isSuccessPubSub,
  } = useGetWebPubSubQuery(isAuthenticated, { skip: !isAuthenticated });

  const [requestedDocumentAnalysis, _setRequestedDocumentAnalysis] =
    useState<IDocument | null>(null);

  const [analyzedContracts, setAnalyzedContracts] = useState<
    AnalyzedContract[]
  >([]);
  const [openedContract, _setOpenedContract] =
    useState<AnalyzedContract | null>(initialState.openedContract);

  const [analyzedContract, setAnalyzedContract] = useState<IContract | null>(
    initialState.analyzedContract
  );

  const [analyzingNumber, setAnalyzingNumber] = useState<number | null>();
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [analyzingSuccess, setAnalyzingSuccess] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      refetchPubSub();
    }
  }, [isAuthenticated]);

  async function connect(dataPubSub: string) {
    console.log("connect pub-sub");

    const client = new WebPubSubClient({
      getClientAccessUrl: async () => dataPubSub,
    });

    client.on("group-message", (e: any) => {
      const res = e.message?.data;
      if (res.type == "contractAnalyze") {
        const data: IContract = res.data;
        if (
          res.data?.analyzeStatus === AIReadingStatus.COMPLETED ||
          res.data?.analyzeStatus === AIReadingStatus.ANALYZING
        ) {
          setAnalyzedContract({
            ...data,
            startDate: data?.startDate
              ? new Date(data.startDate).toISOString()
              : undefined,
            endDate: data?.endDate
              ? new Date(data.endDate).toISOString()
              : undefined,
          });
        }
      }
    });
    await client.start();
    let group = `contract-tenant-${tenantId}`;
    await client.joinGroup(group);
  }

  useEffect(() => {
    if (isSuccessPubSub && dataPubSub && tenantId) {
      connect(dataPubSub.token);
    }
  }, [isSuccessPubSub, tenantId]);

  useEffect(() => {
    if (contracts) {
      const acs = contracts.map((c: IContract) => {
        const ac: AnalyzedContract = {
          name: c.name,
          contractId: c.id,
          analyzeStatus: c.analyzeStatus,
          progress: 0,
          analyzeTaskId: c.analyzeTaskId,
          status: c.status,
        };
        return ac;
      });

      setAnalyzedContracts(acs.reverse());
    }
  }, [contracts]);

  useEffect(() => {
    setAnalyzingNumber(getAnalyzingNumber());

    const haveProgressingFile = analyzedContracts.some(
      (c) => c.analyzeStatus === AIReadingStatus.ANALYZING && c.progress < 99
    );

    if (haveProgressingFile) {
      setTimeout(() => {
        setAnalyzedContracts((prev) => {
          const updatedFiles = prev.map((c) => {
            let progress = c.progress;
            if (
              c.analyzeStatus === AIReadingStatus.ANALYZING &&
              progress < 99
            ) {
              progress += 3;
            }
            return { ...c, progress };
          });

          return updatedFiles;
        });
      }, Math.random() * (5000 - 500) + 500);
    }
  }, [analyzedContracts]);

  useEffect(() => {
    if (analyzedContract) {
      setAnalyzedContracts((prev) => {
        return prev.map((c) => {
          const contract = { ...c };
          if (
            contract.contractId === analyzedContract.id &&
            analyzedContract.analyzeStatus === AIReadingStatus.COMPLETED
          ) {
            return {
              ...contract,
              progress: 100,
              analyzeStatus: AIReadingStatus.COMPLETED,
            };
          } else {
            return contract;
          }
        });
      });
    }
  }, [analyzedContract]);

  useEffect(() => {
    if (!isAnalyzing && analyzingNumber! > 0) {
      setIsAnalyzing(true);
    }

    if (isAnalyzing && analyzingNumber === 0) {
      setIsAnalyzing(false);
      setAnalyzingSuccess(true);
    }
  }, [analyzingNumber, isAnalyzing]);

  const getAnalyzingNumber = (): number => {
    return analyzedContracts?.filter(
      (c: AnalyzedContract) =>
        c.analyzeStatus && c.analyzeStatus == AIReadingStatus.ANALYZING
    ).length;
  };

  const updateAnalyzedContracts = (d: IDocument, analyzeTaskId?: string) => {
    setAnalyzedContracts((prev) => {
      const existContractId = prev.some((c) => {
        return c.contractId === d.contractId;
      });
      if (!existContractId) {
        return [
          {
            status: ContractStatus.DRAFT,
            name: d.name,
            contractId: d.contractId || "",
            progress: 0,
            analyzeStatus: AIReadingStatus.ANALYZING,
            analyzeTaskId,
          },
          ...prev,
        ];
      } else {
        return prev.map((c) => {
          if (c.contractId === d.contractId) {
            if (analyzeTaskId) {
              let progress = c.progress;
              progress += 3;
              return {
                ...c,
                progress,
                analyzeStatus: AIReadingStatus.ANALYZING,
                analyzeTaskId,
              };
            } else {
              return {
                ...c,
                progress: 0,
                analyzeStatus: AIReadingStatus.ANALYZING,
              };
            }
          }
          return { ...c };
        });
      }
    });
  };
  const requestDocumentAnalysis = async (d: IDocument) => {
    if (d) {
      let analysisResponse: any;
      setIsAnalyzing(true);
      updateAnalyzedContracts(d);
      if (d.location === "sharepoint") {
        // we need to pass a downloadUrl
        const response = await getDriveItemContentAsPDF(d);
        if (response.data) {
          const downloadUrl = response.data;
          analysisResponse = await analyzeContract({ ...d, downloadUrl });
        }
      } else {
        analysisResponse = await analyzeContract(d);
      }

      if (analysisResponse?.data?.data) {
        updateAnalyzedContracts(d, analysisResponse?.data?.data);
      }

      if (analysisResponse?.error) {
        // TODO: handle error to show on the screen
        console.error("Error analyzing contract:", analysisResponse?.error);
      }
    }
  };

  const stopDocumentAnalysis = async (d: IDocument) => {
    await stopAnalysisTask(d);
  };

  const setOpenedContract = (c: AnalyzedContract | null) => {
    _setOpenedContract(c);
  };

  return (
    <ContractContext.Provider
      value={{
        openedContract,
        analyzedContracts,
        setOpenedContract,
        setAnalyzedContracts,
        requestDocumentAnalysis,
        stopDocumentAnalysis,
        analyzedContract,
        requestedDocumentAnalysis,
        analyzingNumber,
        isAnalyzing,
        analyzingSuccess,
      }}
    >
      {children}
    </ContractContext.Provider>
  );
}

export { ContractContext, ContractProvider };
