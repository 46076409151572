import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { spacing } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import {
  ArrowBack,
  Cancel,
  ChevronLeft,
  ChevronRight,
  Stop,
  StopCircleOutlined,
} from "@mui/icons-material";
import {
  Box,
  Fade,
  Grid,
  Link,
  Paper,
  Button,
  Typography,
  CircularProgress,
  Divider as MuiDivider,
  Breadcrumbs as MuiBreadcrumbs,
  Alert,
  AlertTitle,
  LinearProgress,
  AppBar,
  IconButton,
  Tooltip,
  Grow,
  Snackbar,
  Stepper,
  Step,
  StepButton,
  Collapse,
} from "@mui/material";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import useContract from "../../../hooks/useContract";
import ContractHeader from "./components/ContractHeader";
import DocumentViewer from "../../../components/viewers/DocumentViewer";
import ContractMetadata from "./components/ContractMetadata";
import ContractAnalyzeForm from "../../../components/forms/ContractForm";
import AIContractDetailSidebar from "./components/AIContractDetailSidebar";
import { IDocument } from "../../../types/document";
import { DialogMode } from "../../../types/dialogmode";
import { ISignature, ISignee } from "../../../types/signature";
import {
  AIReadingStatus,
  ContractStatus,
  IContract,
} from "../../../types/contract";
import {
  epochToDate,
  useGetContractQuery,
  useGetContractsQuery,
  useGetDocumentsQuery,
  useUpdateContractMutation,
} from "../../../redux/slices/indexApiSlice";
import Messages from "./components/ContractNotes";
import MessageDialog from "../../../components/dialogs/NoteDialog";
import { IGraphUser } from "../../../types/user";

import {
  useGetContractAnalysisStatusQuery,
  useLazyGetContractAnalysisStatusQuery,
} from "../../../redux/slices/aiSlice";
import { StopCircle } from "react-feather";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { format, isAfter } from "date-fns";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import ContractStatusStepper from "./components/ContractStatusStepper";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const AIContractDetail = () => {
  const { t } = useTranslation();
  const { user, method } = useAuth();
  const { clientId, contractId, pageMode, status } = useParams();
  const { requestDocumentAnalysis, analyzedContract, stopDocumentAnalysis } =
    useContract();
  const navigate = useNavigate();

  const users = useSelector((state: RootState) => state.users.users);

  const { data: contracts, isSuccess: contractsLoaded } = useGetContractsQuery(
    status as ContractStatus
  );

  const {
    data: contract,
    isLoading: contractLoading,
    isSuccess: contractLoaded,
    refetch: refetchContract,
    isError,
  } = useGetContractQuery(contractId ?? skipToken);

  // get the documents for the contract
  const { data: documents = [] } = useGetDocumentsQuery(
    contractId ?? skipToken
  );

  const [syncAnalysisTask, { data: contractAnalysisTaskStatus }] =
    useLazyGetContractAnalysisStatusQuery();

  const [
    updateContract,
    {
      data: updatedContract,
      isLoading: updating,
      isSuccess: contractUpdated,
      reset: resetContractUpdated,
      isError: errorWhileUpdating,
      error: updateError,
    },
  ] = useUpdateContractMutation();

  useEffect(() => {
    if (analyzedContract?.analyzeStatus === AIReadingStatus.COMPLETED) {
      // analysis done, refetch the contract
      refetchContract();
    }
  }, [analyzedContract?.analyzeStatus]);

  useEffect(() => {
    if (contract?.analyzeStatus === AIReadingStatus.ANALYZING) {
      // as a contractId addition to the webhook call, get the status when the page is refreshed
      syncAnalysisTask(contract);
    }
  }, [contract, contractId]);

  const [mode, setMode] = useState<DialogMode>(DialogMode.ViewOnly);

  const [document, setDocument] = useState<IDocument>();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [documentViewerReady, setDocumentViewerReady] =
    useState<boolean>(false);

  // state for edit mode
  const [analyzeProgress, setAnalyzeProgress] = useState(0);
  const [analysisStatus, setAnalysisStatus] = useState<AIReadingStatus>(
    AIReadingStatus.NONE
  );
  // const [initialContract, setInitialContract] = useState<IContract>();
  const [signer, setSigner] = useState<ISignee>();
  const [signees, setSignees] = useState<Array<ISignee>>([]);
  const [signatures, setSignatures] = useState<Array<ISignature>>([]);
  const [reloadSignatures, setReloadSignatures] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [searchForText, setSearchForText] = useState<string>("");

  // For some reason states (contract, signatures) is not updated
  // in saving functions (handleSaveAsDraft and handleSaveAndPublish).
  // So I use useRef to solve this problem.
  const updatedContractDataRef = useRef<{
    contract: IContract | undefined;
    signatures: ISignature[];
  }>({ contract: undefined, signatures: [] });

  updatedContractDataRef.current = {
    contract: contract ? { ...contract } : undefined,
    signatures: [...signatures],
  };

  let interval: NodeJS.Timeout | null = null;

  useEffect(() => {
    if (documents.length > 0) {
      setDocument(documents[0]);
    }
  }, [documents]);

  useEffect(() => {
    setDocumentViewerReady(false);
  }, [contractId]);

  useEffect(() => {
    if (contract) {
      const newSignees = contract.signatures?.map((sig: ISignature) => {
        const signee: ISignee = {
          displayName: sig.displayName,
          email: sig.email,
          hasSigned: !!sig.signedDate,
          signatureId: sig.id,
          isApproved: sig.isApproved
        };
        return signee;
      });

      if (newSignees) {
        setSignees(newSignees);
      }

      const newSignatures = contract.signatures?.map((sig: ISignature) => ({
        ...sig,
        draggable: !sig.isSigned,
      }));

      if (newSignatures) {
        setSignatures(newSignatures);
      }

      if (contract.analyzeStatus && contract.analyzeStatus !== analysisStatus) {
        setAnalysisStatus(contract.analyzeStatus);
      }
    }
  }, [contract, reloadSignatures, contractId]);

  useEffect(() => {
    if (isError) {
      navigate("/404");
    }
  }, [isError]);

  useEffect(() => {
    if (pageMode) {
      if (pageMode === "edit") {
        setMode(DialogMode.Edit);
      }
    }
  }, [pageMode]);

  useEffect(() => {
    if (contractUpdated) {
      navigate("/contracts", {
        state: { reload: true },
      });
    }
  }, [contractUpdated]);

  useEffect(() => {
    // hide analysis alert (success or failed)
    if (
      mode === DialogMode.ViewOnly &&
      (analysisStatus === AIReadingStatus.COMPLETED ||
        analysisStatus === AIReadingStatus.ERROR)
    ) {
      setAnalysisStatus(AIReadingStatus.NONE);
    }
  }, [mode]);

  useEffect(() => {
    if (!contractId && !contractLoading && contracts && contracts?.length > 0) {
      // if no id is provided, redirect to the first contract
      navigate(`/contracts/${contracts[0].id}`);
    }

    if (contractId && !contractLoading && contracts && contracts?.length > 0) {
      const currentIndex = contracts?.findIndex(
        (c: IContract) => c.id === contractId
      );

      setCurrentIndex(currentIndex);
    }
  }, [contractId, contractLoading, contracts]);

  useEffect(() => {
    if (contract && contract.signatures) {
      const userHaveSignature = contract.signatures.find(
        (sig: ISignature) => sig.email === user.email
      );
      console.log("userHaveSignature", userHaveSignature);
      if (userHaveSignature) {
        setSigner({
          displayName: user.displayName,
          email: user.email,
          hasSigned: !!userHaveSignature.signedDate,
          signatureId: userHaveSignature.id,
        });
      }
    }
  }, [contract, user]);

  useEffect(() => {
    if (analyzedContract && analysisStatus === AIReadingStatus.ANALYZING) {
      if (
        analyzedContract.id === contract?.id &&
        analyzedContract.analyzeStatus === AIReadingStatus.COMPLETED
      ) {
        setAnalysisStatus(AIReadingStatus.COMPLETED);
        // setInitialContract({
        //   ...analyzedContract,
        // });

        // refetch the contract
        refetchContract();
      } else if (analyzedContract.id !== contract?.id) {
        setAnalysisStatus(AIReadingStatus.NONE);
      }
    }
  }, [analyzedContract, contractId]);

  // useEffect(() => {
  //   setAnalysisStatus(AnalysisStatus.NONE);
  // }, [id]);

  useEffect(() => {
    if (analysisStatus === AIReadingStatus.ANALYZING) {
      setAnalyzeProgress(0);
      interval = setInterval(() => {
        setAnalyzeProgress((prev) => {
          if (prev < 70) {
            return prev + 10;
          } else if (prev < 97) {
            return prev + 3;
          } else {
            if (interval) {
              clearInterval(interval);
            }
            return prev;
          }
        });
      }, 400);
    }
  }, [analysisStatus]);

  const stopAnalysis = () => {
    if (document) {
      // taskId = contract?.analyzeTaskId
      stopDocumentAnalysis(document);
    }

    // stop the interval
    if (interval) {
      clearInterval(interval);
    }

    refetchContract();

    setAnalysisStatus(AIReadingStatus.STOPPED);
  };

  const prev = () => {
    if (contracts && contracts.length > 0) {
      const prevContractId = contracts[currentIndex - 1]?.id || null;

      if (prevContractId) {
        if (clientId) {
          navigate(
            `/contracts-under-management/${clientId}/contract/${prevContractId}/${mode}`
          );
        } else {
          navigate(`/contract/${prevContractId}/${mode}`);
        }
      }
    }
  };

  const next = async () => {
    if (contracts && contracts.length > 0) {
      // get the id of the next contract

      const nextContractId = contracts[currentIndex + 1]?.id || null;

      if (nextContractId) {
        if (clientId) {
          navigate(
            `/contracts-under-management/${clientId}/contract/${nextContractId}/${mode}`
          );
        } else {
          navigate(`/contract/${nextContractId}/${mode}`);
        }
      }
    }
  };

  const handleAnalysis = () => {
    if (document) {
      setAnalysisStatus(AIReadingStatus.ANALYZING);
      requestDocumentAnalysis(document);

      setShowSnackbar(true);
    }
  };

  const storeUsers = (): IGraphUser[] => {
    let userStore: IGraphUser[] = [];

    // add the add users to a new contract, so send notification and mail of expired contact.
    if (contract && method !== "Google" && users && users.length) {
      // If method is Google then we stored this user by login step
      let userIds: string[] =
        (contract && contract.ownerIds) || (contract && contract.managerIds)
          ? [...contract?.ownerIds, ...contract?.managerIds]
          : [];

      const userIdsUnique = userIds?.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
      userStore = users
        .filter((u: IGraphUser) =>
          userIdsUnique.some((id: string) => id == u.id)
        )
        .map((item: IGraphUser) => {
          item = { ...item, mail: item.mail || item.userPrincipalName };
          return item;
        });
    }

    return userStore;
  };

  const handleClose = () => {
    setShowSnackbar(false);
  };

  return (
    <React.Fragment>
      <Helmet title={contract?.name} />

      <Grid justifyContent="space-between" container wrap="nowrap">
        <Grid item xl={10}>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/contracts" color="inherit">
              <Typography variant="h3" gutterBottom display="inline">
                {t("Contracts")}
              </Typography>
            </Link>

            {status && (
              <Link
                component={NavLink}
                to={`/contracts/${status}`}
                color="inherit"
              >
                <Typography variant="h3" gutterBottom display="inline">
                  {t(status)}
                </Typography>
              </Link>
            )}

            <Typography
              variant="h3"
              gutterBottom
              display="inline"
              color="primary"
            >
              {contract?.name}
            </Typography>
          </Breadcrumbs>
        </Grid>

        {contractsLoaded && (
          <Grid item xl={2}>
            <Grid container justifyContent="end" direction="row" gap={3}>
              <Grid item>
                <Button
                  onClick={prev}
                  variant="contained"
                  disabled={currentIndex === 0}
                >
                  <ChevronLeft />
                </Button>
              </Grid>

              <Grid item>
                <Button
                  onClick={next}
                  variant="contained"
                  disabled={currentIndex === contracts?.length - 1}
                >
                  <ChevronRight />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      {/* <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          {t("Dashboard")}
        </Link>
        <Link component={NavLink} to="/contracts">
          {t("Contracts")}
        </Link>
        <Typography>{contract?.name}</Typography>
      </Breadcrumbs> */}

      <Divider my={6} />

      {contract?.status && (
        <Box px={6} mb={6} maxWidth="md">
          <ContractStatusStepper contract={contract} />
        </Box>
      )}

      <Paper
        sx={{
          marginTop: 0,
          marginBottom: 6,
          paddingLeft: 4,
          paddingRight: 4,
          paddingTop: 4,
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          height: "100%",
          overflow: "hidden",
        }}
      >
        {/* {contractLoading && (
          <Box textAlign="center">
            <CircularProgress />
          </Box>
        )} */}
        {contract && (
          <Box mb={4} display="flex">
            <ContractHeader contract={contract} />
          </Box>
        )}
        {errorWhileUpdating && (
          <Alert severity="error" sx={{ mb: 6 }}>
            <AlertTitle>{t("An error occurred!")}</AlertTitle>

            {JSON.stringify(updateError)}
          </Alert>
        )}

        {/* only show completed if modified was within the last 5 minutes */}
        {/* {analysisStatus === AIReadingStatus.COMPLETED &&
          isAfter(
            new Date(epochToDate(contract?.modified)),
            new Date(epochToDate(Date.now() - 300000))
          ) && (
            <Alert severity="success" sx={{ mb: 6 }}>
              <AlertTitle>{t("Success")}</AlertTitle>
              {t("Reading success")}
            </Alert>
          )}
         */}

        {analysisStatus === AIReadingStatus.STOPPED && (
          <Alert severity="info" sx={{ mb: 6 }}>
            <AlertTitle>{t("Stopped")}</AlertTitle>
            {t("Analysis stopped")}
          </Alert>
        )}
        {analysisStatus === AIReadingStatus.ERROR && (
          <Alert severity="error" sx={{ mb: 6 }}>
            <AlertTitle>{t("An error occurred!")}</AlertTitle>
            {t("Reading failed")}
          </Alert>
        )}
        {analysisStatus === AIReadingStatus.ANALYZING && (
          <Box mb={6}>
            <Alert
              severity="info"
              // action={
              //   <Button color="primary" onClick={() => stopAnalysis()}>
              //     <Stop /> {t("Stop")}
              //   </Button>
              // }
            >
              <AlertTitle>{t("Reading")}</AlertTitle>
              {t("Reading document")}
            </Alert>
            <LinearProgress variant="determinate" value={analyzeProgress} />
          </Box>
        )}

        <Grid
          container
          display="flex"
          justifyContent="space-between"
          flexDirection="row"
          spacing={3}
          pb={6}
          sx={{ height: "100%", overflow: "hidden" }}
        >
          <Grid item xs={12} md={8} sx={{ height: "100%" }}>
            {/* {contract && (
              <Box>
                <ContractMetadata contract={contract} />
              </Box>
            )} */}

            <Box height="100%" sx={{ overflow: "hidden" }}>
              <DocumentViewer
                viewMode="withMenu"
                document={document}
                signer={signer}
                signable={false}
                signatures={signatures}
                setSignatures={setSignatures}
                onSuccess={() => setDocumentViewerReady(true)}
                paging={false} // true
                searchForText={searchForText}
                // disableAppBar
                // disableScroller
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4} sx={{ height: "100%" }}>
            {contract && document && (
              <Box height="100%">
                <AIContractDetailSidebar
                  mode={mode}
                  contract={contract}
                  document={document}
                  signer={signer}
                  signees={signees}
                  onAnalysis={handleAnalysis}
                  setSignees={setSignees}
                  setSignatures={setSignatures}
                  signatures={signatures}
                  resetSignatures={setReloadSignatures}
                  analysisStatus={analysisStatus}
                  setSearchForText={setSearchForText}
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </Paper>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={showSnackbar}
        onClose={handleClose}
        message={t("Analysis requested")}
        key={"bottomright"}
        autoHideDuration={5000}
        color="primary"
      />
    </React.Fragment>
  );
};

export default AIContractDetail;
