import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Avatar,
  Box,
  Button,
  Fade,
  Grid,
  IconButton,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  TextField,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridToolbar,
  GridState,
  gridPaginationModelSelector,
  useGridApiRef,
  GridRowSelectionModel,
  GridToolbarQuickFilter,
  GridPaginationModel,
} from "@mui/x-data-grid";
import { spacing } from "@mui/system";
import { useTranslation } from "react-i18next";
import EnhancedDataGridToolbar from "./EnhancedDataGridToolbar";
import { useLazySearchCompanyQuery } from "../../redux/slices/kvkSlice";
import { PinDrop } from "@mui/icons-material";
import { Search } from "react-feather";
import { KVKSearchResponseItem } from "../../types/zoeken";
import CustomNoRowsOverlay from "./CustomNoRowsOverlay";
const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const AboutIcon = styled.span`
  display: flex;
  padding-right: ${(props) => props.theme.spacing(2)};

  svg {
    width: 14px;
    height: 14px;
  }
`;

function QuickSearchToolbar() {
  const { t } = useTranslation();

  return (
    <Box p={2}>
      <GridToolbarQuickFilter placeholder={t("Search")!} />
    </Box>
  );
}

interface IDataGridCompaniesProps {
  view?: "cards" | "table";
  onSelect?: (selected: any) => void;
  searchText?: string; // a default value to search on
  refetch?: boolean;
}

function DataGridCompanies({ ...props }: IDataGridCompaniesProps) {
  const { t } = useTranslation();

  const [searchCompany, { data: companies, isLoading, isSuccess }] =
    useLazySearchCompanyQuery();

  // const [getUserPhotoForUser, { data: photoData }] = useGetUserPhotoForUserMutation({ id: "" });
  // const [displayedUsers, setDisplayedUsers] = useState<IGraphUser[]>([]);
  // const [userPhotos, setUserPhotos] = useState<Record<string, string>>({});

  const apiRef = useGridApiRef();
  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([]);
  const [searchText, setSearchText] = useState<string>("");

  useEffect(() => {
    if (props.searchText) {
      // Set the default selected items
      setSearchText(props.searchText);

      handleSearch(props.searchText);
    }
  }, [props.searchText]);

  // const paginationModel = gridPaginationModelSelector(apiRef.current.state, apiRef.current.instanceId);

  useEffect(() => {
    // this is the id of the selected row/user
    console.log(rowSelectionModel);

    if (props.onSelect) {
      const company = companies?.resultaten.find(
        (c: any) => c.id === rowSelectionModel[0]
      );
      props?.onSelect(company);
    }
  }, [rowSelectionModel]);

  const columns: GridColDef[] = [
    // {
    //   field: "id",
    //   headerName: "Id",
    //   hideable: true,
    // },
    { flex: 1, field: "naam", headerName: t("Name")! },
    {
      field: "kvkNummer",
      headerName: t("KVK number")!,
      width: 120,
    },
    {
      field: "adres",
      headerName: t("Address")!,
      width: 400,
      renderCell: (params) => {
        // const t: KVKSearchResponseItem;

        const adres = params.value?.binnenlandsAdres;
        return (
          adres && (
            <Grid container direction="row" alignItems="center" mb={2}>
              {/* <Grid item xs={12}>
                <Typography variant="caption">{adres?.type}</Typography>
              </Grid> */}
              <Grid item alignItems="start">
                <AboutIcon>
                  <PinDrop />
                </AboutIcon>
              </Grid>
              <Grid item>
                {adres?.straatnaam}, {adres?.plaats}
              </Grid>
            </Grid>
          )
        );
      },
      valueFormatter: (params) => {
        return JSON.stringify(params.value);
      },
    },
  ];

  const handleMUIDataGrid = () => {
    if (!isLoading && companies) {
      // only get the users that are on the current page
      // const usersOnCurrentPage = users.slice(page * pageSize, page * pageSize + pageSize);

      return companies?.resultaten;
    }

    return [];
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const handleSearch = async (query: any) => {
    searchCompany(`?naam=${query}`);
  };

  const handleNextPage = () => {
    const link = companies?.volgende;

    // get the query params from the next link
    const url = new URL(link);
    const searchParams = url.search; // '?naam=schiphol&pagina=2&resultatenperpagina=10'

    console.log(searchParams);

    searchCompany(searchParams);
  };

  const handlePreviousPage = () => {
    const link = companies?.vorige;

    // get the query params from the next link
    const url = new URL(link);
    const searchParams = url.search; // '?naam=schiphol&pagina=2&resultatenperpagina=10'

    console.log(searchParams);

    searchCompany(searchParams);
  };

  return (
    <Paper mb={16} elevation={0}>
      <Box
        // height="55vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
        }}
      >
        <Paper p={4} elevation={0}>
          <Grid
            container
            justifyContent="center"
            alignContent="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <TextField // Search field
                variant="outlined"
                label={t("Search")}
                fullWidth
                value={searchText}
                placeholder={t("Search in KVK")!}
                onChange={handleSearchChange}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch(searchText);
                  }
                }}
                sx={{ mb: 2 }}
                InputProps={{
                  startAdornment: (
                    <IconButton
                      onClick={() => handleSearch(searchText)}
                      size="large"
                    >
                      <Search />
                    </IconButton>
                  ),
                }}
                helperText={
                  searchText &&
                  !isSuccess &&
                  !isLoading &&
                  t("Press enter to search")
                }
              />
            </Grid>
          </Grid>
        </Paper>

        {isSuccess && companies?.resultaten?.length === 0 && (
          <Box p={6}>
            <CustomNoRowsOverlay label={t("No results")} />
          </Box>
        )}

        {isSuccess && companies?.resultaten?.length > 0 && (
          <DataGrid
            initialState={{
              pagination: {
                paginationModel: {
                  page: 0,
                  pageSize: 10,
                },
              },
            }}
            apiRef={apiRef}
            rows={companies?.resultaten || []}
            rowCount={companies?.totaal}
            columns={columns}
            autoHeight
            checkboxSelection
            // disableRowSelectionOnClick
            loading={isLoading}
            hideFooterSelectedRowCount
            //  onRowDoubleClick={handleRowDoubleClick}
            // onPaginationModelChange={handlePageChange}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(
                newRowSelectionModel?.length > 1
                  ? newRowSelectionModel.slice(-1) // only allow one row to be selected
                  : newRowSelectionModel
              );
            }}
            rowSelectionModel={rowSelectionModel}
            slots={{
              toolbar: () => (
                <EnhancedDataGridToolbar
                  rowSelectionModel={rowSelectionModel}
                  view={"table"}
                  title={""}
                  isLoading={isLoading}
                />
              ),
              // noRowsOverlay: () => (
              //   <CustomNoRowsOverlay label={t("No companies")} />
              // ),
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
              pagination: {
                // showFirstButton: true,
                // showLastButton: true,
                nextIconButtonProps: {
                  onClick: handleNextPage,
                },
                backIconButtonProps: {
                  onClick: handlePreviousPage,
                },
                page: companies?.pagina - 1 || 0,
                size: companies?.resultatenPerPagina || 10,
              },
              // pagination: {
              //   labelRowsPerPage: t("Rows per page"),
              //   labelDisplayedRows(paginationInfo) {
              //     return `${paginationInfo.from}-${paginationInfo.to} ${t("of")} ${paginationInfo.count}`;
              //   },
              // },
            }}
            density="standard"
          />
        )}
      </Box>
    </Paper>
  );
}

export default DataGridCompanies;
