import React, { useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Collapse,
  Grid,
  Link,
  List,
  ListItemIcon,
  ListSubheader,
  Chip as MuiChip,
  Avatar as MuiAvatar,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  ListItem,
  ListItemButton as MuiListItemButton,
  ListItemText as MuiListItemText,
  Typography,
  Paper as MuiPaper,
  Box,
  ListItemSecondaryAction,
  IconButton,
  Button,
  Fade,
  Tooltip,
  Backdrop,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem, { MenuItemProps } from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { alpha, spacing } from "@mui/system";
import CustomNoRowsOverlay from "../../../../components/datagrids/CustomNoRowsOverlay";
import AzureADAvatar from "../../../../components/avatars/AzureADAvatar";
import {
  useDeleteNoteMutation,
  useGetNotesForContractQuery,
  useUpdateNoteMutation,
} from "../../../../redux/slices/indexApiSlice";
import { IGraphUser } from "../../../../types/user";
import StyledMenu from "../../../../theme/StyledMenu";
import MenuItemAsLabel from "../../../../theme/MenuItemAsLabel";
import { Info } from "react-feather";
import { useTranslation } from "react-i18next";
import { DialogMode } from "../../../../types/dialogmode";
import { IContract } from "../../../../types/contract";
import { IVendor } from "../../../../types/vendor";
import DateTimeTranslate from "../../../../components/DateTimeTranslate";
import NoteDialog from "../../../../components/dialogs/NoteDialog";
import { INote } from "../../../../types/note";
import NoteAsListItem from "../../../../components/lists/listitems/NoteAsListItem";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { useGetNotesForVendorQuery } from "../../../../redux/slices/vendorsApiSlice";

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const ListItemButton = styled(MuiListItemButton)(spacing);

const Avatar = styled(MuiAvatar)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Spacer = styled.div(spacing);

const ListItemText = styled(MuiListItemText)(spacing);

// `color: ${(props) => props.theme.typography.body2.color};
// opacity: initial !important;
// &:hover {
//   background-color: transparent;
// },
// &:hover > .MuiListItemIcon-root: {
//   color: "inherit", // Disable icon color change on hover
// }`

interface IContractNotesProps {
  contract?: IContract;
  vendor?: IVendor;
  selectedUserIds?: string[];
  readOnly?: boolean;
  hideTitle?: boolean;
  setBadgeCount?: (count: number) => void;
}
function ContractNotes({ ...props }: IContractNotesProps) {
  const { t } = useTranslation();
  const { readOnly } = props;

  const { data: notesForContract = [], isSuccess: notesForContractLoaded } =
    useGetNotesForContractQuery(props.contract?.id || "", {
      skip: !props.contract?.id,
    });

  const { data: notesForVendor = [], isSuccess: notesForVendorLoaded } =
    useGetNotesForVendorQuery(props.vendor?.id || "", {
      skip: !props.vendor?.id,
    });

  const [hoveredIndex, setHoveredIndex] = useState(-1);
  const [notes, setNotes] = useState<INote[]>([]);

  useEffect(() => {
    props.setBadgeCount && props.setBadgeCount(notes.length);
  }, [notes]);

  useEffect(() => {
    if (notesForContractLoaded && props.contract?.id && notesForContract) {
      setNotes(notesForContract);
    } else if (notesForVendorLoaded && props.vendor?.id && notesForVendor) {
      setNotes(notesForVendor);
    }
  }, [
    notesForContract,
    notesForVendor,
    notesForVendorLoaded,
    notesForContractLoaded,
    props.contract?.id,
    props.vendor?.id,
  ]);

  const theme = useTheme();
  const isDownLg = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Box
      flex="1"
      display="flex"
      flexDirection="column"
      alignItems={isDownLg ? "flex-start" : "flex-end"}
    >
      <Box width="100%">
        {!props.hideTitle && (
          <Typography variant="h6" mb={2}>
            {t("Notes")}
          </Typography>
        )}

        {!readOnly && (
          <NoteDialog
            contract={props.contract}
            vendor={props.vendor}
            mode={DialogMode.Add}
            type="note"
            selectedUserIds={props.selectedUserIds}
          />
        )}

        <Spacer mb={4} />

        <Grid container direction="column" gap={1} mt={2}>
          {notes?.length === 0 ? (
            <CustomNoRowsOverlay label={t("No notes")} />
          ) : (
            <List onMouseLeave={() => setHoveredIndex(-1)}>
              {notes?.map((note: INote, i: number) => {
                return (
                  <>
                    {i > 0 && <Divider />}

                    <NoteAsListItem
                      note={note}
                      showMenuIcon={i === hoveredIndex}
                      onMouseEnter={() => setHoveredIndex(i)}
                    />
                  </>
                );
              })}
            </List>
          )}
        </Grid>
      </Box>
    </Box>
  );
}

export default ContractNotes;
