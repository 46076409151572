import React, { useEffect, useState } from "react";
import { IVendor } from "../../types/vendor";
import { Form, Formik, FormikProps, useFormikContext } from "formik";
import * as Yup from "yup";
import styled from "@emotion/styled";

import {
  Alert as MuiAlert,
  AvatarGroup,
  createFilterOptions,
  Box,
  Button as MuiButton,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  Grid,
  IconButton,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  TextField as MuiTextField,
  Typography,
  ImageList,
  ImageListItem,
  Tab,
  Tabs,
  ListItemButton,
  Autocomplete,
} from "@mui/material";
import { spacing } from "@mui/system";

import axios from "axios";

import { useTranslation } from "react-i18next";
import { DialogMode } from "../../types/dialogmode";

const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);
const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const apiKey = "AIzaSyCsjfebVdnN7tFX4gQFSyNR3IEOjLzzgGU";
const searchEngineId = "a2103fc35f7874662";

// ...

function ImageSearch({ ...props }) {
  const limit = 3;
  const [imageResults, setImageResults] = useState<any[]>([]);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const baseUrl = "https://www.googleapis.com/customsearch/v1";

  useEffect(() => {
    if (props.query?.length > 2) {
      const keyword = `${props.query} logo`;
      const url = `${baseUrl}?key=${apiKey}&cx=${searchEngineId}&q=${encodeURIComponent(
        keyword
      )}&searchType=image`;

      search(url);
    }
  }, [props.query]);

  const search = async (url: string) => {
    try {
      const response = await axios.get(url);
      const items = response.data.items;
      const imageUrls = items.map((item: any) => item.link);

      // only return the first 3 results
      setImageResults(items.slice(0, limit));
    } catch (error) {
      console.error("Error searching images:", error);
      return [];
    }
  };

  const handleImageClick = (item: any) => {
    const imageUrl = item.link;

    setSelectedImage(imageUrl);
    props.onImageSelected(imageUrl);
  };

  return (
    <ImageList sx={{ width: "100%", height: 170 }} cols={3} rowHeight={120}>
      {imageResults.map((item, i: number) => (
        <Button
          key={item.img}
          onClick={() => handleImageClick(item)}
          color="primary"
          variant={selectedImage === item.link ? "contained" : "outlined"}
          fullWidth
        >
          <img
            src={item.image.thumbnailLink}
            alt={item.title}
            loading="lazy"
            style={{
              objectFit: "contain",
            }}
          />
        </Button>
      ))}
    </ImageList>
  );
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  logo: Yup.string().optional(),
  street: Yup.string().optional(),
  houseNumber: Yup.string().optional(),
  city: Yup.string().optional(),
  country: Yup.string().optional(),
  phone: Yup.string().optional(),
});

interface IVendorFormProps {
  vendor?: IVendor;
  mode: DialogMode;
  addVendor: (vendor: IVendor) => Promise<void>;
  updateVendor: (vendor: IVendor) => Promise<void>;
}

function VendorForm({ ...props }: IVendorFormProps) {
  const { t } = useTranslation();

  const handleSubmit = async (
    values: any,
    { resetForm, setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      console.log("submitting form");
      if (props.mode === DialogMode.Add) {
        await props.addVendor(values);

        // resetForm();
        setStatus({ sent: true });
        setSubmitting(false);
      } else if (props.mode === DialogMode.Edit) {
        await props.updateVendor(values);

        // resetForm();
        setStatus({ sent: true });
        setSubmitting(false);
      }
    } catch (error: any) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  const handleSelectedCompanyFromKVK = (selected: any, setFieldValue: any) => {
    // update the form with the selected company
    console.log("selected", selected);

    setFieldValue("name", selected.naam);
    setFieldValue("kvkNumber", selected.kvkNummer);
    setFieldValue("street", selected.adres?.binnenlandsAdres?.straatnaam);
    setFieldValue("city", selected.adres?.binnenlandsAdres?.plaats);
    setFieldValue(
      "country",
      selected.adres?.binnenlandsAdres
        ? "Nederland"
        : selected.adres?.buitenlandsAdres?.land
    );
  };

  // const initialValues = {
  //   name: props.contract?.name,
  //   startDate: props.contract?.startDate,
  //   endDate: props.contract?.endDate,
  //   value: props.contract?.value,
  // };

  const emptyVendor: IVendor = {
    id: "",
    logo: "",
    postalCode: "",
    name: "",
    street: "",
    houseNumber: "",
    city: "",
    country: "",
    phone: "",
    contacts: [],
    website: "",
    kvkNumber: "",
  };

  const initialValues: IVendor = props.vendor
    ? { ...props.vendor }
    : emptyVendor;

  const fieldVariant = "standard"; // "outlined";
  const errorFieldVariant = "filled";
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
        isValid,
      }) => (
        <Grid container mb={6}>
          <Grid item width="100%">
            {status && status.sent && (
              <Alert severity="success" my={3}>
                {t("Your data has been submitted successfully!")}
              </Alert>
            )}

            {errors && !isValid && !status?.sent && (
              <Alert severity="error" my={3}>
                {t("An error occurred!")}
                {errors && Object.keys(errors).length > 0 && (
                  <pre style={{ whiteSpace: "pre-wrap" }}>
                    {JSON.stringify(errors, null, 2)}
                  </pre>
                )}
              </Alert>
            )}

            <Form id="vendor-form" onSubmit={handleSubmit}>
              <Grid container gap={3} direction="column">
                <Grid item>
                  <TextField
                    name="name"
                    label={t("Name")}
                    value={values.name}
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    // helperText={touched.name && errors.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant={
                      touched.name && errors.name
                        ? errorFieldVariant
                        : fieldVariant
                    }
                    my={2}
                  />
                  {/* 
                    <Grid item xs={4} display="flex" alignItems="center">
                      <SearchCompanyDialog
                        mode={DialogMode.Add}
                        setSelectedCompany={(selected: any) =>
                          handleSelectedCompanyFromKVK(selected, setFieldValue)
                        }
                        searchText={values?.name}
                      />
                    </Grid> */}
                </Grid>

                <Grid item xs={12} md={12}>
                  <Grid container spacing={3} flexDirection="row">
                    <Grid item sm={12} md={8}>
                      <TextField
                        name="street"
                        label={t("Street")}
                        value={values.street}
                        error={Boolean(touched.street && errors.street)}
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant={
                          touched.street && errors.street
                            ? errorFieldVariant
                            : fieldVariant
                        }
                        my={2}
                      />
                    </Grid>
                    <Grid item sm={12} md={4}>
                      <TextField
                        name="houseNumber"
                        label={t("House number")}
                        value={values.houseNumber}
                        error={Boolean(
                          touched.houseNumber && errors.houseNumber
                        )}
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant={
                          touched.houseNumber && errors.houseNumber
                            ? errorFieldVariant
                            : fieldVariant
                        }
                        my={2}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Grid container spacing={3} flexDirection="row">
                    <Grid item sm={12} md={6}>
                      <TextField
                        name="postalCode"
                        label={t("Postal code")}
                        value={values.postalCode}
                        error={Boolean(touched.postalCode && errors.postalCode)}
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant={
                          touched.city && errors.postalCode
                            ? errorFieldVariant
                            : fieldVariant
                        }
                        my={2}
                      />
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <TextField
                        name="city"
                        label={t("City")}
                        value={values.city}
                        error={Boolean(touched.city && errors.city)}
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant={
                          touched.city && errors.city
                            ? errorFieldVariant
                            : fieldVariant
                        }
                        my={2}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid>
                  <TextField
                    name="country"
                    label={t("Country")}
                    value={values.country}
                    error={Boolean(touched.country && errors.country)}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant={
                      touched.country && errors.country
                        ? errorFieldVariant
                        : fieldVariant
                    }
                    my={2}
                  />
                </Grid>

                <Grid item xs={12} md={8}>
                  <TextField
                    name="phone"
                    label={t("Phone")}
                    value={values.phone}
                    error={Boolean(touched.phone && errors.phone)}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant={
                      touched.phone && errors.phone
                        ? errorFieldVariant
                        : fieldVariant
                    }
                    my={2}
                  />
                </Grid>

                <Grid item xs={12} md={8}>
                  <TextField
                    name="website"
                    label={t("Website")}
                    value={values.website}
                    error={Boolean(touched.website && errors.website)}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant={
                      touched.website && errors.website
                        ? errorFieldVariant
                        : fieldVariant
                    }
                    my={2}
                  />
                </Grid>

                <Grid>
                  <TextField
                    name="kvkNumber"
                    label={t("KVK number")}
                    value={values.kvkNumber}
                    error={Boolean(touched.kvkNumber && errors.kvkNumber)}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant={
                      touched.kvkNumber && errors.kvkNumber
                        ? errorFieldVariant
                        : fieldVariant
                    }
                    my={2}
                  />
                </Grid>

                <Grid item>
                  <Grid container gap={3} flexDirection="column">
                    <Grid item>
                      <Typography>{t("Logo")}</Typography>
                    </Grid>

                    <Grid item>
                      <ImageSearch
                        query={values.name}
                        onImageSelected={(imageUrl: string) =>
                          setFieldValue("logo", imageUrl)
                        }
                      />
                    </Grid>

                    {/* <Grid item>
                      <TextField
                        name="logo"
                        label="Paste a url"
                        value={values.logo}
                        error={Boolean(touched.logo && errors.logo)}
                        fullWidth
                        // helperText={touched.name && errors.name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                      />
                    </Grid>

                    <Grid item>
                      <MuiFileInput placeholder={t("Select an image")!} label={t("Select an image")} />
                    </Grid> */}
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
}

export default VendorForm;
