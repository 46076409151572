import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import readXlsxFile, { Row } from "read-excel-file";
import Papa from "papaparse";

import {
  Alert as MuiAlert,
  AvatarGroup,
  Box,
  Button as MuiButton,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  Grid,
  IconButton,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  TextField as MuiTextField,
  Typography,
  Tooltip,
  AlertTitle,
  Divider,
  Icon,
} from "@mui/material";
import { borderLeft, height, spacing } from "@mui/system";
import {
  useAddContractMutation,
  useAddDocumentContentFromDownloadUrlMutation,
  useAddDocumentContentMutation,
  useAddDocumentMutation,
  useDeleteContractMutation,
} from "../../redux/slices/indexApiSlice";
import {
  Edit as EditIcon,
  Cancel,
  FileDownloadOutlined,
  FileUploadOutlined,
  SearchOutlined,
  TypeSpecimen,
  Forum,
  CreateOutlined,
  FeaturedPlayListOutlined,
  FeedOutlined,
  Store,
  StoreMallDirectory,
  AddShoppingCartRounded,
  AddBusiness,
} from "@mui/icons-material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { ContractStatus, IContract } from "../../types/contract";
import { useTranslation } from "react-i18next";
import { DialogMode } from "../../types/dialogmode";
import { Check } from "react-feather";
import useAuth from "../../hooks/useAuth";
import { FileUploader } from "react-drag-drop-files";
import FilesToUploadList from "../lists/FilesToUploadList";
import { IDocument } from "../../types/document";
import ImportDialog from "./ImportDialog";
import {
  useGetOutlookMessageAttachmentContentQuery,
  useLazyGetDriveItemContentAsPDFQuery,
  useLazyGetDriveItemContentQuery,
} from "../../redux/slices/graphApiSlice";

import { useAnalyzeFileMutation } from "../../redux/slices/aiSlice";
import LoadingButton from "@mui/lab/LoadingButton";
import NoFilesFoundIcon from "../../icons/NoFilesFoundIcon";
import useBotChat from "../../hooks/useBotChat";
import ConnectDriveDialog from "./ConnectDriveDialog";
import { IConnectedDrive } from "../../types/connecteddrive";
import useContract from "../../hooks/useContract";

import SearchCompanyDialog from "./SearchCompanyDialog";
import VendorForm from "../forms/VendorForm";
import VendorDialog from "./VendorDialog";
import { IVendor } from "../../types/vendor";
import VendorsToCreateList from "../lists/VendorsToCreateList";
import { KVKSearchResponseItem } from "../../types/zoeken";
import KvKIcon from "../../icons/KvKIcon";
import { useAddVendorMutation } from "../../redux/slices/vendorsApiSlice";

const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);

const UploadButton = styled(MuiPaper)`
  height: 144px;
  border: 1px dashed ${(props) => props.theme.palette.divider};
  padding: 12px;
  cursor: pointer;
  &:hover {
    border: 1px dashed ${(props) => props.theme.palette.primary.main};
  }
`;

const mimeDb = require("mime-db");

function getFileTypeName(mimeType: string) {
  const mimeEntry = mimeDb[mimeType];

  if (mimeEntry && mimeEntry.extensions) {
    const fileExtension = mimeEntry.extensions[0];
    return fileExtension.toLowerCase();
  }

  return "Unknown";
}

type IInitiateVendorsDialogProps = {
  mode: DialogMode;
  newAIinfo?: boolean;
  iconOnly?: boolean;
  inlineConfirmation?: boolean;
  initialValues?: any;
};

function InitiateVendorsDialog(props: IInitiateVendorsDialogProps) {
  const { t } = useTranslation();
  const { user } = useAuth();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState(props.mode || DialogMode.Add);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [errorType, setErrorType] = useState<any>("");
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const [documentsToImport, setDocumentsToImport] = useState<IDocument[]>([]);
  const [allFiles, setAllFiles] = useState<(File | IDocument)[]>([]);
  const [vendorsToCreate, setVendorsToCreate] = useState<IVendor[]>([]);

  const [uploadsProgress, setUploadsProgress] = useState<
    {
      filename: string;
      progress: number;
    }[]
  >([]);
  const [importDialogOpen, setImportDialogOpen] = useState<boolean>(false);
  const [connectDriveDialogOpen, setConnectDriveDialogOpen] =
    useState<boolean>(false);
  const [kvkDialogOpen, setKvkDialogOpen] = useState<boolean>(false);
  const [manualFormDialogOpen, setManualFormDialogOpen] =
    useState<boolean>(false);

  // since we can handle multiple contracts uploads at once we keep an array with the created contracts
  const [addedContracts, setAddedContracts] = useState<IContract[]>([]);

  // since we can handle multiple documents uploads at once we keep an array with the created documents
  const [addedDocuments, setAddedDocuments] = useState<IDocument[]>([]);
  const [openDialog, setOpenDialog] = useState(false);

  const { requestDocumentAnalysis } = useContract();

  const allowedFileTypes = ["XLS", "XLSX", "CSV"];
  const [fileError, setFileError] = useState("");
  const [file, setFile] = useState<any>(null);
  const [fileType, setFileType] = useState<any>(null);
  const [fileUrl, setFileUrl] = useState("");
  const [rows, setRows] = useState<any[]>([]);

  const [
    addVendor,
    {
      isSuccess: isAdded,
      isLoading: addingVendor,
      isError,
      error,
      reset: resetAdd,
    },
  ] = useAddVendorMutation();

  const onTypeError = (error: any) => {
    setErrorType(error);
    console.log(error);
  };

  useEffect(() => {
    if (!open) {
      setFilesToUpload([]);
      setDocumentsToImport([]);
      setUploadsProgress([]);
      setAddedContracts([]);
      setAddedDocuments([]);
    }
  }, [open]);

  const resetAndClose = () => {
    setOpenDialog(false);
    setOpen(false);
  };

  useEffect(() => {
    // console.log(filesToUpload);
    // console.log(documentsToImport);

    const newAllFiles = [...filesToUpload, ...documentsToImport];
    setAllFiles(newAllFiles);
  }, [filesToUpload, documentsToImport]);

  useEffect(() => {
    if (
      uploadsProgress.every((u) => u.progress === 100) &&
      addedDocuments.length === uploadsProgress.length
    ) {
      // handleComplete();
      // handleCloseAfterSuccess();
    }
  }, [uploadsProgress, addedDocuments]);

  // useEffect(() => {
  //   if (isAdded || isUpdated || isDeleted) {
  //     if (isAdded && addedContract) {
  //       resetAdd();
  //     }

  //     if (isUpdated && updatedContract) {
  //       resetUpdate();
  //     }

  //     if (isDeleted && deletedContract) {
  //       resetDelete();
  //     }

  //     setSuccess(true);

  //     if (props.refetch) {
  //       // re-get the message items if the add or delete mutation was successful
  //       props.refetch();
  //     }
  //   }
  // }, [
  //   isAdded,
  //   isUpdated,
  //   isDeleted,
  //   addedContract,
  //   updatedContract,
  //   deletedContract,
  // ]);

  const addVendorToCreate = (vendor: IVendor) => {
    setVendorsToCreate([...vendorsToCreate, vendor]);
  };

  const handleDeleteVendorToCreate = (index: number) => {
    const newVendorsToCreate = vendorsToCreate.splice(index, 1);
    setVendorsToCreate([...newVendorsToCreate]);
  };

  const addKVKCompany = (company: KVKSearchResponseItem) => {
    const newVendor: any = {
      name: company.naam || "",
      kvkNumber: company.kvkNummer || "",
      street: company.adres?.binnenlandsAdres?.straatnaam || "",
      houseNumber:
        company.adres?.binnenlandsAdres?.huisnummer?.toString() || "",
      postalCode: company.adres?.binnenlandsAdres?.postcode || "",
      city: company.adres?.binnenlandsAdres?.plaats || "",
      country: company.adres?.binnenlandsAdres ? "Nederland" : "",
      description: "", // HIER NOG DE ACTIVITEITEN TOEVOEGEN !!!
      foundedDate: undefined,
    };

    addVendorToCreate(newVendor);
  };

  const handleChange = (newValue: File | null) => {
    let selectedFile = newValue;
    if (selectedFile) {
      const fileTypeName = getFileTypeName(selectedFile.type);
      if (
        selectedFile &&
        allowedFileTypes.some(
          (ext) => ext.toLowerCase() === fileTypeName.toLowerCase()
        )
      ) {
        setFile(selectedFile);
        setFileType(fileTypeName.toLowerCase());
        setFileUrl(URL.createObjectURL(selectedFile));
      } else {
        setFileError(`${fileTypeName} is not allowed for upload`);
      }
    } else {
      setFile(null);
    }
  };

  const addDocumentToImport = (doc: IDocument) => {
    // add the doc to documentsToImport
    setDocumentsToImport([...documentsToImport, doc]);
  };

  const handleImport = async (doc: IDocument) => {
    addDocumentToImport(doc);
  };

  const handleConnectDrive = async (newDrive: IConnectedDrive) => {
    //addDocumentToImport(newDrive);
  };

  const handleConfirmDelete = async (e: any) => {
    e.stopPropagation();

    // Call the delete function to delete the item
    // await deleteContract(props.contract);

    // Hide the confirmation
    setShowConfirmation(false);
  };

  const handleCancelDelete = (e: any) => {
    e.stopPropagation();

    // Hide the confirmation
    setShowConfirmation(false);
  };

  const handleEditClick = (e: any) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleDeleteClick = (e: any) => {
    e.stopPropagation();

    if (props.inlineConfirmation) {
      setShowConfirmation(true);
    } else {
      setOpen(true);
    }
  };

  const handleImportDialogClose = () => {
    setImportDialogOpen(false);
  };

  const handleImportDialogOpen = () => {
    setImportDialogOpen(true);
  };

  const handleManualFormDialogOpen = () => {
    setManualFormDialogOpen(true);
  };

  const handleManualFormDialogClose = () => {
    setManualFormDialogOpen(false);
  };

  const handleKvkDialogOpen = () => {
    setKvkDialogOpen(true);
  };

  const handleKvkDialogClose = () => {
    setKvkDialogOpen(false);
  };

  const storeUsers = (): [] => {
    let userStore: any = [];

    // // add the add users to a new contract, so send notification and mail of expired contact.
    // if (analyzedContract && method != "Google" && users && users.length) {
    //   // If method is Google then we stored this user by login step
    //   let userIds: string[] =
    //     (analyzedContract && analyzedContract.ownerIds) ||
    //     (analyzedContract && analyzedContract.managerIds)
    //       ? [...analyzedContract?.ownerIds, ...analyzedContract?.managerIds]
    //       : [];

    //   const userIdsUnique = userIds?.filter((value, index, self) => {
    //     return self.indexOf(value) === index;
    //   });
    //   userStore = users
    //     .filter((u: any) => userIdsUnique.some((id: any) => id == u.id))
    //     .map((item: any) => {
    //       item = { ...item, email: item.mail || item.userPrincipalName };
    //       return item;
    //     });
    // }

    return userStore;
  };

  const handleProgress = (
    filename: string,
    progress: number,
    index: number // pass the index in case there are documents with the same filename
  ) => {
    setUploadsProgress((prev: any) => {
      if (
        !prev.some((p: any) => p.filename === filename && p.index === index)
      ) {
        // initiate the progress
        return [...prev, { filename, progress, index }];
      } else {
        // update the progress
        const updatedProgress = prev.map((p: any) => {
          if (p.filename === filename && p.index === index) {
            p.progress = progress;
          }
          return p;
        });

        return updatedProgress;
      }
    });
  };

  // Function is called when all files and documents have been uploaded
  const handleCloseAfterSuccess = () => {
    // request confirmation
    // setOpenDialog(true);

    // request the analysis for each added document
    // addedDocuments?.forEach((d) => {
    //   // request the analysis for the document
    //   // requestAnalysis(d);
    //   requestDocumentAnalysis(d);
    // });

    resetAndClose();
  };

  useEffect(() => {
    if (file && fileType) {
      if (fileType === "csv") {
        readCSVFile();
      } else if (fileType === "xlsx") {
        readXLSXFile();
      } else if (fileType === "xls") {
        readXLSXFile();
      } else {
        setFileError(`${fileType} is not allowed for upload`);
      }
    }
  }, [file, fileType]);

  const readCSVFile = () => {
    Papa.parse(file, {
      header: true,
      complete: (result) => {
        const rowArray: Row[] = result.data.map((element) => element as Row);
        setRows(rowArray);
      },
    });
  };

  const readXLSXFile = () => {
    readXlsxFile(file).then(async (rows) => {
      // skip the header
      rows.shift();
      setRows(rows);
    });
  };

  useEffect(() => {
    if (rows?.length > 0) {
      const rowVendors: any[] = rows.map((row) => {
        return {
          name: row[0]?.toString(),
          kvkNumber: row[1]?.toString(),
        };
      });
      setVendorsToCreate((prev) => [...prev, ...rowVendors]);
    }
  }, [rows]);

  const createVendors = async () => {
    const promises = [];
    const validVendors = vendorsToCreate.filter((v) => v.name || v.kvkNumber);

    for (const v of validVendors) {
      promises.push(addVendor(v).unwrap());
    }

    await Promise.all(promises)
      .then((addedVendors) => {
        addedVendors.forEach((r, i) => {
          console.log("Vendor added: ", i, r);
        });

        setOpen(false);
        setFile(null);
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  const downloadTemplate = () => {
    const downloadLink = document.createElement("a");
    downloadLink.href = "/static/template/vendors_import.xlsx"; // path to your Excel file
    downloadLink.download = "vendors_import.xlsx"; // name of the file when downloaded
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <>
      {mode === DialogMode.Delete && (
        <>
          {!showConfirmation && (
            <Tooltip title={t("Delete")}>
              {props.iconOnly ? (
                <IconButton size="small" onClick={handleDeleteClick}>
                  <DeleteIcon />
                </IconButton>
              ) : (
                <Button color="primary" onClick={() => setOpen(true)}>
                  {t("Delete contract")}
                </Button>
              )}
            </Tooltip>
          )}

          {props.inlineConfirmation && showConfirmation && (
            <Fade in={showConfirmation}>
              <Box>
                <Typography component="span">{t("Are you sure?")}</Typography>
                <IconButton
                  aria-label="Cancel"
                  size="small"
                  onClick={handleCancelDelete}
                >
                  <Cancel />
                </IconButton>
                <IconButton
                  aria-label="Confirm"
                  size="small"
                  onClick={handleConfirmDelete}
                >
                  <Check />
                </IconButton>
              </Box>
            </Fade>
          )}
        </>
      )}

      {mode === DialogMode.Add && (
        <>
          {props.iconOnly ? (
            <Tooltip title={t("Add vendors")}>
              <IconButton
                size="large"
                color="inherit"
                onClick={() => setOpen(true)}
              >
                <AddBusiness />
              </IconButton>
            </Tooltip>
          ) : (
            <LoadingButton
              variant="contained"
              sx={{ alignSelf: "flex-end" }}
              onClick={() => setOpen(true)}
            >
              <FileUploadOutlined
                style={{ marginRight: "8px" }}
              ></FileUploadOutlined>{" "}
              {t("Add vendors")}
            </LoadingButton>
          )}
        </>
      )}

      {mode === DialogMode.Edit &&
        (props.iconOnly ? (
          <IconButton size="small" onClick={handleEditClick}>
            <EditIcon />
          </IconButton>
        ) : (
          <Button color="primary" onClick={handleEditClick}>
            {t("Quick edit")}
          </Button>
        ))}

      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth
        // fullScreen
        sx={{ padding: 10, marginX: 30, marginY: 15 }}
      >
        <DialogTitle id="form-dialog-title">
          {mode === DialogMode.Add
            ? t("New vendor")
            : mode === DialogMode.Edit
            ? `${t("Edit vendor")}`
            : mode === DialogMode.Delete
            ? t("Are you sure you want to delete this contract?")
            : ""}
        </DialogTitle>
        <DialogContent>
          {mode !== DialogMode.Delete && (
            <>
              <Grid
                container
                spacing={6}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={
                    mode === DialogMode.Edit
                      ? {
                          pointerEvents: "none",
                          opacity: 0.5,
                        }
                      : null
                  }
                >
                  <FileUploader
                    multiple={false}
                    handleChange={handleChange}
                    name="file"
                    types={allowedFileTypes}
                    onTypeError={onTypeError}
                  >
                    <UploadButton
                      variant="outlined"
                      onClick={() => console.log("upload button clicked")}
                      sx={{ cursor: "pointer" }}
                    >
                      <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        textAlign="center"
                      >
                        <Grid item>
                          <FileUploadOutlined
                            fontSize="large"
                            color="primary"
                          />
                        </Grid>
                        <Grid item>
                          <Typography mt={2} mb={1} variant="subtitle2">
                            {t("Import from Excel")}
                          </Typography>
                          <Typography variant="caption" color="textSecondary">
                            {t("Import vendors from XLSX or CSV file")}
                          </Typography>
                        </Grid>
                      </Grid>
                    </UploadButton>
                  </FileUploader>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={
                    mode === DialogMode.Edit
                      ? {
                          pointerEvents: "none",
                          opacity: 0.5,
                        }
                      : null
                  }
                >
                  <UploadButton
                    variant="outlined"
                    onClick={() => handleManualFormDialogOpen()}
                    sx={{ cursor: "pointer" }}
                  >
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      textAlign="center"
                    >
                      <Box>
                        <FeedOutlined fontSize="large" color="primary" />
                      </Box>
                      <Typography mt={2} mb={1} variant="subtitle2">
                        {t("Enter manually")}
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        {t(
                          "Use the web form to enter the company details manually"
                        )}
                      </Typography>
                    </Grid>
                  </UploadButton>
                </Grid>

                {/* <Divider orientation="vertical" flexItem sx={{ mx: 6 }} /> */}

                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={
                    mode === DialogMode.Edit
                      ? {
                          pointerEvents: "none",
                          opacity: 0.5,
                        }
                      : null
                  }
                >
                  <UploadButton
                    variant="outlined"
                    onClick={() => handleKvkDialogOpen()}
                    sx={{ cursor: "pointer" }}
                  >
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      textAlign="center"
                    >
                      <Box>
                        {/* <SearchOutlined fontSize="large" color="primary" /> */}
                        <Icon fontSize="large">
                          <KvKIcon sx={{ height: "100%", width: "auto" }} />
                        </Icon>
                      </Box>
                      <Typography mt={2} mb={1} variant="subtitle2">
                        {t("Search in KVK")}
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        {t(
                          "Search in the business register of The Netherlands"
                        )}
                      </Typography>
                    </Grid>
                  </UploadButton>
                </Grid>
              </Grid>

              <Button onClick={() => downloadTemplate()} color="primary">
                {t("Download template")}
              </Button>

              {errorType ? (
                <Typography mt={2} mb={1} variant="subtitle1" color="error">
                  {errorType}
                </Typography>
              ) : (
                <></>
              )}

              {mode === DialogMode.Add && vendorsToCreate?.length > 0 && (
                <VendorsToCreateList
                  mode={mode}
                  vendorsWithProgress={
                    vendorsToCreate?.map((vendor, i: number) => {
                      const progress = uploadsProgress.find(
                        (p) => p.filename === vendor.name
                      );
                      return {
                        vendor: vendor,
                        progress: progress?.progress || 0,
                      };
                    }) as any
                  }
                  handleDeleteVendorToCreate={handleDeleteVendorToCreate}
                />
              )}
              {/* {allFiles?.length === 0 && (
                <Box mt={12} textAlign="center">
                  <NoFilesFoundIcon
                    sx={{
                      width: 65,
                      height: "auto",
                    }}
                  ></NoFilesFoundIcon>
                  <Typography mt={2} mb={1} variant="h6">
                    {t("No_files_found")}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {t("Upload_or_import_to_see_here")}
                  </Typography>
                </Box>
              )} */}
            </>
          )}
        </DialogContent>
        <DialogActions>
          {(allFiles.length === 0 || uploadsProgress.length == 0) && (
            <Button onClick={() => resetAndClose()} color="primary" autoFocus>
              {t("Cancel")}
            </Button>
          )}

          {(mode === DialogMode.Edit || mode === DialogMode.Add) && (
            <>
              {vendorsToCreate.length > 0 && (
                <>
                  {uploadsProgress.length > 0 &&
                  uploadsProgress.every((u) => u.progress === 100) ? (
                    <Button
                      onClick={() => handleCloseAfterSuccess()}
                      variant="contained"
                      color="primary"
                    >
                      {t("Close")}
                    </Button>
                  ) : (
                    <Button
                      onClick={() => createVendors()}
                      variant="contained"
                      color="primary"
                      disabled={
                        uploadsProgress.length > 0 &&
                        !uploadsProgress.every((u) => u.progress === 100)
                      }
                    >
                      {vendorsToCreate.length === 1
                        ? t("Create1Vendor")
                        : t("CreateXVendors", {
                            count: vendorsToCreate.length,
                          })}
                    </Button>
                  )}
                </>
              )}
            </>
          )}

          {mode === DialogMode.Delete && (
            <Button onClick={handleConfirmDelete} color="primary">
              {t("Delete")}
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <VendorDialog
        mode={mode}
        open={manualFormDialogOpen}
        onCancel={handleManualFormDialogClose}
        onSubmit={addVendorToCreate}
      />

      <SearchCompanyDialog
        mode={mode}
        searchText=""
        setSelectedCompany={addKVKCompany}
        open={kvkDialogOpen}
        onCancel={handleKvkDialogClose}
      />
    </>
  );
}

export default InitiateVendorsDialog;
