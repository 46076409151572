import styled from "@emotion/styled";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import {
  Box,
  CircularProgress,
  Button as MuiButton,
  Grid,
  Typography,
  Chip as MuiChip,
  Stepper,
  Step,
  StepButton,
} from "@mui/material";

import { ContractStatus, IContract } from "../../../../types/contract";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { green, grey, orange } from "@mui/material/colors";

const TypographyLight = styled(Typography)`
  color: #646464;
`;
const Chip = styled(MuiChip)<{ color?: string }>`
  height: 24px;
  border-radius: 12px;
  // font-size: 85%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"]}; // .light
  color: ${(props) => props.theme.palette.common.white};
`;

type IContractHeaderProps = {
  contract: IContract;
};

const getStatusColor = (status: any) => {
  switch (status) {
    case ContractStatus.DRAFT:
      return grey[500];
    case ContractStatus.PUBLISHED:
      return grey[800];
    case ContractStatus.SIGNING:
      return green[200];
    case ContractStatus.SIGNED:
      return green[500];
    case ContractStatus.ACTIVE:
      return orange[500];
    default:
      return "info";
  }
};

const ContractHeader: React.FC<IContractHeaderProps> = ({
  ...props
}: IContractHeaderProps) => {
  const { t } = useTranslation();
  const { contract } = props;
  const { status } = useParams();

  return (
    <Box>
      <Box mb={3}>
        <Grid
          container
          display="flex"
          justifyContent="space-between"
          width="100%"
        >
          <Grid item>
            <Typography variant="caption" mb={1}>
              {t("Contract ID")}: {contract?.id}
            </Typography>
            {/* <Typography variant="h4" mb={1}>
              {contract?.name}
            </Typography>

            <TypographyLight variant="caption">
              {t("Version")}: 1.0
            </TypographyLight> */}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ContractHeader;
