import React, { useEffect, useState, useTransition } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Avatar,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  Link,
  TextField as MuiTextField,
  Typography,
  Autocomplete,
  Box,
  Tooltip,
  IconButton,
  Fade,
  CardActions,
  CardActionArea,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
  TextField,
  Button,
  CircularProgress,
  CardMedia,
  AlertTitle,
  Alert,
  CardHeader,
} from "@mui/material";

import { spacing, SpacingProps } from "@mui/system";
import { useTranslation } from "react-i18next";
import {
  useGetConnectedDrivesQuery,
  useSyncConnectedDriveMutation,
} from "../../../redux/slices/indexApiSlice";

import { DialogMode } from "../../../types/dialogmode";

import { ISharePointConnection } from "../../../types/sharepointconnection";
import SharePointIcon from "../../../icons/SharePointIcon";
import { IConnectedDrive } from "../../../types/connecteddrive";
import ConnectedSharePointDriveDialog from "../../../components/dialogs/ConnectedSharePointDriveDialog";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  useGetDriveItemsQuery,
  useLazyGetDriveItemsQuery,
} from "../../../redux/slices/graphApiSlice";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

// enum for the data location
enum DataLocationOption {
  Default = "default",
  SharePoint = "sharepoint",
  AzureBlobStorage = "azureblobstorage",
}

function ConnectedDrives() {
  const { t } = useTranslation();

  const [selectedDataLocation, setSelectedDataLocation] = useState<string>(
    DataLocationOption.Default
  );
  const [selectedSharePointConnection, setSelectedSharePointConnection] =
    useState<ISharePointConnection>();

  const {
    data: connectedDrives = [],
    isLoading: connectedDrivesLoading,
    isSuccess: connectedDrivesLoaded,
    refetch: refetchConnectedDrives,
    isError: connectedDrivesError,
    error: connectedDrivesErrorObject,
  } = useGetConnectedDrivesQuery();

  const [sync, { isLoading: syncing, isSuccess: synced }] =
    useSyncConnectedDriveMutation();

  const [
    getDriveItems,
    {
      data: driveItems,
      isLoading: driveItemsLoading,
      isSuccess: driveItemsLoaded,
      isError: driveItemsError,
      error: driveItemsErrorObject,
    },
  ] = useLazyGetDriveItemsQuery();

  useEffect(() => {
    if (driveItemsLoaded && driveItems && driveItems?.length > 0) {
      console.log(driveItems);

      const drive = connectedDrives?.find(
        (d: IConnectedDrive) =>
          d.driveId === driveItems[0].parentReference.driveId
      );

      // send the drive items to the backend so they can be synced
      sync({
        driveItems: driveItems,
        connectedDrive: drive,
      });
    }
  }, [driveItemsLoaded, driveItems]);

  const startSync = async (drive: IConnectedDrive) => {
    await getDriveItems(drive.driveId);
  };

  return (
    <React.Fragment>
      <Helmet title={t("Trusted Partner")!} />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {t("Connected Drives")}
          </Typography>
          {/* 
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/admin">
              {t("Admin")}
            </Link>
            <Typography>{t("Connected Drives")}</Typography>
          </Breadcrumbs> */}
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container direction="column" gap={6}>
        <Grid item>
          <ConnectedSharePointDriveDialog
            mode={DialogMode.Add}
            refetch={refetchConnectedDrives}
          />
        </Grid>

        <Grid item>
          {connectedDrivesLoading ? (
            <CircularProgress />
          ) : (
            <Grid container spacing={6} direction="column">
              <Grid item>
                <Grid container gap={3} flexDirection="column">
                  {connectedDrivesError && (
                    <Grid item>
                      <Alert severity="error">
                        <AlertTitle>{t("An error occurred!")}</AlertTitle>
                        {JSON.stringify(connectedDrivesErrorObject)}
                      </Alert>
                    </Grid>
                  )}

                  <Grid item>
                    <Grid container display="flex" direction="row" gap={3}>
                      {connectedDrives?.map(
                        (drive: IConnectedDrive, i: number) => (
                          <Grid
                            item
                            key={`connectedDriveCard-${i}`}
                            display="flex"
                            md={6}
                            lg={3}
                          >
                            <Card
                              key={drive.id}
                              // onClick={() => handleDriveClick(drive)}
                            >
                              <CardHeader
                                avatar={<SharePointIcon />}
                                title={drive?.driveId}
                                subheader={drive?.driveId}
                              >
                                <Typography variant="h6" gutterBottom>
                                  {t("Connected Drive")}
                                </Typography>
                              </CardHeader>

                              <CardContent></CardContent>
                              <CardActions disableSpacing>
                                <LoadingButton
                                  loading={driveItemsLoading || syncing}
                                  onClick={() => startSync(drive)}
                                >
                                  {t("Sync now")}
                                </LoadingButton>

                                <ConnectedSharePointDriveDialog
                                  mode={DialogMode.Delete}
                                  refetch={refetchConnectedDrives}
                                  connectedDrive={drive}
                                />
                              </CardActions>
                            </Card>
                          </Grid>
                        )
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default ConnectedDrives;
