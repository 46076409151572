import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";

import {
  Avatar as MuiAvatar,
  Badge,
  Box,
  Button,
  IconButton,
  List,
  ListItemAvatar,
  ListItemText,
  Popover as MuiPopover,
  SvgIcon,
  Tooltip,
  Typography,
  ListItemButton,
} from "@mui/material";
import { Bell, Home, UserPlus, Server } from "react-feather";
import { Alarm } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import {
  useGetNotificationsQuery,
  useGetWebPubSubQuery,
  useUpdateNotificationMutation,
} from "../../redux/slices/indexApiSlice";
import { INotification } from "../../types/notification";
import useAuth from "../../hooks/useAuth";
import {
  convertCreatedDate,
  convertNotificationDate,
} from "../../utils/string";
import { WebPubSubClient } from "@azure/web-pubsub-client";
import useAppSelector from "../../hooks/useAppSelector";
import { isUserUnReadNotification } from "../../utils/userReadyNotification";

const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 300px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: ${(props) => props.theme.header.indicator.background};
    color: ${(props) => props.theme.palette.common.white};
  }
`;

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;

const NotificationHeader = styled(Box)`
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`;

interface INotificationProps {
  notification: INotification;
  Icon: React.ElementType;
  hasBeenRead: boolean;
  onRead: (notification: INotification) => void;
}

function Notification({
  notification,
  Icon,
  hasBeenRead,
  onRead,
}: INotificationProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isRead, setIsRead] = useState(hasBeenRead);
  const [updateNotification, { isLoading: isUpdating, isSuccess: isUpdated }] =
    useUpdateNotificationMutation();

  const getNavigationUrl = () => {
    switch (notification.type) {
      case "contractExpiry": {
        return `/contract/${notification.contractId}/viewOnly`;
      }
      default: {
        return "#";
      }
    }
  };

  const handleReadNotification = async (e: any) => {
    navigate(getNavigationUrl());

    await updateNotification(notification).then(() => {
      setIsRead(true);
      onRead(notification);
    });
  };

  return (
    <ListItemButton
      divider
      onClick={handleReadNotification}
      alignItems="flex-start"
    >
      <ListItemAvatar>
        <Avatar
          sx={{
            background: "unset !important",
            color: (theme) => theme.palette.primary.main,
          }}
        >
          <SvgIcon fontSize="large">
            <Icon />
          </SvgIcon>
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        // primary={notification.type === "contractExpiry" ? t("Contract Expiry") : ""}
        primaryTypographyProps={{
          variant: "subtitle2",
          color: isRead ? "textSecondary" : "textPrimary",
          sx: { fontWeight: isRead ? "regular" : "bold" },
        }}
        secondary={
          <>
            {notification?.type === "contractExpiry" && (
              <Box>{convertNotificationDate(notification)}</Box>
            )}
            {notification?.type === "note" && <Box>{notification.text}</Box>}
            <Typography color="textSecondary" variant="body2" pt={2}>
              {convertCreatedDate(notification.insertionTime)}
            </Typography>
          </>
        }
        secondaryTypographyProps={{
          color: "textPrimary", //isRead ? "textSecondary" : "textPrimary",
          sx: { fontWeight: isRead ? "regular" : "bold" },
        }}
      />
    </ListItemButton>
  );
}

function NavbarNotificationsDropdown() {
  const { t } = useTranslation();
  const tenantId = useAppSelector((state) => state.auth.tenantId);
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const { isAuthenticated, user } = useAuth();

  // only get the notifications if the user is logged in
  const {
    data: notifications = [],
    isSuccess,
    refetch,
  } = useGetNotificationsQuery(isAuthenticated, {
    skip: !isAuthenticated || !user?.id,
  });

  const {
    data: dataPubSub,
    isSuccess: isSuccessPubSub,
    refetch: refetchPubSub,
  } = useGetWebPubSubQuery(isAuthenticated, {
    skip: !isAuthenticated || !user?.id,
  });

  const [unread, setUnread] = useState<INotification[]>([]);
  const [notificationList, setNotificationList] = useState<INotification[]>([]);

  useEffect(() => {
    if (isSuccess) {
      setNotificationList(notifications);
    }
  }, [notifications, isSuccess]);

  useEffect(() => {
    setUnread(
      notificationList.filter((n: INotification) =>
        isUserUnReadNotification(n, user.id)
      )
    );
  }, [notificationList]);

  useEffect(() => {
    if (isAuthenticated) {
      refetchPubSub();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isSuccessPubSub && dataPubSub && tenantId) {
      connect(dataPubSub.token);
    }
  }, [isSuccessPubSub, tenantId]);

  const handleOpen = () => {
    if (isAuthenticated) {
      refetch();
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onRead = (notification: INotification) => {
    setOpen(false);
    setUnread(
      notificationList.filter(
        (n: INotification) =>
          isUserUnReadNotification(n, user.id) && n.id != notification.id
      )
    );
  };

  async function connect(dataPubSub: string) {
    const client = new WebPubSubClient({
      getClientAccessUrl: async () => dataPubSub,
    });
    client.on("group-message", (e: any) => {
      // console.log("notifi socket", e);
      const res = e.message?.data;
      if (res.type == "notification") {
        appendMessage(res);
      } else if (res.type === "note") {
        appendMessage(res);
      }
    });
    await client.start();
    let group = `contract-tenant-${tenantId}`;
    await client.joinGroup(group);
  }

  function appendMessage(res: any) {
    const notification: INotification = {
      id: res.id,
      text: res.title || res.body,
      readBy: res.readBy,
      insertionTime: res._tsc,
      type: res.type,
      contractId: res.contractId,
      contractName: res.contractName,
      endDate: new Date(res.endDate).toISOString(),
      taggedUsers: res.taggedUsers,
    };
    if (notification.taggedUsers?.some((id) => id == "all" || id == user?.id)) {
      setNotificationList((current) => [notification, ...current]);
    }
  }

  return (
    <React.Fragment>
      <Tooltip
        title={t("Notifications")}
        sx={{ display: isAuthenticated ? "" : "none" }}
      >
        <IconButton color="inherit" ref={ref} onClick={handleOpen} size="large">
          <Indicator badgeContent={unread?.length}>
            <Bell />
          </Indicator>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <NotificationHeader p={2}>
          <Typography variant="subtitle1" color="textPrimary">
            {unread?.length > 0
              ? `${unread?.length} ${
                  unread.length === 1
                    ? t("New notification")
                    : t("New notifications")
                }`
              : t("No new notifications")}
          </Typography>
        </NotificationHeader>
        <React.Fragment>
          <List disablePadding>
            {notificationList?.map((notification: INotification) => (
              <Notification
                key={notification.id}
                notification={notification}
                Icon={Alarm}
                onRead={onRead}
                hasBeenRead={!isUserUnReadNotification(notification, user.id)}
              />
            ))}
          </List>
          <Box p={1} display="flex" justifyContent="center">
            <Button
              size="small"
              component={Link}
              to="/notifications"
              onClick={handleClose}
            >
              {t("Show all notifications")}
            </Button>
          </Box>
        </React.Fragment>
      </Popover>
    </React.Fragment>
  );
}

export default NavbarNotificationsDropdown;
