// ** React Imports
import { Document, Page } from "react-pdf";
import React, { ReactEventHandler, useEffect, useRef, useState } from "react";

// https://www.npmjs.com/package/react-pdf
import { pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

// ** MUI Imports
import {
  Alert,
  AlertTitle,
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  CssBaseline,
  Dialog,
  Divider,
  Fade,
  Grid,
  Grow,
  Icon,
  IconButton,
  InputBase,
  LinearProgress,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Pagination as MuiPagination,
  Paper,
  ScopedCssBaseline,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
  paperClasses,
  styled,
  useScrollTrigger,
  useTheme,
} from "@mui/material";
import { alpha, lighten, spacing } from "@mui/system";

import {
  Delete as DeleteIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  Verified,
  MoreVert as MoreVertIcon,
  Cloud,
  Css,
  KeyboardArrowRight,
  KeyboardArrowLeft,
} from "@mui/icons-material";
import { saveAs } from "file-saver";
import {
  ChevronLeft,
  ChevronRight,
  Download,
  Edit,
  ZoomIn,
  ZoomOut,
} from "react-feather";
import { useTranslation } from "react-i18next";

import Draggable from "react-draggable";
import useAuth from "../../hooks/useAuth";
import { IDocument } from "../../types/document";
import { IDriveItem } from "../../types/driveItem";
import { ISignature, ISignee } from "../../types/signature";
import AIDocumentSignature from "../../pages/main/contracts/components/AIDocumentSignature";
import PDFViewer from "./PDFViewer";
import PNGViewer from "./PNGViewer";
import DriveItemViewer from "./DriveItemViewer";
import { useLazyGetDocumentContentQuery } from "../../redux/slices/indexApiSlice";
import { doc } from "prettier";
import OutlookAttachmentViewer from "./OutlookAttachmentViewer";
import { IOutlookMessageAttachment } from "../../types/outlookattachment";
import StyledMenu from "../../theme/StyledMenu";
import { set } from "date-fns";
import { useDispatch } from "react-redux";
import {
  ADD_FILE,
  FileState,
  addFile,
  fileExists,
  selectFile,
} from "../../redux/slices/filesSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import ReactPDFViewer from "./ReactPDFViewer";
import SearchIcon from "@mui/icons-material/Search";
import { ProgressIndicator } from "@fluentui/react";
import {
  getFileTypeIconProps,
  initializeFileTypeIcons,
} from "@fluentui/react-file-type-icons";
import { initialize } from "../auth/firebaseAuth";
import FluentUIFileTypeIcon from "../../icons/FluentUIFileTypeIcon";

const Pagination = styled(MuiPagination)(spacing);

function WordViewer({ ...props }) {
  const googleDocsViewerUrl = `https://docs.google.com/gview?url=${encodeURIComponent(
    props.fileUrl
  )}&embedded=true`;

  return (
    <div>
      <iframe
        className="word-document"
        title="Word Document Preview"
        width="100%"
        height="600"
        frameBorder="0"
        src={googleDocsViewerUrl}
      ></iframe>
    </div>
  );
}

const MemoizedDocument = React.memo(Document);
const MemoizedPage = React.memo(Page);

const elevation2 =
  "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12) !important";
const elevation3 =
  "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12) !important";
const elevation4 =
  "rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px !important";

const StyledGridContainer = styled(Grid)`
  // background-color: ${(props) => (props.theme as any).sidebar.background};
`;

type MenuItemOption = {
  key: string;
  onClick?: () => void;
  icon: JSX.Element;
  label: string;
};

type IMenuComponentProps = {
  label: string;
  options: MenuItemOption[];
};

const MenuComponent = (props: IMenuComponentProps) => {
  const { label, options } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOnClick = (e: any, option: MenuItemOption) => {
    e.stopPropagation();

    if (option.onClick) {
      option.onClick();
    }

    handleCloseMenu();
  };

  return (
    <div>
      <Button
        onClick={handleOpenMenu}
        endIcon={<KeyboardArrowDownIcon />}
        variant="text"
        sx={{
          ":hover": {
            background: "transparent",
          },
        }}
      >
        {label}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {/* {options.map((option: JSX.Element, index: number) => {
          return option;
        })} */}
        {options.map((option) => {
          return (
            <MenuItem
              key={option.key}
              onClick={(e: any) => {
                handleOnClick(e, option);
              }}
              disableRipple
            >
              <ListItemIcon>{option.icon}</ListItemIcon>
              <ListItemText>{option.label}</ListItemText>
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

function ElevationScroll(props: any) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export interface DocumentViewerProps {
  document?: IDocument;
  driveItem?: IDriveItem;
  outlookAttachment?: IOutlookMessageAttachment;
  file?: File;
  fileUrl?: string;
  // arrayBuffer?: ArrayBuffer;
  height?: string;
  deleteDocument?: () => void;
  setAIResponse?: (aiResponse: any) => void;
  setIsProcessing?: (isProcessing: boolean) => void;
  hideFileMenu?: boolean;
  selectedVersionId?: string;
  hasMoreVersions?: boolean;
  startAI?: boolean;
  viewMode?: "withMenu" | "pageOnly";
  signable?: boolean;
  signer?: ISignee;
  signatures?: ISignature[];
  onSaveSignature?: () => any;
  setSignatures?: React.Dispatch<React.SetStateAction<ISignature[]>>;
  setSignatureSizePercentValue?: React.Dispatch<
    React.SetStateAction<{ width: number; height: number }>
  >;
  toggleSignaturePad?: boolean;
  onSuccess?: () => void;
  paging?: boolean;
  disableAppBar?: boolean;
  disableScroller?: boolean;
  searchForText?: string;
}

// Use the DocumentViewer to view files that are stored in Azure Blob Storage
// Digital Signatures are supported
function DocumentViewer({ ...props }: DocumentViewerProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    document,
    driveItem,
    outlookAttachment,
    file,
    fileUrl,
    height,
    onSuccess,
  } = props;

  const [ready, setReady] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [searchResultIndex, setSearchResultIndex] = useState(0);
  const [searchResults, setSearchResults] = useState<number>(0);

  const [
    getDocumentContent,
    { isLoading, isFetching, status, currentData, data },
  ] = useLazyGetDocumentContentQuery();

  // check if the file is already in the store
  const fileContentExists = useSelector<RootState, boolean | undefined>(
    (state) =>
      props.document ? fileExists(props.document.id || "")(state) : undefined
  );

  const fileBuffer = useSelector<RootState, ArrayBuffer | undefined>((state) =>
    props.document ? selectFile(props.document.id || "")(state) : undefined
  );

  useEffect(() => {
    if (document?.id && document.location !== "sharepoint") {
      // the id changed, clear the array buffer
      // setArrayBuffer(null);

      if (!fileContentExists) {
        // add the file content to the store
        getDocumentContent(document).then((res) => {
          if (res.isSuccess) {
            const buffer = res.data as ArrayBuffer;
            const id = res.originalArgs.id;
            // setArrayBuffer(buffer);
            if (id) {
              dispatch(addFile({ id, buffer }));
            }
          }
        });
      }
    }
  }, [document?.id]);

  useEffect(() => {
    setReady(false);
  }, [document?.id]);

  useEffect(() => {
    if (props.searchForText) {
      // goToSection(props.searchForText);
      setSearchText(props.searchForText);
      console.log("searching for text", props.searchForText);
    }
  }, [props.searchForText]);

  const mimeType = "application/pdf"; // MIME type of the file
  const fileName =
    props.document?.name || props.driveItem?.name || props.file?.name;

  const extension = fileName
    ? fileName?.substring(fileName?.lastIndexOf(".") + 1)
    : props.fileUrl
    ? props.fileUrl?.substring(props.fileUrl?.lastIndexOf(".") + 1)
    : null;

  const downloadDocument = () => {
    if (props.file) {
      const blob = new Blob([props.file], { type: mimeType });
      saveAs(blob, props.file.name);
    } else if (props.document?.id && fileBuffer) {
      // convert the arraybuffer to a blob and download it
      const blob = new Blob([fileBuffer], { type: mimeType });
      saveAs(blob, fileName || "download.pdf");
    }
  };

  const done = () => {
    setReady(true);

    onSuccess && onSuccess();
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const prevSearchResult = () => {
    // find the next search result
    console.log("searching for", searchText);

    if (searchResultIndex > 0) {
      setSearchResultIndex(searchResultIndex - 1);
    }
  };

  const nextSearchResult = () => {
    // find the next search result
    console.log("searching for", searchText);

    setSearchResultIndex(searchResultIndex + 1);
  };

  return (
    <Box
      component={props.disableAppBar ? Paper : Card}
      variant={props.disableAppBar ? "elevation" : "outlined"}
      sx={{
        boxShadow: "none",
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
        height: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {!props.disableAppBar && (
        <AppBar
          position="relative"
          color="primary"
          sx={{
            // backgroundColor: (theme) => lighten(theme.palette.primary.main, 0.3),
            zIndex: (theme) => theme.zIndex.appBar,
            boxShadow: elevation4,
            // zIndex: (theme) => theme.zIndex.modal - 1,
            // borderTopLeftRadius: 32,
            // borderTopRightRadius: 32,
            display: "flex",
          }}
        >
          <Toolbar color="transparent">
            <Stack justifyContent="space-between" direction="row" width="100%">
              <Stack>
                <ListItem
                // secondaryAction={
                //   <IconButton
                //     color="inherit"
                //     onClick={downloadDocument}
                //     title={t("Download")!}
                //   >
                //     <Download />
                //   </IconButton>
                // }
                >
                  <ListItemIcon>
                    <FluentUIFileTypeIcon filename={fileName || ""} />
                  </ListItemIcon>
                  <ListItemText sx={{ pr: 3 }}>{fileName}</ListItemText>
                </ListItem>
              </Stack>

              <Stack justifyContent="center" pr={12}>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Stack>
                    <Search>
                      <SearchIconWrapper>
                        <SearchIcon />
                      </SearchIconWrapper>
                      <StyledInputBase
                        placeholder={t("Search")!}
                        inputProps={{ "aria-label": "search" }}
                        // onChange={(e: any) => {
                        //   // if enter is pressed
                        //   if (e.key === "Enter") {
                        //     setSearchText(e.target.value);
                        //   }
                        // }}
                        onKeyDown={(e) => {
                          // if enter is pressed
                          if (
                            e.key === "Enter" &&
                            e.currentTarget.value?.length >= 3
                          ) {
                            if (searchText === e.currentTarget.value) {
                              // Enter was pressed again, go to the next result
                              nextSearchResult();
                            } else {
                              setSearchResultIndex(0);
                              setSearchText(e.currentTarget.value);
                            }
                          }
                        }}
                      />
                    </Search>
                  </Stack>
                  {/* 
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography variant="body2" px={2}>
                      {searchResultIndex + 1}
                      of {searchResults}
                    </Typography>

                    <IconButton
                      color="inherit"
                      onClick={prevSearchResult}
                      size="small"
                    >
                      <KeyboardArrowLeft />
                    </IconButton>

                    <IconButton
                      color="inherit"
                      onClick={nextSearchResult}
                      size="small"
                    >
                      <KeyboardArrowRight />
                    </IconButton>
                  </Stack> */}
                </Stack>
              </Stack>
            </Stack>
          </Toolbar>

          <LinearProgress variant="determinate" value={loadingProgress * 100} />
        </AppBar>
      )}

      <Container disableGutters sx={{ overflow: "hidden" }}>
        <Box
          sx={{
            py: 3,
            // maxHeight: props.disableScroller ? "100%" : "60vh",
            overflow: "auto",
            height: "100%",
          }}
        >
          {/* {props.viewMode !== "pageOnly" && (
        <Grid item>
          <StyledGridContainer container justifyContent="space-between">
            <Grid item></Grid>
            <Grid item>
              <Grid container gap={0} justifyContent="space-between">
                {/* <Grid item>
                <IconButton type="button" color="primary" onClick={prevPage} disabled={pageNumber <= 1}>
                  <ArrowCircleUp />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton type="button" color="primary" onClick={nextPage} disabled={pageNumber >= numPages}>
                  <ArrowCircleDown />
                </IconButton>
              </Grid> */}
          {/* <Grid item>
                  <Tooltip title={`Zoom out`}>
                    <StyledIconButton
                      type="button"
                      onClick={decreaseScale}
                      disabled={scale <= minScale}
                    >
                      <ZoomOut />
                    </StyledIconButton>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title={`Zoom in`}>
                    <StyledIconButton
                      type="button"
                      onClick={increaseScale}
                      disabled={scale >= maxScale}
                    >
                      <ZoomIn />
                    </StyledIconButton>
                  </Tooltip>
                </Grid> 

                <Grid item>
                  <IconButton
                    aria-label="more"
                    id={`document-viewer-menubutton-view-more`}
                    aria-controls={open ? `document-viewer-menu` : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                    sx={{
                      visibility: "visible",
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <StyledMenu
                    id={`document-viewer-menu`}
                    MenuListProps={{
                      "aria-labelledby": `document-viewer-menubutton-view-more`,
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                    <MenuItem
                      key="menu-item-download-document"
                      onClick={downloadDocument}
                    >
                      <Download style={{ marginRight: 2 }} /> {t("Download")}
                    </MenuItem>
                  </StyledMenu>
                </Grid>
              </Grid>
            </Grid>
          </StyledGridContainer>
        </Grid>
      )}

      {props.viewMode !== "pageOnly" && (
        <Grid item>
          <Divider />
        </Grid>
      )} */}

          {document?.id && !fileContentExists ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box>
              {outlookAttachment ? (
                <OutlookAttachmentViewer
                  outlookAttachment={outlookAttachment}
                />
              ) : document?.location === "sharepoint" ||
                (!document?.id && document?.driveItemId) ? ( // for drive items that have not been imported yet
                <DriveItemViewer
                  {...props}
                  document={document}
                  onSuccess={done}
                />
              ) : extension === "pdf" ||
                document?.origin === "sharepoint" ||
                document?.origin === "onedrive" ||
                document?.origin === "teams" ||
                document?.origin === "outlook" ||
                driveItem?.parentReference.driveId ? ( // drive items can be converted to pdf, so use the pdf viewer
                <>
                  <PDFViewer // ReactPDFViewer
                    {...props}
                    // arrayBuffer={arrayBuffer}
                    onSuccess={done}
                    setProgress={setLoadingProgress}
                    searchForText={searchText}
                    searchResultIndex={searchResultIndex}
                    setSearchResults={setSearchResults}
                  />
                  {/* <ReactPDFViewer // ReactPDFViewer
              {...props}
              // arrayBuffer={arrayBuffer}
              defaultScale={scale}
              onSuccess={done}
            /> */}
                </>
              ) : extension === "docx" ? (
                <WordViewer
                  {...props}
                  arrayBuffer={undefined}
                  onSuccess={done}
                />
              ) : extension === "png" ? (
                <PNGViewer filename={fileName} arrayBuffer={undefined} />
              ) : null}
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
}

export default DocumentViewer;
// export default DocumentViewer;
