import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  useGetUserPhotoForUserMutation,
  useGetUserProfileForUserQuery,
} from "../../redux/slices/graphApiSlice";
import {
  Box,
  Avatar,
  Typography,
  Grid,
  Card as MuiCard,
  CardMedia as MuiCardMedia,
  CardContent,
  CardActionArea,
} from "@mui/material";
import { useGetVendorQuery } from "../../redux/slices/vendorsApiSlice";
import { spacing, SpacingProps } from "@mui/system";
import { PinDrop } from "@mui/icons-material";
import { PhoneCall } from "react-feather";
import CustomNoRowsOverlay from "../datagrids/CustomNoRowsOverlay";
import { useTranslation } from "react-i18next";
import { IVendor } from "../../types/vendor";

const AboutIcon = styled.span`
  display: flex;
  padding-right: ${(props) => props.theme.spacing(2)};

  svg {
    width: 14px;
    height: 14px;
  }
`;

const Card = styled(MuiCard)(spacing);

const CardMedia = styled(MuiCardMedia)`
  height: 120px;
`;

const SquareAvatar = styled(Avatar)`
  border-radius: 0;

  & .MuiAvatar-img {
    object-fit: contain;
  }
`;

// Specify the type of data
interface QueryResponse {
  data: IVendor; // Here, CompanyProfile is the type of your company profile object
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  error: any; // Change `any` to the specific error type if available
  refetch: () => void; // Define the type of the refetch function
}

function VendorAvatar({
  ...props
}: {
  vendorId?: string;
  size?: number;
  big?: boolean;
  small?: boolean;
  refetch?: boolean;
  handleCardClick?: () => void;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    data: vendor,
    isLoading,
    isSuccess,
    refetch: getVendor,
  } = useGetVendorQuery(props.vendorId ?? "", { skip: !props.vendorId });

  useEffect(() => {
    if (props.vendorId || props.refetch) {
      getVendor();
    }
  }, [props.vendorId, props.refetch]);

  if (!props.vendorId && props.small) {
    return <span />;
  }

  const handleOnClick = () => {
    if (props.handleCardClick) {
      //   setSelected(!isSelected);
      props.handleCardClick();
    } else if (vendor?.id) {
      /// navigate to a new window
      navigate(`/vendor/${vendor?.id}`);
      // window.open(`/vendors/${vendor?.id}`, "_blank");
    }
  };

  return props.big ? (
    <Card onClick={handleOnClick}>
      <CardActionArea onClick={handleOnClick}>
        {!vendor ? (
          <CustomNoRowsOverlay label={t("No vendor")} />
        ) : (
          <>
            {vendor?.logo && (
              <CardMedia
                sx={{ backgroundSize: "50%", backgroundPosition: "center" }}
                title={vendor?.name}
                image={vendor?.logo}
              />
            )}

            <CardContent sx={{ paddingBottom: "0 !important" }}>
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {vendor?.name}
              </Typography>

              {(vendor?.street || vendor?.city) && (
                <Grid container direction="row" alignItems="center" mb={2}>
                  <Grid item alignItems="start">
                    <AboutIcon>
                      <PinDrop />
                    </AboutIcon>
                  </Grid>
                  <Grid item>
                    {vendor.street} {vendor.houseNumber} {vendor.city}{" "}
                    {vendor.postalCode} {vendor.country}
                  </Grid>
                </Grid>
              )}

              {vendor?.phone && (
                <Grid container direction="row" alignItems="center" mb={2}>
                  <Grid item>
                    <AboutIcon>
                      <PhoneCall />
                    </AboutIcon>
                  </Grid>
                  <Grid item>{vendor.phone}</Grid>
                </Grid>
              )}
            </CardContent>
          </>
        )}
      </CardActionArea>
    </Card>
  ) : props.small ? (
    <Grid
      container
      direction="row"
      alignItems="center"
      gap={2}
      flexWrap="nowrap"
    >
      {vendor?.logo && (
        <Grid item display="flex">
          <SquareAvatar
            sx={{ display: "inline-block" }}
            alt={vendor?.name}
            title={vendor?.name}
            src={vendor?.logo}
          />
        </Grid>
      )}

      <Grid item sx={{ overflow: "hidden" }}>
        <Typography
          variant="body2"
          title={vendor?.name}
          sx={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {vendor?.name}
        </Typography>
      </Grid>
    </Grid>
  ) : (
    <SquareAvatar
      sx={{ display: "inline-block", width: props.size, height: props.size }}
      alt={vendor?.name}
      title={vendor?.name}
      src={vendor?.logo}
    />
  );
}

export default VendorAvatar;
