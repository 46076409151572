import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Avatar,
  Alert as MuiAlert,
  Box,
  Button as MuiButton,
  Grid,
  IconButton,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Paper as MuiPaper,
  TextField as MuiTextField,
  Typography as MuiTypography,
  ImageList,
  ImageListItem,
  Tab,
  Tabs,
  ListItemButton,
  Card as MuiCard,
  CardActions,
  CardContent as MuiCardContent,
  CardMedia as MuiCardMedia,
  Chip as MuiChip,
  Divider as MuiDivider,
} from "@mui/material";
import { AvatarGroup as MuiAvatarGroup } from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";

import {
  useAddMessageMutation,
  useGetMessagesQuery,
} from "../../../redux/slices/indexApiSlice";

import { spacing, SpacingProps } from "@mui/system";
import { DialogMode } from "../../../types/dialogmode";
const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);
const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface TypographyProps extends SpacingProps {
  component?: string;
}
const Typography = styled(MuiTypography)<TypographyProps>(spacing);

const CardContent = styled(MuiCardContent)`
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
`;

const CardMedia = styled(MuiCardMedia)`
  height: 220px;
`;

const Chip = styled(MuiChip)<{ color?: string }>`
  height: 20px;
  padding: 4px 0;
  font-size: 85%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
  margin-bottom: ${(props) => props.theme.spacing(4)};
`;

const AvatarGroup = styled(MuiAvatarGroup)`
  margin-left: ${(props) => props.theme.spacing(2)};
`;

type ContractTemplateProps = {
  image?: string;
  title: string;
  description: string;
  chip: JSX.Element;
};
const ContractTemplate: React.FC<ContractTemplateProps> = ({
  image,
  title,
  description,
  chip,
}) => {
  return (
    <Card>
      {image ? <CardMedia image={image} title="Contemplative Reptile" /> : null}
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {title}
        </Typography>

        {chip}

        <Typography mb={4} color="textSecondary" component="p">
          {description}
        </Typography>

        <AvatarGroup max={3}>
          <Avatar alt="Avatar" src="/static/img/avatars/avatar-1.jpg" />
          <Avatar alt="Avatar" src="/static/img/avatars/avatar-2.jpg" />
          <Avatar alt="Avatar" src="/static/img/avatars/avatar-3.jpg" />
        </AvatarGroup>
      </CardContent>
      <CardActions>
        <Button size="small" color="primary">
          Share
        </Button>
        <Button size="small" color="primary">
          Learn More
        </Button>
      </CardActions>
    </Card>
  );
};

function TemplatesList() {
  const [addMessage, { isLoading: isAdding, isSuccess: isAdded }] =
    useAddMessageMutation();
  const {
    data: messages,
    isLoading: messagesLoading,
    refetch,
  } = useGetMessagesQuery();

  useEffect(() => {
    if (isAdded) {
      // re-get the message items if the add or delete mutation was successful
      refetch();
    }
  }, [isAdded]);

  return (
    <React.Fragment>
      <Helmet title="Vendors" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Contract Template
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/libraries">
              Libraries
            </Link>
            <Typography>Contract Template</Typography>
          </Breadcrumbs>
        </Grid>
        {/* <Grid item>
          <MessageDialog mode={DialogMode.Add} refetch={refetch} />
        </Grid> */}
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} lg={6} xl={3}>
          <ContractTemplate
            title="Fix form validation"
            description="Nam pretium turpis et arcu. Duis arcu tortor, suscipit eget, imperdiet nec, imperdiet iaculis, ipsum. Sed aliquam ultrices mauris."
            chip={<Chip label="In progress" color="warning" />}
            image="/static/img/unsplash/unsplash-1.jpg"
          />
        </Grid>
        <Grid item xs={12} lg={6} xl={3}>
          <ContractTemplate
            title="New company logo"
            description="Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum."
            chip={<Chip label="On hold" color="error" />}
            image="/static/img/unsplash/unsplash-2.jpg"
          />
        </Grid>
        <Grid item xs={12} lg={6} xl={3}>
          <ContractTemplate
            title="Upgrade to latest Maps API"
            description="Nam pretium turpis et arcu. Duis arcu tortor, suscipit eget, imperdiet nec, imperdiet iaculis, ipsum. Sed aliquam ultrices mauris."
            chip={<Chip label="Finished" color="success" />}
            image="/static/img/unsplash/unsplash-3.jpg"
          />
        </Grid>
        <Grid item xs={12} lg={6} xl={3}>
          <ContractTemplate
            title="Refactor backend templates"
            description="Curabitur ligula sapien, tincidunt non, euismod vitae, posuere imperdiet, leo. Maecenas malesuada. Praesent congue erat at massa."
            chip={<Chip label="On hold" color="error" />}
            image="/static/img/unsplash/unsplash-4.jpg"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default TemplatesList;
