import { Grid } from "@mui/material";
import { DialogMode } from "../../../types/dialogmode";
import { IVendor } from "../../../types/vendor";
import VendorMetadata from "./components/VendorMetadata";
import { Box } from "@mui/system";
import VendorDetailSidebar from "./components/VendorDetailSidebar";

type Props = {
  mode: DialogMode;
  vendor: IVendor;
  refetch: any;
  setErrors: React.Dispatch<React.SetStateAction<any[]>>;
};

const VendorInformation: React.FC<Props> = ({
  mode,
  vendor,
  refetch,
  setErrors,
}) => {
  return (
    <Grid container justifyContent="space-between" flexDirection="row">
      <Grid item xs={12} md={7} mb={6}>
        {mode === DialogMode.ViewOnly && vendor && (
          <Box>
            {/* <ContractMetadata contract={initialContract} /> */}
            <VendorMetadata vendor={vendor} />
          </Box>
        )}
        {mode === DialogMode.Edit && (
          <Box>
            {/* <ContractAnalyzeForm
                  initialValues={initialContract}
                  setFormValues={setContract}
                /> */}
            More details
          </Box>
        )}
      </Grid>
      <Grid item xs={12} md={4}>
        {vendor && (
          <Box flex={1}>
            <VendorDetailSidebar
              mode={mode}
              vendor={vendor}
              setErrors={setErrors}
              refetch={refetch}
            />
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default VendorInformation;
