import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Alert as MuiAlert,
  AvatarGroup,
  Box,
  Button as MuiButton,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  Grid,
  IconButton,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  TextField as MuiTextField,
  Typography,
  ImageList,
  ImageListItem,
  Tab,
  Tabs,
  ListItemButton,
} from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { spacing } from "@mui/system";

import DataGridVendors from "../../../components/datagrids/DataGridVendors";

import { useTranslation } from "react-i18next";
import { DialogMode } from "../../../types/dialogmode";

import AskMeAnything from "../../components/AskMeAnything";
import InitiateVendorsDialog from "../../../components/dialogs/InitiateVendorsDialog";

const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);
const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

function VendorList() {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <React.Fragment>
      <Helmet title={t("Vendors")!} />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/vendors" color="inherit">
              <Typography variant="h3" gutterBottom display="inline">
                {t("Vendors")}
              </Typography>
            </Link>
          </Breadcrumbs>
        </Grid>

        <Grid item xs={12} md={4}>
          <AskMeAnything placeholder={"Ask a question about your vendors"} />
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container direction="column" gap={6}>
        <Grid item>
          <Grid container gap={3}>
            <Grid item>
              <InitiateVendorsDialog mode={DialogMode.Add} />
              {/* <Grid justifyContent="space-between" container spacing={4}>
                <Grid item>
                  <VendorDialog
                    mode={DialogMode.Add}
                    refetch={() => setRefetch(true)}
                  />
                </Grid>
                <Grid item>
                  <VendorUploadFileDialog onSuccess={() => setRefetch(true)} />
                </Grid>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <DataGridVendors />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default VendorList;
