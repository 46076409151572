import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { blue, grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  CircularProgress,
  Fade,
  Grid,
  Icon,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  StepButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import DocumentViewer from "./DocumentViewer";
import NoFilesFoundIcon from "../../icons/NoFilesFoundIcon";
import { DialogMode } from "../../types/dialogmode";

import FluentUIFileTypeIcon from "../../icons/FluentUIFileTypeIcon";
import PDFViewer from "./PDFViewer";
import { IDriveItem } from "../../types/driveItem";
import DriveItemViewer from "./DriveItemViewer";
import { IDocument } from "../../types/document";
import { IOutlookMessageAttachment } from "../../types/outlookattachment";

const UploadButton = styled(MuiPaper)`
  height: 144px;
  border: 1px dashed ${(props) => props.theme.palette.divider};
  padding: 24px;
  cursor: pointer;
  &:hover {
    border: 1px dashed ${(props) => props.theme.palette.primary.main};
  }
`;

interface IFileToUploadViewerProps {
  mode: DialogMode;
  file?: File;
  document?: IDocument;
  outlookAttachment?: IOutlookMessageAttachment;
}
function FileToUploadViewer({ ...props }: IFileToUploadViewerProps) {
  const { mode, file, document, outlookAttachment } = props;

  const { t } = useTranslation();
  const theme = useTheme();
  const isDownLg = useMediaQuery(theme.breakpoints.down("lg"));
  const [documentViewerReady, setDocumentViewerReady] = useState(false);

  return (
    <Box>
      {mode === DialogMode.Add && (
        <Box
          sx={{
            display: "flex",
            flexDirection: isDownLg ? "column-reverse" : "row",
          }}
        >
          <Box mr={8} width="100%">
            <DocumentViewer
              file={file}
              onSuccess={() => setDocumentViewerReady(true)}
              signatures={[]}
              document={document}
              outlookAttachment={outlookAttachment}
              hideFileMenu
              viewMode="pageOnly"
              disableAppBar
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default FileToUploadViewer;
