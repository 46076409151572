import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { blue, grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  CircularProgress,
  Fade,
  Grid,
  Icon,
  LinearProgress,
  Link,
  List,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Pagination,
  StepButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { DialogMode } from "../../types/dialogmode";
import FileAsListItem from "./listitems/FileAsListItem";
import FileToUploadViewer from "../viewers/FileToUploadViewer";
import DriveItemViewer from "../viewers/DriveItemViewer";
import { IDocument } from "../../types/document";
import DocumentAsListItem from "./listitems/DocumentAsListItem";
import { IOutlookMessageAttachment } from "../../types/outlookattachment";

interface IFilesToUploadListProps {
  mode: DialogMode;
  documents?: IDocument[];
  filesWithProgress?: { file: File; progress: number; document?: IDocument }[];
  handleDeleteFileToUpload?: (index: number) => void;
  handleDeleteDocument?: (item: IDocument) => void;
  uploadsProgress?: { filename: string; progress: number }[];
}
function FilesToUploadList({ ...props }: IFilesToUploadListProps) {
  const {
    mode,
    documents,
    filesWithProgress,
    handleDeleteFileToUpload,
    handleDeleteDocument,
    uploadsProgress,
  } = props;

  const { t } = useTranslation();
  const theme = useTheme();

  // items per page should be as many as possible, depending on the window height

  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(itemsPerPage);

  const [files, setFiles] = useState<
    (IDocument | { file: File; progress: number })[]
  >([]);
  const [oneFileSelected, setOneFileSelected] = useState<
    IDocument | File | null
  >(null);

  useEffect(() => {
    if (!documents || documents?.length === 0) {
      const allFilesWithProgress = filesWithProgress?.map((f) => f.file) || [];

      if (allFilesWithProgress?.length === 1) {
        setOneFileSelected(allFilesWithProgress[0]);
      } else {
        setOneFileSelected(null);
      }
    } else if (documents?.length === 1) {
      const allFilesWithProgress = filesWithProgress?.map((f) => f.file) || [];

      if (allFilesWithProgress?.length === 0) {
        setOneFileSelected(documents[0]);
      } else {
        setOneFileSelected(null);
      }
    }
  }, [documents, filesWithProgress]);

  useEffect(() => {
    const newFiles = [...(documents || []), ...(filesWithProgress || [])];
    setFiles(newFiles);
  }, [filesWithProgress, documents]);

  useEffect(() => {
    if (files?.length > 0) {
      setNumPages(Math.ceil(files.length / itemsPerPage));
    }
  }, [files, itemsPerPage]);

  // if the user deletes the last file on the last page, we need to go back one page
  useEffect(() => {
    if (pageNumber > numPages) {
      setPageNumber(numPages);
      setMin((numPages - 1) * itemsPerPage);
      setMax(numPages * itemsPerPage);
    }
  }, [pageNumber, numPages]);

  return (
    <Box>
      <List dense>
        {filesWithProgress?.map((f, i) => {
          if (i > max) return null;
          if (i < min) return null;

          return (
            <FileAsListItem
              key={`FileAsListItem-${f.file?.name}-${i}`}
              onClick={() => console.log("file button clicked")}
              file={f.file}
              onDelete={() =>
                mode !== DialogMode.ViewOnly &&
                handleDeleteFileToUpload &&
                handleDeleteFileToUpload(i)
              }
              uploading={f.progress > 0}
              uploadPercentProgress={f.progress}
              uploaded={f.progress === 100}
            />
          );
        })}

        {documents?.map((document, i) => {
          const newI = filesWithProgress ? i + filesWithProgress?.length : i;
          if (newI > max) return null;
          if (newI < min) return null;

          return (
            <DocumentAsListItem
              key={`DocumentAsListItem-${document?.id}`}
              document={document}
              loadCurrentVersionContent={() => {}}
              onClick={() => console.log("document button clicked")}
              onDelete={() =>
                mode !== DialogMode.ViewOnly &&
                handleDeleteDocument &&
                handleDeleteDocument(document)
              }
              loadDocumentVersionContent={() => {}}
              setDeletedDocumentId={() => {}}
              isActive={false}
            />
          );
        })}
      </List>
      {numPages > 1 && (
        <Box mt={2} justifyContent="center" display="flex">
          <Pagination
            count={numPages}
            color="primary"
            size="small"
            showFirstButton
            showLastButton
            onChange={(event, value) => setPageNumber(value)}
            variant="text"
          />
        </Box>
      )}

      {oneFileSelected && (
        <FileToUploadViewer
          mode={mode}
          file={
            oneFileSelected && oneFileSelected instanceof File
              ? oneFileSelected
              : undefined
          } // a manually uploaded file
          document={
            (oneFileSelected as any)?.driveItemId && oneFileSelected
              ? (oneFileSelected as IDocument)
              : undefined
          } // a document from the import dialog
          outlookAttachment={
            (oneFileSelected as any)?.origin === "outlook" && oneFileSelected
              ? (oneFileSelected as any)
              : undefined
          }
        />
      )}
    </Box>
  );
}

export default FilesToUploadList;
