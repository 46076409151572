import React from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import { darken } from "polished";
import { Search as SearchIcon } from "react-feather";
import { useTranslation } from "react-i18next";

import {
  Grid,
  InputBase,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
  Typography,
} from "@mui/material";

import { Menu as MenuIcon } from "@mui/icons-material";

import NavbarNotificationsDropdown from "./NavbarNotificationsDropdown";
import NavbarMessagesDropdown from "./NavbarMessagesDropdown";
import NavbarLanguagesDropdown from "./NavbarLanguagesDropdown";
import NavbarUserDropdown from "./NavbarUserDropdown";
import NavbarThemesDropdown from "./NavbarThemesDropdown";
import NavbarDarkModeSwitch from "./NavbarDarkModeSwitch";
import {
  useGetOrganizationBrandingQuery,
  useGetOrganizationQuery,
} from "../../redux/slices/graphApiSlice";
import { getTenantId } from "../../redux/slices/authSlice";
import { store } from "../../redux/store";
import NavbarLevelsDropdown from "./NavbarLevelsDropdown";
import AskMeAnything from "../../pages/components/AskMeAnything";
import InitiateDocumentsDialog from "../dialogs/InitiateDocumentsDialog";
import InitiateVendorsDialog from "../dialogs/InitiateVendorsDialog";
import PromptDialog from "../dialogs/PromptDialog";
import { DialogMode } from "../../types/dialogmode";
import NoteDialog from "../dialogs/NoteDialog";
import { useGetContractsQuery } from "../../redux/slices/indexApiSlice";
import { ContractStatus } from "../../types/contract";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

type NavbarProps = {
  onDrawerToggle: React.MouseEventHandler<HTMLElement>;
};

const Navbar: React.FC<NavbarProps> = ({ onDrawerToggle }) => {
  const { t } = useTranslation();

  const tenantId = getTenantId(store.getState());

  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            <Grid item sx={{ display: { xs: "block", md: "none" } }}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
                size="large"
              >
                <MenuIcon />
              </IconButton>
            </Grid>

            {/* <Grid item>
              <Typography variant="h6" sx={{ ml: 2 }}>
                {organization?.displayName}
              </Typography>
            </Grid> */}

            {/* <Grid item xs={6} md={4}>
              <AskMeAnything />
            </Grid> */}

            <Grid item>
              <InitiateDocumentsDialog mode={DialogMode.Add} iconOnly />
              <InitiateVendorsDialog mode={DialogMode.Add} iconOnly />
              <NoteDialog mode={DialogMode.Add} type="reminder" iconOnly />
            </Grid>

            <Grid item xs />
            <Grid item>
              {/* <NavbarMessagesDropdown /> */}
              <NavbarNotificationsDropdown />
              <NavbarDarkModeSwitch />
              <NavbarLanguagesDropdown />
              {/* <NavbarLevelsDropdown /> */}
              <NavbarUserDropdown />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default withTheme(Navbar);
