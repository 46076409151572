import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Container, List } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { SidebarItemsType } from "../../types/sidebar";
import SidebarNavSection from "./SidebarNavSection";

import "../../vendor/perfect-scrollbar.css";

const baseScrollbar = (props: any) => css`
  background-color: ${props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  flex-grow: 1;
`;

const Scrollbar = styled.div`
  ${baseScrollbar}
`;

const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)};
  padding-bottom: ${(props) => props.theme.spacing(2.5)};
`;

type SidebarNavProps = {
  items: {
    title: string;
    pages: SidebarItemsType[];
  }[];
  isDrawerOpen: boolean;
};

const SidebarNav: React.FC<SidebarNavProps> = ({ items, isDrawerOpen }) => {
  return (
    <Scrollbar>
      <List disablePadding sx={{ height: "100%" }}>
        <Items>
          {items &&
            items.map((item) => (
              <SidebarNavSection
                component="div"
                key={item.title}
                pages={item.pages}
                title={item.title}
                isDrawerOpen={isDrawerOpen}
              />
            ))}
        </Items>
      </List>
    </Scrollbar>
  );
};

export default SidebarNav;
