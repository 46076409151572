import styled from "@emotion/styled";
import {
  CheckCircleOutlined,
  FileUploadOutlined,
  InsertDriveFileOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  Link,
  Paper as MuiPaper,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { blue, grey } from "@mui/material/colors";
import { MuiColorInput } from "mui-color-input";
import { ChangeEvent, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useTranslation } from "react-i18next";
import {
  default as ReactSignatureCanvas,
  default as SignaturePad,
} from "react-signature-canvas";
import { ISignType } from "../../../../types/signature";
import { formatBytes } from "../../../../utils/string";

const UploadPaper = styled(MuiPaper)`
  height: 144px;
  border: 1px dashed ${(props) => props.theme.palette.divider};
  padding: 24px;
  cursor: pointer;
  background-color: ${grey[100]};
  &:hover {
    background-color: ${blue[50]};
    border: 1px dashed ${blue[500]};
  }
`;

type Props = {
  onSign: (signatureUrl: string) => any;
  onCancel: () => any;
};

const AIDocumentSignature: React.FC<Props> = ({ onSign, onCancel }) => {
  const { t } = useTranslation();
  const [signed, setSigned] = useState(false);
  const [signType, setSignType] = useState<ISignType>(ISignType.DRAW);
  const [sigColor, setSigColor] = useState("#000");
  const [sigCanvas, setSigCanvas] = useState<ReactSignatureCanvas | null>(null);
  const [sigText, setSigText] = useState("");

  const [signatureFile, setSignatureFile] = useState<File | null>(null);
  const [errorType, setErrorType] = useState<any>("");

  const handleChangeTab = (
    event: React.SyntheticEvent,
    newValue: ISignType
  ) => {
    setSignType(newValue);
  };

  const handleClearDraw = () => {
    if (sigCanvas) {
      sigCanvas.clear();
      setSigned(false);
    }
  };

  const handleChangeSigText = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSigText(event.target.value);
    setSigned(!!event.target.value);
  };

  const onTypeError = (error: any) => {
    setErrorType(error);
    console.log(error);
  };
  const handleUploadSignatureFile = (file: File) => {
    setSignatureFile(file);
    setErrorType("");
    setSigned(true);
  };

  const handleSign = () => {
    if (signType === ISignType.TYPE) {
      const offscreenCanvas = document.createElement("canvas");
      offscreenCanvas.width = 552;
      offscreenCanvas.height = 200;
      const context = offscreenCanvas.getContext("2d");
      if (context) {
        context.font = "150px Arial";
        context.fillStyle = sigColor;
        context.fillText(sigText, 0, 150);
      }
      const dataUrl = offscreenCanvas.toDataURL();
      onSign(dataUrl);
    } else if (sigCanvas && signType === ISignType.DRAW) {
      // Assuming sigCanvas is defined here
      sigCanvas.getCanvas().setAttribute("lineWidth", "7");
      const dataUrl = sigCanvas.getTrimmedCanvas().toDataURL();
      onSign(dataUrl);
    } else if (signatureFile && signType === ISignType.UPLOAD) {
      const dataUrl = URL.createObjectURL(signatureFile);
      console.log(dataUrl);

      onSign(dataUrl);
    }
    setSigned(false);
  };

  return (
    <Box width="600px" px={6} py={4} borderRadius={2}>
      <Typography variant="h5" color="primary" mb={4}>
        {t("Document_Signature")}
      </Typography>
      <Box sx={{ width: "100%" }} mb={4}>
        <Tabs value={signType} onChange={handleChangeTab}>
          <Tab
            sx={{ width: "50px" }}
            value={ISignType.DRAW}
            label={t("Draw")}
          />
          <Tab value={ISignType.TYPE} label={t("Type")} />
          <Tab value={ISignType.UPLOAD} label={t("Upload")} />
        </Tabs>
      </Box>

      <Box>
        {signType == ISignType.DRAW && (
          <Box>
            <Typography variant="body1" mb={3}>
              {t("Draw your signature in the blank box below")}
            </Typography>
            <Box
              mb={3}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center" gap={2}>
                <Typography>Color:</Typography>
                <MuiColorInput
                  value={sigColor}
                  onChange={(color) => setSigColor(color)}
                />
              </Box>
              <Button variant="contained" onClick={handleClearDraw}>
                {t("Clear")}
              </Button>
            </Box>
            <Box
              sx={{
                maxWidth: "552px",
                maxHeight: "152px",
                border: "1px dashed #ccc",
                borderRadius: 2,
              }}
            >
              <SignaturePad
                ref={(ref) => setSigCanvas(ref)}
                penColor={sigColor}
                onEnd={() => setSigned(true)}
                canvasProps={{
                  style: { width: "552px", height: "152px" },
                  width: 552,
                  height: 152,
                }}
              />
            </Box>
          </Box>
        )}
        {signType == ISignType.TYPE && (
          <Box>
            <Typography variant="body1" mb={3}>
              {t("Draw your signature in the blank box below")}
            </Typography>
            <Box mb={3} display="flex" alignItems="center" gap={2}>
              <Typography>Color:</Typography>
              <MuiColorInput
                value={sigColor}
                onChange={(color) => setSigColor(color)}
              />
            </Box>
            <TextField
              fullWidth
              label={t("Signature")}
              placeholder={t("Type_here") || ""}
              onChange={handleChangeSigText}
            />
          </Box>
        )}
        {signType == ISignType.UPLOAD && (
          <Box>
            <Typography variant="body1" mb={3}>
              {t("Draw your signature file in the blank box below")}
            </Typography>
            {signatureFile ? (
              <Grid
                container
                spacing={6}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <InsertDriveFileOutlined
                        fontSize="large"
                        color="secondary"
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1">
                        {signatureFile ? signatureFile.name : ""}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {signatureFile ? formatBytes(signatureFile.size) : ""}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <CheckCircleOutlined color="success" />
                </Grid>
              </Grid>
            ) : (
              <FileUploader
                handleChange={handleUploadSignatureFile}
                name="file"
                types={["png", "jpg"]}
                onTypeError={onTypeError}
              >
                <UploadPaper variant="outlined" sx={{ cursor: "pointer" }}>
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box>
                      <FileUploadOutlined
                        fontSize="large"
                        color="secondary"
                      ></FileUploadOutlined>
                    </Box>
                    <Typography mt={2} mb={1} variant="subtitle1">
                      <Link underline="none">{t("Click_to_upload")}</Link>{" "}
                      {t("or_drag_and_drop")}{" "}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {t("Maximum_file_size", { size: 30 })}
                    </Typography>
                  </Grid>
                </UploadPaper>
              </FileUploader>
            )}
          </Box>
        )}
      </Box>

      <Box mt={4} textAlign="right">
        <Button variant="outlined" onClick={onCancel}>
          {t("Cancel")}
        </Button>
        <Button
          variant="contained"
          sx={{ marginLeft: 3 }}
          disabled={!signed}
          onClick={handleSign}
        >
          {t("Sign")}
        </Button>
      </Box>
    </Box>
  );
};

export default AIDocumentSignature;
