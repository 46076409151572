import styled from "@emotion/styled";
import { format, set } from "date-fns";
import { useTranslation } from "react-i18next";
import {
  Box,
  CircularProgress,
  Button as MuiButton,
  Grid,
  Typography,
  Card as MuiCard,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  FormLabel,
  FormGroup,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  CardContent,
  AvatarGroup,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemButton,
  InputAdornment,
  TextField,
  ListItemIcon,
  Link,
  Alert,
  AlertTitle,
  Skeleton,
  Stepper,
  Step,
  StepButton,
} from "@mui/material";
import {
  useGetTrustedPartnerTrustsQuery,
  useUpdateContractMutation,
} from "../../../../redux/slices/indexApiSlice";
import {
  Euro,
  Edit as EditIcon,
  QuestionMark,
  Add,
  AttachFile,
  Store,
  Person,
} from "@mui/icons-material";
import CustomNoRowsOverlay from "../../../../components/datagrids/CustomNoRowsOverlay";
import { ContractStatus, IContract } from "../../../../types/contract";
import React, { useEffect, useState } from "react";
import { fontWeight, spacing, SpacingProps } from "@mui/system";
import { ITrustedPartnerTrust } from "../../../../types/trustedpartner";
import DataGridUsers from "../../../../components/datagrids/DataGridUsers";
import AzureADAvatar from "../../../../components/avatars/AzureADAvatar";
import MessageDialog from "../../../../components/dialogs/NoteDialog";
import { DialogMode } from "../../../../types/dialogmode";
import VendorAvatar from "../../../../components/avatars/VendorAvatar";
import useAuth from "../../../../hooks/useAuth";
import { NumericFormat } from "react-number-format";
import { DollarSign } from "react-feather";
import OwlIcon from "../../../../icons/OwlIcon";
import OwlAvatarIcon from "../../../../icons/OwlAvatarIcon";
import { useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/dist/query";

const TypographyLight = styled(Typography)`
  color: #646464;
`;

const Centered = styled.div`
  text-align: center;
`;

const Button = styled(MuiButton)(spacing);

const steps = [
  ContractStatus.DRAFT,
  ContractStatus.PUBLISHED,
  ContractStatus.SIGNING,
  ContractStatus.SIGNED,
  ContractStatus.ACTIVE,
];

type ContractStatusStepperProps = {
  contract: IContract;
};

const ContractStatusStepper: React.FC<ContractStatusStepperProps> = ({
  contract,
}: ContractStatusStepperProps) => {
  const { t } = useTranslation();
  const { clientId, contractId, pageMode, status } = useParams();

  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});

  useEffect(() => {
    if (contract.status) {
      // mark all steps up to the active step as completed
      const activeStep = steps.indexOf(contract.status);
      const newCompleted = completed;
      for (let i = 0; i <= activeStep; i++) {
        newCompleted[i] = true;
      }
      setCompleted(newCompleted);
    } else {
      setCompleted({});
    }
  }, [contract.status]);

  return (
    <Stepper sx={{ marginTop: 1 }}>
      {steps.map((label, index) => (
        <Step key={label} completed={completed[index]}>
          <StepButton color="inherit">
            <Typography variant="body1">{t(label)}</Typography>
          </StepButton>
        </Step>
      ))}
    </Stepper>
  );
};

export default ContractStatusStepper;
