import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";

import { IGraphUser } from "../../types/user";
import { RootState } from "../store";

export const ADD_FILE = "ADD_FILE";
export const REMOVE_FILE = "REMOVE_FILE";

export interface FileState {
  files: { [id: string]: ArrayBuffer };
}

const initialState: FileState = {
  files: {},
};

const filesSlice = createSlice({
  name: "files",
  initialState,
  reducers: {
    addFile: (
      state,
      action: PayloadAction<{ id: string; buffer: ArrayBuffer }>
    ) => {
      state.files[action.payload.id] = action.payload.buffer;
    },
    removeFile: (state, action: PayloadAction<string>) => {
      delete state.files[action.payload];
    },
  },
});

// create a function that selects a file
export const selectFile = (id: string) =>
  createSelector(
    (state: RootState) => state.files.files,
    (files) => {
      return files[id];
    }
  );

// check if the file exists. If yes, return true
export const fileExists = (id: string) =>
  createSelector(
    (state: RootState) => state.files.files,
    (files) => {
      return !!files[id];
    }
  );

export const { addFile, removeFile } = filesSlice.actions;

export default filesSlice;
