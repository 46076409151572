import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";

import {
  Alert as MuiAlert,
  Button as MuiButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  IconButton,
  Fade,
  Box,
  Typography,
  MenuItem,
  Alert,
  AlertTitle,
  Grid,
} from "@mui/material";
import { spacing } from "@mui/system";

import {
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Cancel,
  EventAvailable,
  EventBusy,
  Check,
  Savings as SavingsIcon,
  SendRounded,
  CancelOutlined,
  Close,
  AddCircleOutline,
  NoteAddOutlined,
  NoteAddSharp,
  AlarmAddOutlined,
} from "@mui/icons-material";

import { INote } from "../../types/note";
import useAuth from "../../hooks/useAuth";

import {
  useAddNoteMutation,
  useDeleteNoteMutation,
  useUpdateNoteMutation,
} from "../../redux/slices/indexApiSlice";
import { useTranslation } from "react-i18next";
import { DialogMode } from "../../types/dialogmode";
import NoteForm from "../forms/NoteForm";
import { IContract } from "../../types/contract";
import { IVendor } from "../../types/vendor";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";

const Button = styled(MuiButton)(spacing);

interface INoteDialogProps {
  mode: DialogMode;
  type: "note" | "reminder"; // a note is related to a contract or vendor, a reminder is just a note with a reminder date regardless of a contract or vendor
  open?: boolean;
  handleClose?: () => void;
  note?: INote;
  contract?: IContract;
  vendor?: IVendor;
  selectedUserIds?: string[];
  disabled?: boolean;
  element?: JSX.Element;
  compact?: boolean;
  inlineConfirmation?: boolean;
  iconOnly?: boolean;
  asMenuItem?: boolean; // renders the button as a menu item
  controlled?: boolean; // means the parent component controls the open state so no need to show the open button
  buttonVariant?: "text" | "contained" | "outlined";
}
function NoteDialog({ ...props }: INoteDialogProps) {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [open, setOpen] = useState(props.open || false);
  const { mode, asMenuItem } = props;

  const [success, setSuccess] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const users = useSelector((state: RootState) => state.users.users);

  const [
    addNote,
    {
      data: addedNote,
      isLoading: isAdding,
      isSuccess: isAdded,
      reset: resetAdd,
      isError: addNoteError,
      error: addNoteErrorObject,
    },
  ] = useAddNoteMutation();

  const [
    updateNote,
    {
      data: updatedNote,
      isSuccess: isUpdated,
      reset: resetUpdate,
      isError: updateNoteError,
      error: updateNoteErrorObject,
    },
  ] = useUpdateNoteMutation();

  const [
    deleteNote,
    {
      data: deletedNote,
      isLoading: isDeleting,
      isSuccess: isDeleted,
      reset: resetDelete,
      isError: deleteNoteError,
      error: deleteNoteErrorObject,
    },
  ] = useDeleteNoteMutation();

  useEffect(() => {
    if (isAdded || isUpdated || isDeleted) {
      if (isAdded && addedNote) {
        resetAdd();
      }

      if (isUpdated && updatedNote) {
        resetUpdate();
      }

      if (isDeleted && deletedNote) {
        resetDelete();
      }

      setSuccess(true);

      resetAndClose();
    }
  }, [isAdded, isUpdated, isDeleted, addedNote, updatedNote, deletedNote]);

  const resetAndClose = () => {
    setOpen(false);

    if (props.handleClose) {
      props.handleClose();
    }

    // wait for the dialog to close
    setTimeout(() => {
      setSuccess(false);
    }, 500);
  };

  const handleAddNote = async (note: INote) => {
    const noteToAdd: INote = { ...note, author: user?.id };
    if (props.contract?.id) {
      noteToAdd.contractId = props.contract?.id;
    } else if (props.vendor?.id) {
      noteToAdd.vendorId = props.vendor?.id;
    }

    await addNote(noteToAdd);
  };

  const handleUpdateNote = async (note: INote) => {
    const noteToUpdate: INote = { ...note };

    await updateNote(noteToUpdate);
  };

  const handleConfirmDelete = async (e: any) => {
    e.stopPropagation();

    // Call the delete function to delete the item
    if (props.note?.id) {
      await deleteNote(props.note);
    }

    // Hide the confirmation
    setShowConfirmation(false);
  };

  const handleCancelDelete = (e: any) => {
    e.stopPropagation();

    // Hide the confirmation
    setShowConfirmation(false);
  };

  const handleDeleteClick = (e: any) => {
    e.stopPropagation();

    if (props.inlineConfirmation) {
      setShowConfirmation(true);
    } else {
      setOpen(true);
    }
  };

  return (
    <>
      {props.element &&
        React.cloneElement(props.element, {
          onClick: () => setOpen(true), // Add onClick function to the element
        })}

      {!props.controlled && (
        <>
          {mode === DialogMode.Delete && (
            <>
              {!showConfirmation && (
                <Tooltip title={t("Delete")}>
                  {props.iconOnly ? (
                    <IconButton size="small" onClick={handleDeleteClick}>
                      <DeleteIcon />
                    </IconButton>
                  ) : asMenuItem ? (
                    <MenuItem onClick={() => setOpen(true)}>
                      <DeleteIcon />
                      {t("Delete note")}
                    </MenuItem>
                  ) : (
                    <Button color="primary" onClick={() => setOpen(true)}>
                      {t("Delete note")}
                    </Button>
                  )}
                </Tooltip>
              )}

              {props.inlineConfirmation && showConfirmation && (
                <Fade in={showConfirmation}>
                  <Box>
                    <Typography component="span">
                      {t("Are you sure?")}
                    </Typography>
                    <IconButton
                      aria-label="Cancel"
                      size="small"
                      onClick={handleCancelDelete}
                    >
                      <Cancel />
                    </IconButton>
                    <IconButton
                      aria-label="Confirm"
                      size="small"
                      onClick={handleConfirmDelete}
                    >
                      <Check />
                    </IconButton>
                  </Box>
                </Fade>
              )}
            </>
          )}

          {props.mode === DialogMode.Add && (
            <>
              {props.iconOnly ? (
                <Tooltip title={t(`Add ${props.type}`)}>
                  <IconButton
                    size="large"
                    color="inherit"
                    onClick={() => setOpen(true)}
                  >
                    <AlarmAddOutlined />
                  </IconButton>
                </Tooltip>
              ) : (
                <Button
                  variant={props.buttonVariant || "text"}
                  onClick={() => setOpen(true)}
                >
                  <AlarmAddOutlined sx={{ mr: 2 }} /> {t(`Add ${props.type}`)}
                </Button>
              )}
            </>
          )}

          {props.mode === DialogMode.Edit && (
            <>
              {asMenuItem ? (
                <MenuItem onClick={() => setOpen(true)}>
                  <EditIcon />
                  {t("Edit")}
                </MenuItem>
              ) : (
                <Button
                  size="small"
                  color="primary"
                  aria-label="Edit"
                  disabled={props.disabled}
                  onClick={() => setOpen(true)}
                >
                  {t("Edit")}
                </Button>
              )}
            </>
          )}
        </>
      )}

      <Dialog
        open={open}
        maxWidth={"sm"}
        fullWidth
        // onClose={() => setOpen(false)}
        aria-labelledby="note-dialog-title"
        id="note-dialog"
      >
        <DialogTitle id="note-dialog-title">
          <Grid container justifyContent="space-between">
            <Grid item>
              {mode === DialogMode.Add
                ? t(`Add ${props.type}`)
                : mode === DialogMode.Edit
                ? t(`Edit ${props.type}`)
                : mode === DialogMode.Delete
                ? t(`Are you sure you want to delete this ${props.type}?`)
                : ""}
            </Grid>
            <Grid item>
              <IconButton size="small" onClick={() => setOpen(false)}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        {mode !== DialogMode.Delete && (
          <DialogContent>
            {(updateNoteError || addNoteError || deleteNoteError) && (
              <Alert severity="error" sx={{ mb: 6 }}>
                <AlertTitle>{t("An error occurred!")}</AlertTitle>

                {JSON.stringify(addNoteErrorObject)}

                {JSON.stringify(updateNoteErrorObject)}

                {JSON.stringify(deleteNoteErrorObject)}
              </Alert>
            )}

            <NoteForm
              mode={props.mode}
              type={props.type}
              addNote={handleAddNote}
              updateNote={handleUpdateNote}
              note={props.note}
              contract={props.contract}
              vendor={props.vendor}
              users={users}
              selectedUserIds={props.selectedUserIds}
            />
          </DialogContent>
        )}
        <DialogActions>
          {success ? (
            <Button
              variant="contained"
              onClick={() => resetAndClose()}
              color="primary"
            >
              {t("Close")}
            </Button>
          ) : (
            <>
              {!success && (
                <Button onClick={() => resetAndClose()} color="primary">
                  {t("Cancel")}
                </Button>
              )}

              {(mode === DialogMode.Edit || mode === DialogMode.Add) && (
                <Button
                  type="submit"
                  form="note-form"
                  color="primary"
                  disabled={isAdding}
                  variant="contained"
                >
                  {t("Save")}
                </Button>
              )}

              {mode === DialogMode.Delete && (
                <Button
                  variant="contained"
                  onClick={handleConfirmDelete}
                  color="primary"
                >
                  {t("Delete")}
                </Button>
              )}
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

export default NoteDialog;
