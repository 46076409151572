import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import {
  Avatar,
  Box,
  Button,
  Fade,
  Grid,
  IconButton,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Toolbar,
  Tooltip,
  Typography as MuiTypography,
  CardActions,
  CardMedia as MuiCardMedia,
  Chip as MuiChip,
  CardActionArea,
  useTheme,
} from "@mui/material";
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  Description,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
  Delete as DeleteIcon,
  Cancel,
  GridView,
  TableView,
  PropaneSharp,
} from "@mui/icons-material";

import {
  DataGrid,
  GridColDef,
  GridToolbar,
  GridState,
  gridPaginationModelSelector,
  useGridApiRef,
  GridRowSelectionModel,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { SpacingProps, alpha, spacing } from "@mui/system";

import {
  useUpdatePromptMutation,
  useDeletePromptMutation,
  useGetPromptsQuery,
} from "../../redux/slices/indexApiSlice";
import { Delete, LegendToggle } from "@mui/icons-material";
import VendorAvatar from "../avatars/VendorAvatar";
import CustomNoRowsOverlay from "./CustomNoRowsOverlay";
import { AvatarGroup as MuiAvatarGroup } from "@mui/material";
import { IKPI, IVendorKPI } from "../../types/kpi";
import { Check } from "react-feather";
import KPIDialog from "../dialogs/KPIDialog";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import EnhancedDataGridToolbar from "./EnhancedDataGridToolbar";
import { t } from "i18next";
import { DialogMode } from "../../types/dialogmode";
import EnhancedDataGrid from "./EnhancedDataGrid";
import { fetchKpi } from "../../redux/slices/kpiSlice";
import PromptDialog from "../dialogs/PromptDialog";
import { IPrompt } from "../../types/prompt";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

const Spacer = styled.div`
  flex: 1 1 100%;
`;

interface TypographyProps extends SpacingProps {
  component?: string;
}
const Typography = styled(MuiTypography)<TypographyProps>(spacing);

const CardContent = styled(MuiCardContent)`
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
`;

const CardMedia = styled(MuiCardMedia)`
  height: 220px;
`;

const Chip = styled(MuiChip)<{ color?: string }>`
  height: 20px;
  padding: 4px 0;
  font-size: 85%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
  margin-bottom: ${(props) => props.theme.spacing(4)};
`;

const AvatarGroup = styled(MuiAvatarGroup)`
  margin-left: ${(props) => props.theme.spacing(2)};
`;

// Type guard to check if kpi is of type IVendorKPI
function isVendorKPI(kpi: IKPI | IVendorKPI): kpi is IVendorKPI {
  return (kpi as IVendorKPI).score !== undefined;
}

type PromptCardProps = {
  image?: string;
  prompt: IPrompt;
  chip: JSX.Element;
  handleCardClick?: (selected: IPrompt) => void;
  selected?: boolean;
};
const PromptCard: React.FC<PromptCardProps> = ({
  image,
  prompt,
  chip,
  selected,
  handleCardClick,
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);

  const theme = useTheme();

  const [isDeleting, setIsDeleting] = useState(false);

  const handleOnClick = () => {
    if (handleCardClick) {
      //   setSelected(!isSelected);

      handleCardClick(prompt);
    }
  };

  const cardContent = (
    <CardContent>
      <Grid
        container
        justifyContent="space-between"
        alignItems="top"
        wrap="nowrap"
      >
        <Typography
          gutterBottom
          variant="h5"
          component="h2"
          noWrap
          textOverflow="ellipsis"
        >
          {prompt?.body}
        </Typography>
      </Grid>

      {/* {chip} */}

      <Tooltip title={prompt?.body}>
        <Typography mb={4} color="textSecondary" component="p">
          {prompt?.body}
        </Typography>
      </Tooltip>
    </CardContent>
  );

  const backgroundColor = selected
    ? alpha(theme.palette.primary.main, 0.16)
    : null;

  return (
    <Card onClick={handleOnClick} aria-selected={selected} variant="outlined">
      {handleCardClick ? (
        // if there is an action, wrap the card in a CardActionArea
        <CardActionArea
          aria-selected={selected}
          sx={{ backgroundColor: backgroundColor }}
        >
          {cardContent}
        </CardActionArea>
      ) : (
        <>{cardContent}</>
      )}
      <CardActions>
        <PromptDialog
          prompt={prompt}
          mode={DialogMode.Edit}
          iconOnly
          disabled={isDeleting}
        />

        <PromptDialog
          prompt={prompt}
          mode={DialogMode.Delete}
          iconOnly
          disabled={isDeleting}
        />
      </CardActions>
    </Card>
  );
};

interface IDataGridPromptsProps {
  items?: IPrompt[];
  compactHeader?: boolean;
  onSelect?: (selected: IPrompt[]) => void;
  selected?: string[];
  handleCardClick?: (selected: IPrompt) => void;
  view?: "cards" | "table";
}

function DataGridPrompts({ ...props }: IDataGridPromptsProps) {
  const [view, setView] = useState(props.view || "cards");

  const [updatePrompt, { isLoading: isUpdating, isSuccess: isUpdated }] =
    useUpdatePromptMutation();

  const [deletePrompt, { isLoading: deleting, isSuccess: isDeleted }] =
    useDeletePromptMutation();

  const { data: prompts = [], isLoading, isSuccess } = useGetPromptsQuery();

  const apiRef = useGridApiRef();
  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([]);

  useEffect(() => {
    if (props?.selected) {
      // Set the default selected items
      setRowSelectionModel(props.selected);
    }
  }, []);

  // const paginationModel = gridPaginationModelSelector(apiRef.current.state, apiRef.current.instanceId);

  useEffect(() => {
    // this is the id of the selected row/user
    console.log(rowSelectionModel);

    // get the kpi object from the id
    const selectedPrompts = handleMUIDataGrid().filter(
      (p: IPrompt) => p.id && rowSelectionModel.includes(p.id)
    );

    props?.onSelect && props?.onSelect(selectedPrompts);
  }, [rowSelectionModel]);

  const columns: GridColDef[] = [
    // {
    //   flex: 1,
    //   field: "name",
    //   headerName: t("Name")!,
    // },
    {
      flex: 1,
      field: "body",
      headerName: t("Prompt")!,
    },
    {
      flex: 1,
      field: "category",
      headerName: t("Category")!,
    },
    {
      headerName: t("Action")!,
      width: 250,
      field: "action",
      disableColumnMenu: true,
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <>
            <PromptDialog
              prompt={cellValues.row}
              mode={DialogMode.Edit}
              iconOnly
            />

            <PromptDialog
              prompt={cellValues.row}
              mode={DialogMode.Delete}
              iconOnly
            />
          </>
        );
      },
    },
  ];

  // This function is triggered when the delete button in the toolbar is clicked
  const handleToolbarDeleteClick = (event: any) => {
    console.log(rowSelectionModel);

    rowSelectionModel.forEach((row) => {
      handleDeletePrompt({ id: row } as IPrompt);
    });
  };

  const handleMUIDataGrid = () => {
    if (props.items) {
      return props.items;
    } else if (!isLoading && prompts) {
      return prompts;
    }

    return [];
  };

  const rows = handleMUIDataGrid();

  const handleCardClick = (sel: IPrompt) => {
    if (sel.id && rowSelectionModel.includes(sel?.id)) {
      const newRowSelectionModel = rowSelectionModel.filter(
        (row) => row !== sel?.id
      );
      setRowSelectionModel(newRowSelectionModel);
    } else if (sel.id) {
      const newRowSelectionModel = [...rowSelectionModel, sel?.id];
      setRowSelectionModel(newRowSelectionModel);
    }
  };

  const handleDeletePrompt = async (prompt: IPrompt) => {
    if (prompt?.id) {
      await deletePrompt(prompt.id);

      // remove the deleted item from the rowSelectionModel
      const newRowSelectionModel = rowSelectionModel.filter(
        (row) => row !== prompt?.id
      );

      setRowSelectionModel(newRowSelectionModel);
    }
  };

  return view === "cards" ? (
    <Paper>
      <Box
        height={rows.length > 0 ? "55vh" : 400}
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiToolbar-root": {
            minHeight: "64px",
          },
        }}
      >
        <EnhancedDataGridToolbar
          rowSelectionModel={rowSelectionModel}
          handleDeleteClick={handleToolbarDeleteClick}
          view={view}
          setView={setView}
          title={""}
          isLoading={isLoading}
          deleting={deleting}
        />
        <Box
          m={4}
          p={4}
          sx={{ background: (props) => props.palette.background.default }}
          height="100%"
        >
          <Grid container spacing={6}>
            {prompts?.map((prompt: IPrompt, i: number) => (
              <Grid key={i.toString()} item xs={12} lg={6} xl={3}>
                <PromptCard
                  prompt={prompt}
                  handleCardClick={handleCardClick}
                  selected={rowSelectionModel.includes(prompt.id || "")}
                  chip={<Chip label="Finished" color="success" />}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Paper>
  ) : (
    <Paper mb={16}>
      <Box
        // height="55vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
        }}
      >
        {!isLoading && isSuccess && prompts?.length > 0 && (
          <DataGrid
            initialState={{
              pagination: { paginationModel: { page: 0, pageSize: 10 } },
            }}
            apiRef={apiRef}
            pageSizeOptions={[5, 10, 25]}
            rows={rows}
            columns={columns}
            autoHeight
            checkboxSelection
            loading={isLoading}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelectionModel}
            slots={{
              toolbar: () => (
                <EnhancedDataGridToolbar
                  rowSelectionModel={rowSelectionModel}
                  handleDeleteClick={handleToolbarDeleteClick}
                  view={view}
                  setView={setView}
                  title={t("Prompts")!}
                  isLoading={isLoading}
                  deleting={deleting}
                />
              ),
              noRowsOverlay: () => (
                <CustomNoRowsOverlay label={t("No prompts")} />
              ),
            }} // GridToolbar    GridToolbarQuickFilter
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
              pagination: {
                showFirstButton: true,
                showLastButton: true,
              },
              // pagination: {
              //   labelRowsPerPage: t("Rows per page"),
              //   labelDisplayedRows(paginationInfo) {
              //     return `${paginationInfo.from}-${paginationInfo.to} ${t("of")} ${paginationInfo.count}`;
              //   },
              // },
            }}
            density="comfortable"
          />
        )}
      </Box>
    </Paper>
  );
}

export default DataGridPrompts;
