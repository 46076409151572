import React, { Suspense, lazy, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Avatar,
  Box,
  Button,
  Container,
  Fade,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  Description,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  NoteAdd as NoteAddIcon,
  EditNote as EditNoteIcon,
  Event,
  CopyAll,
} from "@mui/icons-material";

import { DataGrid, GridColDef, useGridApiRef } from "@mui/x-data-grid";
import { spacing } from "@mui/system";

import { useGetActivitiesQuery } from "../../redux/slices/indexApiSlice";
import { Delete, LegendToggle } from "@mui/icons-material";
import VendorAvatar from "../avatars/VendorAvatar";
import CustomNoRowsOverlay from "./CustomNoRowsOverlay";
import { useTranslation } from "react-i18next";
import EnhancedDataGridToolbar from "./EnhancedDataGridToolbar";
import VendorDialog from "../dialogs/VendorDialog";
import { DialogMode } from "../../types/dialogmode";
import { format } from "date-fns";
import EnhancedDataGrid from "./EnhancedDataGrid";
import { IStep } from "../../types/step";
import { IActivity } from "../../types/activity";
import ActivityDescription from "../../pages/components/ActivityDescription";
import { enGB, nl } from "date-fns/locale";
import i18next from "i18next";
import DateTimeTranslate from "../DateTimeTranslate";
import { skipToken } from "@reduxjs/toolkit/dist/query";
const JSONPretty = require("react-json-pretty");

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

const Spacer = styled.div`
  flex: 1 1 100%;
`;

function DataGridActivities({ ...props }) {
  const { t } = useTranslation();

  const {
    data: activities = [],
    isLoading,
    isSuccess,
  } = useGetActivitiesQuery();

  const [steps, setSteps] = useState<IStep[] | null>([]);

  const [locale, setLocale] = useState(i18next.language === "nl" ? nl : enGB);

  useEffect(() => {
    if (isSuccess && activities) {
      const steps = activities.map((activity: IActivity) => {
        const step: IStep = {
          icon:
            activity.method === "POST" ? (
              <NoteAddIcon />
            ) : activity.method === "PUT" ? (
              <EditNoteIcon />
            ) : (
              <Event />
            ),
          label: "",
          description: <ActivityDescription activity={activity} />,
          created: activity.created,
        };

        return step;
      });

      setSteps(steps);

      // {
      //   icon: <AzureADAvatar userId={props.contract?.owner} size={24} />,
      //   text: "Contract owner was changed.",
      // },
      // {
      //   icon: <AzureADAvatar userId={props.contract?.owner} size={24} />,
      //   text: `Contract assigned to Mark Green for further review.`,
      // },
      // {
      //   icon: <CheckBox />,
      //   text: `Contract approved by Mark Green.`,
      // },
      // {
      //   icon: <CheckBox />,
      //   text: `Contract is in validation.`,
      // },
    }
  }, [isSuccess, activities]);

  const [view, setView] = useState(props.view || "table");

  const apiRef = useGridApiRef();

  const columns: GridColDef[] = [
    {
      flex: 1,
      field: "user",
      headerName: t("User")!,
      disableColumnMenu: true,
      valueFormatter: (params) => {
        return params.value && `${params.value.name} (${params.value.upn})`;
      },
    },
    {
      flex: 1,
      field: "created",
      headerName: t("Date")!,
      disableColumnMenu: true,
      valueFormatter: (params) => {
        return params.value && format(new Date(params.value), "P pp");
      },
    },

    {
      flex: 1,
      field: "method",
      headerName: t("Event")!,
      disableColumnMenu: true,
      valueFormatter: (params) => {
        return params.value === "POST"
          ? t("Created")
          : params.value === "PUT"
          ? t("Updated")
          : params.value === "DELETE"
          ? t("Deleted")
          : params.value;
      },
    },

    {
      flex: 1,
      field: "payload",
      resizable: true,
      // valueFormatter: (params) => {
      //   return JSON.stringify(params.value);
      // },
      // width: 450,
      headerName: t("Payload")!,
      renderCell: (cellValues) => {
        return (
          cellValues.row?.payload && (
            <Container>
              <TextField
                multiline
                fullWidth
                value={JSON.stringify(cellValues.row?.payload)}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() => {
                        navigator.clipboard.writeText(
                          JSON.stringify(cellValues.row?.payload)
                        );
                      }}
                    >
                      <CopyAll />
                    </IconButton>
                  ),
                  rows: 10,
                }}
              />
            </Container>
          )
        );
      },
    },
    {
      flex: 1,
      field: "vendorName",
      headerName: t("Vendor")!,
      // renderCell: (cellValues) => {
      //   return (
      //     cellValues.row?.payload &&
      //     cellValues.row?.entity === "vendor" &&
      //     cellValues.row?.payload?.body?.name
      //   );
      // },
    },
    {
      flex: 1,
      field: "contractName",
      headerName: t("Contract")!,
      // valueFormatter: (params) => {
      //   return (
      //     params.value?.entity === "contract" &&
      //     params.value.payload?.body?.name
      //   );
      // },
      // renderCell: (cellValues) => {
      //   return (
      //     cellValues.row?.payload &&
      //     cellValues.row?.entity === "contract" &&
      //     cellValues.row?.payload?.body?.name
      //   );
      // },
    },
    // {
    //   flex: 1,
    //   field: "contractId",
    //   headerName: t("Contract")!,
    //   renderCell: (cellValues) => {
    //     return cellValues.row?.payload && cellValues.row?.entity === "contract" && cellValues.row?.payload.id;
    //   },
    // },
  ];

  return (
    <Paper mb={16}>
      <Box
        // height="55vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
        }}
      >
        {!isLoading && activities?.length === 0 ? (
          <CustomNoRowsOverlay label={t("No activities")} />
        ) : view === "cards" ? (
          <Paper>
            <Box
              // height={activities.length > 0 ? "55vh" : 400}
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiToolbar-root": {
                  minHeight: props.vendor || props.contract ? "unset" : "64px",
                },
              }}
            >
              <EnhancedDataGridToolbar
                rowSelectionModel={null}
                handleDeleteClick={null}
                view={view}
                setView={setView}
                title={t("Activity Log")}
                isLoading={isLoading}
                deleting={false}
              />

              <List dense>
                {steps?.map((step, i) => (
                  <>
                    <ListItem>
                      <ListItemIcon sx={{ paddingTop: 2, alignSelf: "start" }}>
                        {step.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          step.created && (
                            <Grid
                              justifyContent="space-between"
                              direction="row"
                              display="flex"
                            >
                              <Grid item>
                                <DateTimeTranslate
                                  date={new Date(step.created)}
                                  format="ago"
                                ></DateTimeTranslate>
                              </Grid>

                              <Grid item>
                                <Typography color="secondary">
                                  <DateTimeTranslate
                                    date={new Date(step.created)}
                                    format="dateTime"
                                  />
                                </Typography>
                              </Grid>
                            </Grid>
                          )
                        }
                        secondary={step.description}
                      />
                    </ListItem>
                    {i < steps.length - 1 && <Divider />}
                  </>
                ))}
              </List>
            </Box>
          </Paper>
        ) : (
          <DataGrid
            initialState={{
              pagination: { paginationModel: { page: 0, pageSize: 5 } },
            }}
            apiRef={apiRef}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            rows={activities}
            rowHeight={350}
            columns={activities.length > 0 ? columns : []}
            loading={isLoading}
            slots={{
              toolbar: () => (
                <EnhancedDataGridToolbar
                  view={view}
                  // setView={setView}
                  title={""}
                  isLoading={isLoading}
                  customActions={[]}
                />
              ),
              noRowsOverlay: () => (
                <CustomNoRowsOverlay label={t("No activities")} />
              ),
            }} // GridToolbar    GridToolbarQuickFilter
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
              pagination: {
                showFirstButton: true,
                showLastButton: true,
              },
              // pagination: {
              //   labelRowsPerPage: t("Rows per page"),
              //   labelDisplayedRows(paginationInfo) {
              //     return `${paginationInfo.from}-${paginationInfo.to} ${t("of")} ${paginationInfo.count}`;
              //   },
              // },
            }}
            density="compact"
          />
        )}
      </Box>
    </Paper>
  );
}

export default DataGridActivities;
