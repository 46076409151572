import React, { useEffect, useState } from "react";
import { Field, Form, Formik, FormikProps, useFormikContext } from "formik";
import * as Yup from "yup";
import styled from "@emotion/styled";

import {
  Alert as MuiAlert,
  AvatarGroup,
  createFilterOptions,
  Box,
  Button as MuiButton,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  Grid,
  IconButton,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  TextField as MuiTextField,
  Typography,
  ImageList,
  ImageListItem,
  Tab,
  Tabs,
  ListItemButton,
  Autocomplete,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { spacing } from "@mui/system";

import {
  DatePicker,
  StaticDatePicker,
  DateTimePicker,
  StaticDateTimePicker,
  TimePicker,
  StaticTimePicker,
} from "@mui/x-date-pickers";
import { DialogMode } from "../../types/dialogmode";
import { useTranslation } from "react-i18next";
import {
  useGetDrivesQuery,
  useGetFollowedSitesQuery,
  useGetSitesQuery,
  useLazyGetDrivesQuery,
} from "../../redux/slices/graphApiSlice";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { ISharePointConnection } from "../../types/sharepointconnection";

const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);
const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface AutocompleteOption {
  id: string;
  label: string;
  logo: string;
}

interface ISharePointConnectionFormProps {
  mode: DialogMode;
  connection?: ISharePointConnection;
  initialValues?: ISharePointConnection;
  addConnection: (values: ISharePointConnection) => void;
  updateConnection: (values: ISharePointConnection) => void;
}

function SharePointConnectionForm({
  ...props
}: ISharePointConnectionFormProps) {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    siteId: Yup.string().required(t("Required")!),
    driveId: Yup.string().required(t("Required")!),
  });

  const [siteId, setSiteId] = React.useState(
    props.connection?.siteId ?? props.initialValues?.siteId ?? null
  );
  const { data: sites, isSuccess: sitesLoaded } = useGetFollowedSitesQuery();
  // const { data: sites, isSuccess: sitesLoaded } = useGetSitesQuery();

  const [getDrives, { data: drives, isSuccess: drivesLoaded }] =
    useLazyGetDrivesQuery();
  const [siteOptions, setSiteOptions] = useState<AutocompleteOption[]>([]);
  const [driveOptions, setDriveOptions] = useState<AutocompleteOption[]>([]);

  useEffect(() => {
    if (siteId) {
      getDrives(siteId);
    }
  }, [siteId]);

  useEffect(() => {
    if (sites && sitesLoaded) {
      setSiteOptions(
        sites?.map((site: any) => ({
          label: `${site.displayName} (${site.webUrl})`,
          id: site.id,
          logo: site.logo,
        }))
      );
    }
  }, [sites, sitesLoaded]);

  useEffect(() => {
    if (drives && drivesLoaded) {
      setDriveOptions(
        drives.map((drive: any) => ({
          label: `${drive.name} (${drive.webUrl})`,
          id: drive.id,
          logo: drive.logo,
        }))
      );
    }
  }, [drives, drivesLoaded]);

  const handleSubmit = async (
    values: any,
    { resetForm, setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      console.log("submitting form");
      if (props.mode === DialogMode.Add) {
        await props.addConnection(values);

        // resetForm();
        setStatus({ sent: true });
        setSubmitting(false);
      } else if (props.mode === DialogMode.Edit) {
        await props.updateConnection(values);

        // resetForm();
        setStatus({ sent: true });
        setSubmitting(false);
      }
    } catch (error: any) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  const initialValues: ISharePointConnection = props.connection
    ? { ...props.connection }
    : props.initialValues
    ? { ...props.initialValues }
    : {
        id: "",
        siteId: "",
        driveId: "",
        webUrl: "",
        siteDisplayName: "",
        driveName: "",
      };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
        isValid,
      }) => (
        <Card mb={6}>
          <CardContent>
            {status && status.sent && (
              <Alert severity="success" my={3}>
                {t("Your data has been submitted successfully!")}
              </Alert>
            )}

            {errors && !isValid && !status?.sent && (
              <Alert severity="error" my={3}>
                {t("An error occurred!")}
                {errors && Object.keys(errors).length > 0 && (
                  <pre style={{ whiteSpace: "pre-wrap" }}>
                    {JSON.stringify(errors, null, 2)}
                  </pre>
                )}
              </Alert>
            )}

            {/* {isSubmitting && <CircularProgress />} */}

            <Form id="sharepoint-connection-form" onSubmit={handleSubmit}>
              <Grid container gap={12} direction="column">
                <Grid item>
                  <FormControl fullWidth>
                    <Field
                      name="siteId"
                      error={Boolean(touched.siteId && errors.siteId)}
                      helperText={
                        touched.siteId && errors.siteId
                          ? errors.siteId.toString()
                          : null
                      }
                    >
                      {() => (
                        <Autocomplete
                          // multiple
                          fullWidth
                          id="site-select"
                          options={siteOptions}
                          freeSolo={false}
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          getOptionLabel={(option) => option.label}
                          // renderOption={(props, option) => (
                          //   <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                          //     <img loading="lazy" width="40" src={option.logo} srcSet={`${option.logo} 2x`} alt="" />
                          //     {option.label}
                          //   </Box>
                          // )}
                          onChange={(event: any, option: any) => {
                            setFieldValue("siteId", option?.id);
                            setSiteId(option?.id);
                          }}
                          value={
                            values.siteId &&
                            siteOptions &&
                            siteOptions.length > 0
                              ? siteOptions.filter(
                                  (u) => u.id === values.siteId
                                )[0]
                              : null
                          }
                          renderInput={(params: any) => (
                            <TextField
                              {...params}
                              label={t("Select Site")}
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                    </Field>
                  </FormControl>
                </Grid>

                <Grid item>
                  <FormControl fullWidth>
                    <Field
                      name="driveId"
                      error={Boolean(touched.driveId && errors.driveId)}
                      helperText={
                        touched.driveId && errors.driveId
                          ? errors.driveId.toString()
                          : null
                      }
                    >
                      {() => (
                        <Autocomplete
                          // multiple
                          fullWidth
                          id="drive-select"
                          options={driveOptions}
                          freeSolo={false}
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          getOptionLabel={(option) => option.label}
                          // renderOption={(props, option) => (
                          //   <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                          //     <img loading="lazy" width="40" src={option.logo} srcSet={`${option.logo} 2x`} alt="" />
                          //     {option.label}
                          //   </Box>
                          // )}
                          onChange={(event: any, option: any) => {
                            setFieldValue("driveId", option?.id);
                          }}
                          value={
                            values.driveId &&
                            driveOptions &&
                            driveOptions.length > 0
                              ? driveOptions.filter(
                                  (u) => u.id === values.driveId
                                )[0]
                              : null
                          }
                          renderInput={(params: any) => (
                            <TextField
                              {...params}
                              label={t("Select Drive")}
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                    </Field>
                  </FormControl>
                </Grid>
              </Grid>
            </Form>
          </CardContent>
        </Card>
      )}
    </Formik>
  );
}

export default SharePointConnectionForm;
