import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Bar } from "react-chartjs-2";

import { IFeedback } from "../../types/feedback";
import { nl, enGB } from "date-fns/locale";

import ShowMoreText from "react-show-more-text";

import {
  Briefcase,
  DollarSign,
  ExternalLink,
  Facebook,
  Home,
  Instagram,
  MapPin,
  PhoneCall,
  ShoppingBag,
  Twitter,
} from "react-feather";
import {
  Add as AddIcon,
  NoteAdd as NoteAddIcon,
  Delete as DeleteIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
  EditNote as EditNoteIcon,
  Cancel,
  EventAvailable,
  EventBusy,
  Check,
  Savings as SavingsIcon,
  Edit as EditIcon,
  Feed,
  Phone,
  ChevronLeft,
  ChevronRight,
  Mail,
  CrisisAlert,
  Fastfood,
  Event,
  CheckBox,
  NewReleases,
  Verified,
  Launch as LaunchIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  Style,
  AutoFixHigh,
  MoreHoriz as MoreHorizIcon,
  HistoryToggleOff,
  History,
  Task,
  Download as DownloadIcon,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { IActivity } from "../../types/activity";
import AzureADAvatar from "../../components/avatars/AzureADAvatar";
import { Box, Grid, Typography } from "@mui/material";
import { IContract } from "../../types/contract";
import VendorAvatar from "../../components/avatars/VendorAvatar";
import i18next from "i18next";

function ActivityDescription({ ...props }: { activity: IActivity }) {
  const { t } = useTranslation();

  const { activity } = props;

  const userName = activity?.user?.name
    ? activity?.user?.name
    : activity?.user?.upn;

  const avatar = <AzureADAvatar userId={activity?.user?.id} size={24} />;

  const getDeltas = (activity: IActivity) => {
    const userName = activity?.user?.name
      ? activity?.user?.name
      : activity?.user?.upn;
    const userId = activity?.user?.id;

    if (!activity.delta) {
      return `${t("Contract updated by")} ${userName}}`;
    }

    const elements = [];

    const deltaProps: IContract = activity.delta;

    if (deltaProps.name) {
      const element = (
        <Typography>
          {t("Name")} {t("was updated to")}: {deltaProps.name}
        </Typography>
      );
      elements.push(element);
    }

    if (deltaProps.vendorId) {
      const element = (
        <Typography>
          {t("Vendor")} {t("was updated to")}:{" "}
          <VendorAvatar vendorId={deltaProps.vendorId} small />
        </Typography>
      );
      elements.push(element);
    }

    if (deltaProps.signatures) {
      const element = <Typography>{t("Signatures were updated")}</Typography>;
      elements.push(element);
    }

    if (deltaProps.managerIds) {
      const userNames = deltaProps.managerIds?.map((userId: string) => {
        return (
          <Box p={0.5}>
            <AzureADAvatar userId={userId} small size={24} />
          </Box>
        );
      });

      const element = (
        <Typography>
          {t("Manager")}: {userNames}
        </Typography>
      );
      elements.push(element);
    }

    if (deltaProps.ownerIds) {
      const userNames = deltaProps.ownerIds?.map((userId: string) => {
        return (
          <Box p={0.5}>
            <AzureADAvatar userId={userId} small size={24} />
          </Box>
        );
      });

      const element = (
        <Typography>
          {t("Owner")}: {userNames}
        </Typography>
      );
      elements.push(element);
    }

    if (elements.length > 0) {
      const firstElement = userId ? (
        <Typography variant="body1">
          {activity.entity === "contract" && t("Contract updated by")}
          {activity.entity === "vendor" && t("Vendor updated by")}
          : <AzureADAvatar userId={userId} nameOnly />
        </Typography>
      ) : (
        <Typography>{userName}</Typography>
      );

      elements.unshift(firstElement);
    }

    // put the elements in a Grid
    const gridElements = elements.map((element) => {
      return <Grid item>{element}</Grid>;
    });

    return (
      <Grid container spacing={2} direction="column">
        {gridElements}
      </Grid>
    );
  };

  switch (activity.method) {
    case "POST":
      return (
        <Typography>
          {i18next.language === "nl"
            ? `${userName} heeft ${activity.payload.body?.name} aangemaakt`
            : `${userName} created ${activity.payload.body?.name}`}
        </Typography>
      );
    case "PUT":
      return (
        <Grid container direction="row">
          <Grid item>{getDeltas(activity)}</Grid>
        </Grid>
      );
    case "DELETE":
      return (
        <Typography
          variant="body1"
          color={(theme) => theme.palette.text.primary}
        >
          {t("Contract_deleted_activity", {
            userName,
            contractId: activity.payload?.params?.contractId,
          })}
        </Typography>
      );
    default:
      return <Typography>unknown activity</Typography>;
  }
}

export default ActivityDescription;
